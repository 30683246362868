import Modal from "@mui/material/Modal";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { ReactComponent as Completed } from "../../assets/operations/status-completed.svg";
import { useEffect, useState } from "react";
import {
  deepCopy,
  formatTime,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";
import { getTurnaroundInfo } from "../../turnaroundUtils";
import { useTranslation } from "react-i18next";
import SelectCrew from "../SelectCrew";
import { debugLog } from "../../logging";
import { getUserInfo } from "../../userUtils";
import { getRequirementsToPatch } from "./utils";
import { ANALYTICS_EVENTS } from "../../constants";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";

function TaskModal(props) {
  const { t } = useTranslation();

  const { taskInfo, onClose, timezone, rosterInfo } = props;
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    turnaroundDetails,
    users,
    positions,
    turnaroundsSummary,
  } = mainContext;

  const [isProcessing, setIsProcessing] = useState(false);
  const [turnaroundInfo, setTurnaroundInfo] = useState(null);
  const [operationToEdit, setOperationToEdit] = useState(null);
  const [slotsToEdit, setSlotsToEdit] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [selectedUserUuid, setSelectedUserUuid] = useState(null);
  const [completedDetails, setCompletedDetails] = useState(null);

  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );

  // Fetch the turnaround requested from the taskInfo
  useEffect(() => {
    const turnaroundUuid = !isNullOrUndefined(
      taskInfo?.userTask?.turnaroundUuid
    )
      ? taskInfo.userTask.turnaroundUuid
      : taskInfo?.unassignedTask?.turnaroundUuid;
    if (!isNullOrUndefined(turnaroundUuid)) {
      getTurnaroundDetails(dispatch, {
        uuid: turnaroundUuid,
      });
    }
    // Set the selectedUser if present
    setSelectedUserUuid(taskInfo?.userUuid);

    return () => {
      /** Cleanup **/
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
      setTurnaroundInfo(null);
      setOperationToEdit(null);
      setSlotsToEdit(null);
      setIsProcessing(false);
      setSelectedUserUuid(null);
      setCompletedDetails(null);
    };
  }, [dispatch, taskInfo]);

  // Load the turnaround requested from the taskInfo
  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetails)) {
      setTurnaroundInfo(getTurnaroundInfo(turnaroundDetails));

      const operationUuid = !isNullOrUndefined(
        taskInfo?.userTask?.operationUuid
      )
        ? taskInfo.userTask.operationUuid
        : taskInfo?.unassignedTask?.operationUuid;
      if (!isNullOrUndefined(operationUuid)) {
        const sourceProfile = !isNullOrUndefined(turnaroundDetails?.monitor)
          ? turnaroundDetails.monitor
          : turnaroundDetails.profile;
        const selectedOperation = getByFieldValue(
          sourceProfile?.turnaroundOperations,
          "uuid",
          operationUuid
        );
        if (!isNullOrUndefined(selectedOperation)) {
          // Determine what slots are needed
          const slots = [];
          const requirements = selectedOperation?.turnaroundRequirements;
          sortByField(requirements, "certification.name");

          if (!isEmptyList(requirements)) {
            for (let i = 0; i < requirements.length; i++) {
              const requirement = requirements[i];
              const assigned = !isEmptyList(requirement.crewAssignments)
                ? requirement.crewAssignments.length
                : 0;
              const missingCount = requirement.quantity - assigned;
              const assignments = [];
              const slotId = requirement.uuid;

              if (!isEmptyList(requirement.crewAssignments)) {
                for (let j = 0; j < requirement.crewAssignments.length; j++) {
                  const crewAssignment = requirement.crewAssignments[j];
                  const user = getByFieldValue(
                    users,
                    "uuid",
                    crewAssignment.userUuid
                  );
                  // TODO: Implement actual assignedByUser
                  const assignedByUserUuid = null; //users[0].uuid;
                  const assignedByUser = !isNullOrUndefined(assignedByUserUuid)
                    ? getByFieldValue(users, "uuid", assignedByUserUuid)
                    : null;
                  assignments.push({
                    slotId: slotId,
                    slotAssignmentId: `${requirement.uuid}-${assignments.length}`,
                    requirement: requirement,
                    assignedUser: user,
                    assignedByUser: assignedByUser,
                  });
                }
              }

              if (missingCount > 0) {
                for (let j = 0; j < missingCount; j++) {
                  assignments.push({
                    slotId: slotId,
                    slotAssignmentId: `${requirement.uuid}-${assignments.length}`,
                    requirement: requirement,
                    assignedUser: null,
                  });
                }
              }
              if (assignments.length > 0) {
                slots.push({
                  slotId: slotId,
                  requirement: requirement,
                  assignments: assignments,
                });
              }
            }
          }
          setOperationToEdit(selectedOperation);
          setSlotsToEdit(slots);
        }

        // If completed, get the completion details
        if (
          taskInfo?.userTask?.completed &&
          !isNullOrUndefined(selectedOperation)
        ) {
          const sourceProfile = !isNullOrUndefined(turnaroundDetails?.monitor)
            ? turnaroundDetails.monitor
            : turnaroundDetails.profile;
          const operations = sourceProfile?.turnaroundOperations;
          const operationTypeUuid = selectedOperation?.type?.uuid;
          const completedOperation =
            !isNullOrUndefined(operationTypeUuid) && !isEmptyList(operations)
              ? getByFieldValue(operations, "type.uuid", operationTypeUuid)
              : null;

          if (
            !isNullOrUndefined(completedOperation?.markedCompleteAt) &&
            !isNullOrUndefined(completedOperation?.markedCompleteByUser)
          ) {
            setCompletedDetails({
              markedCompleteAt: completedOperation.markedCompleteAt,
              markedCompleteByUser: getUserInfo(
                completedOperation.markedCompleteByUser
              ),
            });
          }
        }
      }
    }
  }, [t, turnaroundDetails, taskInfo, users]);

  function updateSlots(assignedSlot, user) {
    const slotsToUpdate = deepCopy(slotsToEdit);
    const slotToUpdate = getByFieldValue(
      slotsToUpdate,
      "slotId",
      assignedSlot.slotId
    );
    const slotAssignmentToUpdate = getByFieldValue(
      slotToUpdate.assignments,
      "slotAssignmentId",
      assignedSlot.slotAssignmentId
    );
    if (!isNullOrUndefined(slotAssignmentToUpdate)) {
      slotAssignmentToUpdate.assignedUser = user;
    }
    setSlotsToEdit(slotsToUpdate);
    setSelectedSlotId(null);
  }

  async function handleSaveCrewAssignments() {
    if (isProcessing) return;
    setIsProcessing(true);

    const origOperation = getByFieldValue(
      turnaroundInfo?.originalProfile?.turnaroundOperations,
      "uuid",
      operationToEdit.uuid
    );

    const requirementsToPatch = getRequirementsToPatch(
      slotsToEdit,
      origOperation
    );

    if (isNullOrUndefined(turnaroundInfo.originalProfile)) {
      // This technically should not happen, but adding a check here
      debugLog(`Turnaround missing profile: ${turnaroundInfo.uuid}`);
    } else {
      const patchRequest = {
        uuid: turnaroundInfo.originalProfile.uuid,
        requirements: requirementsToPatch,
      };
      const result = await patchTurnaroundProfile(dispatch, patchRequest);
      if (result) {
        await getTurnaroundDetails(dispatch, turnaroundInfo);
        dispatch({
          type: "setAlertMessage",
          alertMessage: t("saved_web", {
            name: turnaroundInfo.combinedFlightName,
          }),
        });
      }
    }
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.LIVE_ROSTER_TASK_SAVE);
    setIsProcessing(false);
    handleCloseModal();
  }

  let modalTitle = null;
  if (
    !isNullOrUndefined(turnaroundInfo?.combinedFlightName) &&
    !isNullOrUndefined(operationToEdit?.type?.name)
  ) {
    modalTitle = `${turnaroundInfo?.combinedFlightName}: ${operationToEdit.type.name}`;
  }

  function handleCloseModal() {
    setTurnaroundInfo(null);
    setOperationToEdit(null);
    setSlotsToEdit(null);
    setSelectedSlotId(null);
    onClose();
  }

  const isCompleted = taskInfo?.userTask?.completed;

  // If is readonly, do not allow assigning
  const isReadonly =
    !hasEditTurnProfile ||
    isCompleted ||
    isNullOrUndefined(turnaroundInfo?.originalProfile);

  return (
    <div>
      <Modal open={!isNullOrUndefined(taskInfo)} onClose={handleCloseModal}>
        <div className="modal">
          {!isNullOrUndefined(turnaroundInfo) &&
            !isNullOrUndefined(operationToEdit) && (
              <>
                <div>
                  <div className="modal-header">
                    <h2>{t("assign_crew")}</h2>
                    <div className="button-icon" onClick={handleCloseModal}>
                      <Cross />
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="modal-container">
                    <div className="task-modal-content">
                      <div className="task-modal-header">
                        <div>
                          <div>
                            <h2>{modalTitle}</h2>
                          </div>
                          <div>
                            <span></span>
                          </div>
                        </div>
                        <div>
                          <span>
                            {!isNullOrUndefined(completedDetails) && (
                              <Completed />
                            )}
                            {!isNullOrUndefined(completedDetails)
                              ? t("completed_at_web", {
                                  value: formatTime(
                                    completedDetails.markedCompleteAt,
                                    timezone
                                  ),
                                  name: completedDetails.markedCompleteByUser
                                    .fullName,
                                })
                              : isReadonly
                              ? t("allocations_readonly_msg")
                              : t("allocations_msg")}
                          </span>
                        </div>
                      </div>
                      {!isEmptyList(slotsToEdit) && (
                        <div className="task-modal-slots-container">
                          {!isEmptyList(slotsToEdit) &&
                            slotsToEdit.map((slot) => {
                              const assignmentsCount = slot.assignments.length;
                              const assignmentsWithUser =
                                slot.assignments.filter(
                                  (i) => !isNullOrUndefined(i.assignedUser)
                                );
                              const assignmentsWithUserCount =
                                assignmentsWithUser.length;
                              return (
                                <div
                                  className="task-modal-slots"
                                  key={slot.slotId}
                                >
                                  <div className="task-modal-slots-name">
                                    <div>
                                      {slot.requirement.certification.name}
                                    </div>
                                    <div className="task-modal-slots-counts">
                                      <div>
                                        {assignmentsCount}
                                        <span>{t("required")}</span>
                                      </div>
                                      <div>
                                        {assignmentsWithUserCount}
                                        <span>{t("assigned")}</span>
                                      </div>
                                    </div>
                                  </div>
                                  {!isEmptyList(slot.assignments) &&
                                    slot.assignments.map((assignment) => (
                                      <div
                                        className="task-modal-slot"
                                        key={assignment.slotAssignmentId}
                                        data-seleted-user={selectedUserUuid}
                                        data-assigned-user={JSON.stringify(
                                          assignment?.assignedUser
                                        )}
                                      >
                                        <SelectCrew
                                          isActive={
                                            selectedSlotId ===
                                            assignment.slotAssignmentId
                                          }
                                          isProfileEditable={!isReadonly}
                                          isEditable={!isReadonly}
                                          isSelected={
                                            !isNullOrUndefined(
                                              selectedUserUuid
                                            ) &&
                                            selectedUserUuid ===
                                              assignment?.assignedUser?.uuid
                                          }
                                          onClick={() => {
                                            setSelectedSlotId(
                                              assignment.slotAssignmentId
                                            );
                                          }}
                                          onSelect={(selectedUser) => {
                                            updateSlots(
                                              assignment,
                                              selectedUser.user
                                            );
                                            logAnalyticsEvent(
                                              dispatch,
                                              ANALYTICS_EVENTS.LIVE_ROSTER_TASK_ADD_ASSIGNMENT
                                            );
                                          }}
                                          onRemove={() => {
                                            updateSlots(assignment, null);
                                            logAnalyticsEvent(
                                              dispatch,
                                              ANALYTICS_EVENTS.LIVE_ROSTER_TASK_REMOVE_ASSIGNMENT
                                            );
                                          }}
                                          onCancel={() => {
                                            setSelectedSlotId(null);
                                          }}
                                          requirement={slot.requirement}
                                          assignments={slot.assignments}
                                          assignedUser={assignment.assignedUser}
                                          assignedByUser={
                                            assignment.assignedByUser
                                          }
                                          users={users}
                                          positions={positions}
                                          turnarounds={turnaroundsSummary}
                                          shiftContext={{
                                            taskInfo: !isNullOrUndefined(
                                              taskInfo?.userTask
                                            )
                                              ? taskInfo.userTask
                                              : taskInfo.unassignedTask,
                                            rosterInfo: rosterInfo,
                                            timezone: timezone,
                                          }}
                                        />
                                      </div>
                                    ))}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="modal-footer">
                    <div>
                      <button className="secondary" onClick={handleCloseModal}>
                        {t("cancel")}
                      </button>
                    </div>
                    <div>
                      {isReadonly && (
                        <button
                          className="alternate"
                          onClick={handleCloseModal}
                        >
                          {t("close")}
                        </button>
                      )}
                      {!isReadonly && (
                        <button
                          onClick={handleSaveCrewAssignments}
                          disabled={!hasEditTurnProfile || isProcessing}
                        >
                          {t("save")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </Modal>
    </div>
  );
}

export default TaskModal;
