import "./styles.css";
import { useState, useEffect, useMemo } from "react";
import {
  deepCopy,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { ReactComponent as CircleCheck } from "../../assets/circle_check.svg";
import { ReactComponent as CircleCross } from "../../assets/circle_cross.svg";
import PageToolbar from "../PageToolbar";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import DataTable, { ColumnNames } from "../DataTable";
import {
  getGseTypes,
  getGses,
  getTrackers,
  removeVehicle,
  saveVehicle,
} from "../../api";
import VehicleModal from "./VehicleModal";
import { GroundVehicleStatus } from "../../gseUtils";
import LoadingIndicator from "../LoadingIndicator";
import { ANALYTICS_EVENTS, SEARCH_QUERY_PLACEHOLDERS } from "../../constants";
import { useTranslation } from "react-i18next";
import GseFilter from "./GseFilter";

function Vehicles() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { gses, gsesLoading, gseTypes, gsesSaving, trackers, searchQuery } =
    mainContext;

  const [selectedGses, setSelectedGses] = useState([]);
  const [gseToEdit, setGseToEdit] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSaveInitiated, setIsSaveInitiated] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    gseTypes: [],
  });

  const gseToRender = useMemo(() => {
    const gseList = [];

    if (!isEmptyList(gses)) {
      gses.forEach((item) => {
        if (
          isEmptyList(selectedFilters.gseTypes) ||
          selectedFilters.gseTypes.includes(item.gseType.uuid)
        ) {
          gseList.push(item);
        }
      });
    }
    return gseList;
  }, [gses, selectedFilters]);

  useEffect(() => {
    getGseTypes(dispatch);
    getGses(dispatch);
    getTrackers(dispatch);
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_VEHICLES,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (isSaveInitiated && !gsesSaving) {
      setSelectedGses([]);
      setIsSaveInitiated(false);
      setGseToEdit(null);

      // refetch
      getGses(dispatch);
      getTrackers(dispatch);
    }
  }, [dispatch, gsesSaving, isSaveInitiated, isConfirming]);

  const defaultGseType =
    !isNullOrUndefined(gseTypes) && gseTypes.length > 0
      ? gseTypes[0].uuid
      : null;

  const hasSelectedItems =
    !isNullOrUndefined(selectedGses) && selectedGses.length > 0;

  const hasMultipleSelectedItems =
    !isNullOrUndefined(selectedGses) && selectedGses.length > 1;

  const actionButtons = [
    <button
      className="secondary"
      disabled={!hasSelectedItems}
      onClick={() => {
        handleConfirmBulkAction("online");
      }}
    >
      <CircleCheck /> {t("set_online")}
    </button>,
    <button
      className="danger"
      disabled={!hasSelectedItems}
      onClick={() => {
        handleConfirmBulkAction("offline");
      }}
    >
      <CircleCross /> {t("set_offline")}
    </button>,
    <button
      className="danger"
      disabled={!hasSelectedItems || hasMultipleSelectedItems}
      onClick={() => {
        handleConfirmBulkAction("delete");
      }}
    >
      <Trash /> {t("delete")}
    </button>,
    <button
      onClick={() => {
        setSelectedGses([]);
        // Defaults for new vehicle
        setGseToEdit({
          name: "",
          trackerCode: "",
          gseType: { uuid: defaultGseType },
          statusId: GroundVehicleStatus.Available,
        });
      }}
    >
      <Plus /> {t("add_gse")}
    </button>,
    <GseFilter
      gseTypes={gseTypes}
      itemList={gses}
      selectedFilters={selectedFilters}
      onChange={(gseTypeUuid) => {
        if ("_none" === gseTypeUuid) {
          setSelectedFilters((prev) => {
            const updatedFilters = deepCopy(prev);
            updatedFilters.gseTypes = [];
            return updatedFilters;
          });
        } else {
          setSelectedFilters((prev) => {
            const updatedFilters = deepCopy(prev);
            if (updatedFilters.gseTypes.includes(gseTypeUuid)) {
              // Remove from filters
              updatedFilters.gseTypes.splice(
                updatedFilters.gseTypes.indexOf(gseTypeUuid),
                1
              );
            } else {
              updatedFilters.gseTypes.push(gseTypeUuid);
            }
            return updatedFilters;
          });
        }
        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.GSE_CHANGE_FILTERS);
      }}
    />,
  ];

  async function handleSave(item) {
    setIsSaveInitiated(true);
    const result = await saveVehicle(item, dispatch);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", { name: item.name }),
      });
    }
  }

  async function handleDelete(item) {
    setIsSaveInitiated(true);
    const result = await removeVehicle(item, dispatch);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("deleted_web", { name: item.name }),
      });
    }
    handleConfirmReset();
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.GSE_DELETE);
  }

  const handleConfirmReset = () => {
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
    setIsConfirming(false);
    setSelectedGses([]);
  };

  const handleConfirmDelete = (item) => {
    setIsConfirming(true);
    setSelectedGses([item.uuid]);
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: t("delete_msg_web", { name: item.name }),
        actionOK: () => {
          handleDelete(item);
        },
        actionCancel: () => {
          handleConfirmReset();
        },
      },
    });
  };

  async function handleBulkAction(actionType) {
    setIsSaveInitiated(true);
    const itemCount = selectedGses.length;
    let result = true;
    for (let i = 0; i < itemCount; i++) {
      const item = gseToRender.find((item) => item.uuid === selectedGses[i]);
      const itemToAction = {
        uuid: item.uuid,
        trackerCode: item.trackerCode,
        name: item.name,
        gseTypeUuid: item.gseType?.uuid,
        statusId: item.statusId,
      };
      let bulkResult = true;
      if (actionType === "online") {
        itemToAction.statusId = GroundVehicleStatus.Available;
        bulkResult = await saveVehicle(itemToAction, dispatch);
      } else if (actionType === "offline") {
        itemToAction.statusId = GroundVehicleStatus.Offline;
        bulkResult = await saveVehicle(itemToAction, dispatch);
      } else if (actionType === "delete") {
        bulkResult = await removeVehicle(itemToAction, dispatch);
      }
      // flag if any bulk action did not succeed
      if (!bulkResult) result = false;
    }
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: `${
          actionType === "delete"
            ? t("deleted_gses_msg")
            : t("updated_gses_msg")
        }`,
      });
    }
    handleConfirmReset();
  }

  const handleConfirmBulkAction = (actionType) => {
    setIsConfirming(true);
    let message = null;
    let eventName = null;
    if (actionType === "online") {
      message = t("online_confirm_msg");
      eventName = ANALYTICS_EVENTS.GSE_SET_ONLINE;
    } else if (actionType === "offline") {
      message = t("offline_confirm_msg");
      eventName = ANALYTICS_EVENTS.GSE_SET_OFFLINE;
    } else if (actionType === "delete") {
      message = t("delete_confirm_msg");
      eventName = ANALYTICS_EVENTS.GSE_DELETE;
    }
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: message,
        actionOK: () => {
          handleBulkAction(actionType);
          if (!isNullOrUndefined(eventName)) {
            logAnalyticsEvent(dispatch, eventName);
          }
        },
        actionCancel: () => {
          handleConfirmReset();
        },
      },
    });
  };

  return (
    <div className="vehicles">
      <div className="vehicles-header">
        <PageToolbar
          title={t("vehicles")}
          actionButtons={actionButtons}
          badgeValue={gseToRender?.length}
        />
      </div>
      <div className="vehicles-container">
        {gsesLoading && <LoadingIndicator />}
        {!gsesLoading && (
          <DataTable
            dataList={gseToRender}
            selectedUuids={selectedGses}
            searchQuery={searchQuery}
            columnNames={[
              ColumnNames.VehicleName,
              ColumnNames.VehicleType,
              ColumnNames.Tracker,
              ColumnNames.LastOnline,
              ColumnNames.Battery,
              ColumnNames.VehicleStatus,
              ColumnNames.MapVehicle,
            ]}
            rowActions={{
              editAction: (item) => {
                setSelectedGses([item.uuid]);
                setGseToEdit(item);
              },
              deleteAction: (item) => {
                setSelectedGses([item.uuid]);
                handleConfirmDelete(item);
              },
            }}
            hasMultiSelect={true}
            onSelectHandler={(items) => {
              if (isNullOrUndefined(items) || items.length === 0) {
                setSelectedGses([]);
                return;
              }
              const selectedGsesNew = [...selectedGses];
              for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const indexOfItem = selectedGsesNew.indexOf(item.uuid);
                if (indexOfItem > -1) {
                  selectedGsesNew.splice(indexOfItem, 1);
                } else {
                  selectedGsesNew.push(item.uuid);
                }
              }
              setSelectedGses(selectedGsesNew);
            }}
          />
        )}
        <VehicleModal
          selectedItem={gseToEdit}
          onCloseHandler={() => {
            setGseToEdit(null);
          }}
          onSaveHandler={(values) => {
            handleSave(values);
            if (isNullOrUndefined(values.uuid)) {
              logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.GSE_ADD);
            } else {
              logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.GSE_SAVE);
            }
          }}
          gseTypes={gseTypes}
          trackers={trackers}
          gsesSaving={gsesSaving}
        />
      </div>
    </div>
  );
}
export default Vehicles;
