import { useTranslation } from "react-i18next";
import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as CaretRight } from "../../assets/caret-right.svg";
import {
  getUserInfoForMap,
  handleHover,
  MAPINFO_MARKER_TYPE,
} from "../../mapUtils";
import { formatTime, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import CrewInfo from "../CrewInfo";
import { ANALYTICS_EVENTS } from "../../constants";

function CrewInfoModule(props) {
  const { t } = useTranslation();
  const { info, isSelected, airportTimezone } = props;
  const dispatch = useMainDispatchContext();
  const details = !isNullOrUndefined(info?.activeTurnaroundInfo)
    ? `${t("servicing")}: ${info.activeTurnaroundInfo.combinedFlightName}`
    : info.certNames;

  const currentShift = info?.relativeCrewShifts?.currentShift;
  const nextShift = info?.relativeCrewShifts?.nextShift;
  const shiftToDisplay = !isNullOrUndefined(currentShift)
    ? currentShift
    : nextShift;
  const shiftDisplayValue = !isNullOrUndefined(shiftToDisplay)
    ? `${formatTime(shiftToDisplay.startTime, airportTimezone)} - ${formatTime(
        shiftToDisplay.endTime,
        airportTimezone
      )}`
    : null;

  const userInfo = getUserInfoForMap(info.userPosition, info);
  return (
    <div
      className={`overlay-module${isSelected ? " selected" : ""}`}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.USER,
            item: {
              uuid: info.uuid,
            },
          },
        });
        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.MAP_VIEW_CREW_TILE);
      }}
      onMouseOver={() => {
        handleHover(true, userInfo);
      }}
      onMouseOut={(e) => {
        handleHover(false, userInfo);
      }}
      data-uuid={info.uuid}
    >
      <div className="overlay-module-box">
        <div className="crew-member-info">
          <div>
            <CrewInfo
              user={info.user}
              details={
                <div className="crew-member-info-details all-caps">
                  <div>{details}</div>
                  {!isNullOrUndefined(shiftToDisplay) && (
                    <div>
                      {t("on_shift")}: {shiftDisplayValue}
                    </div>
                  )}
                  {isNullOrUndefined(shiftToDisplay) && (
                    <div>{t("off_shift")}</div>
                  )}
                </div>
              }
              mapStatus={info.mapStatus}
            />
          </div>
          <div>
            <CaretRight className="arrow-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrewInfoModule;
