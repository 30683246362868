import "./styles.css";
import { ReactComponent as Search } from "../../assets/search.svg";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { logAnalyticsEvent } from "../../utils";
function SectionToolbar(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { searchQuery, searchbarDisabled } = mainContext;
  const { eventName } = props;
  const searchPlaceholder = searchQuery?.searchPlaceholder;
  const searchTerm = searchQuery?.searchTerm;
  const containerRef = useRef(null);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    // Reset the hasSearched when eventName changes
    setHasSearched(false);
  }, [eventName]);

  return (
    <div
      className={`search-input-container${
        searchbarDisabled ? " disabled" : ""
      }`}
      ref={containerRef}
    >
      <Search />
      <input
        value={searchTerm || ""}
        placeholder={t(searchPlaceholder)}
        onChange={(e) => {
          const searchQueryToUpdate = {
            ...searchQuery,
            searchTerm: e.target.value,
          };
          dispatch({
            type: "setSearchQuery",
            value: searchQueryToUpdate,
          });
          if (!hasSearched) {
            // Log only one event (not every keystroke)
            logAnalyticsEvent(dispatch, eventName);
            setHasSearched(true);
          }
        }}
        onFocus={() => {
          containerRef.current.classList.add("focused");
        }}
        onBlur={() => {
          containerRef.current.classList.remove("focused");
        }}
        disabled={searchbarDisabled}
      />
    </div>
  );
}
export default SectionToolbar;
