import "./styles.css";
import { useEffect } from "react";

import { useMainContext, useMainDispatchContext } from "../../MainContext";
import MainMenu from "../MainMenu";
import UserBadge from "../UserBadge";
import Crew from "../Crew";
import Dashboard from "../Dashboard";
import Vehicles from "../Vehicles";
import { getDynamicEnv, getUser, logout } from "../../api";
import { isBlank, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import Flights from "../Flights";
import {
  ResourceActions,
  userHasAnyResourceAction,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import Settings from "../Settings";
import SectionToolbar from "../SectionToolbar";
import Map from "../Map";
import { CookieManager } from "../../cookies";
import Dispatches from "../Dispatches";
import Templates from "../Templates";
import ConsoleDataHelper from "./ConsoleDataHelper";
import Chat from "../Chat";
import CrewShifts from "../CrewShifts";
import CrewShiftsProvider from "../../CrewShiftsProvider";
import SettingsProvider from "../../SettingsProvider";
import Notifications from "../Notifications";
import TurnaroundsModule from "../TurnaroundsModule";
import { useParams } from "react-router-dom";
import LiveRoster from "../LiveRoster";
import TasksOverviewProvider from "../../TasksOverviewProvider";
import OperationPerformance from "../OperationPerformance";
import CrewLeaderboard from "../CrewLeaderboard";
import Keys from "../Keys";
import { checkSupportedBrowser } from "../../i18n/browserUtils";
import UnsupportedBrowser from "../UnsupportedBrowser";
import { ANALYTICS_EVENTS, MenuMode } from "../../constants";
import MenuHandle from "../MainMenu/MenuHandle";
import { SettingNames, SettingsManager } from "../../SettingsManager";

function MainConsole(props) {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, dynamicEnv, localSettings } = mainContext;
  const { section } = props;

  const isCompactMode = !isNullOrUndefined(localSettings)
    ? MenuMode.COMPACT === localSettings[SettingNames.MENU_MODE.name]
    : MenuMode.COMPACT ===
      SettingsManager.getSetting(SettingNames.MENU_MODE.name);

  const isInternal = currentUser?.isInternal;

  useEffect(() => {
    getDynamicEnv(dispatch);
  }, [dispatch]);

  // TODO: Need a non-admin version of getUserGroups
  // useEffect(() => {
  //   if (!isNullOrUndefined(currentUser?.userGroupUuid)) {
  //     getUserGroups(dispatch);
  //   }
  // }, [dispatch, currentUser?.userGroupUuid]);

  useEffect(() => {
    // check if we can refresh the login user
    if (isNullOrUndefined(currentUser)) {
      // const accessToken = localStorage.getItem("accessToken");
      const accessToken = CookieManager.getCookieValue("accessToken");
      if (!isBlank(accessToken)) {
        getUser(dispatch);
        return () => {};
      }
      logout();
    }
  }, [currentUser, dispatch]);

  const hasEditAdminUsers = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminUsers
  );
  const hasEditAdminGse = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminGse
  );
  // Temp disable the performance pages because it needs refactoring
  const hasViewLeaderboard = userHasResourceAction(
    currentUser,
    ResourceActions.ViewUserLeaderboard
  );
  const hasViewGseUtilization = false; // TODO: Remove this
  const hasViewOnTimePerformance = userHasResourceAction(
    currentUser,
    ResourceActions.ViewOnTimePerformance
  );
  const hasEditTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnTemplate
  );
  // TODO Remove
  // const hasEditGateSchedule = userHasAnyResourceAction(
  //   currentUser,
  //   ResourceActions.EditStandAssignment
  // );
  const hasCrewShifts = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditCrewShifts
  );
  const hasLiveRoster = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewLiveRoster
  );

  const showMap = !isNullOrUndefined(dynamicEnv?.mapBoxToken);
  // NOTE: Deprecating the allocations page
  const showDispatch = false; //hasEditTurnProfile;
  const showTurnaroundTemplates = hasEditTurnTemplate;

  // TODO: Update after refactoring ResourceActions
  const showLiveRoster = hasLiveRoster;

  // TODO: Remove this feature
  const showFlights = false;
  // TODO: Remove this feature (may be replaced with Alerts 2.0)
  const showNotifications = false;

  const isSupportedBrowser = checkSupportedBrowser();

  const { turnaroundUuid } = useParams();
  const isTurnarounds = section === "turnarounds";
  const showTurnaroundToolbar = isTurnarounds && isBlank(turnaroundUuid);

  const escKeyAction = () => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
      },
    });
  };
  const ctrlKeyActions = {
    f: () => {
      const inputEl = document.querySelector(".search-input-container input");
      if (!isNullOrUndefined(inputEl)) {
        inputEl.focus();
      }
    },
  };

  return (
    <div className={`main-console${isCompactMode ? " compact" : ""}`}>
      <div className="main-side-panel">
        <MainMenu
          showAdminUsers={hasEditAdminUsers}
          showAdminGses={hasEditAdminGse}
          showLeaderboard={hasViewLeaderboard}
          showPerformance={hasViewOnTimePerformance}
          showUtilization={hasViewGseUtilization}
          showDispatch={showDispatch}
          showTurnaroundTemplates={showTurnaroundTemplates}
          showGateSchedule={false} // TODO: Remove
          showCrewShifts={hasCrewShifts}
          showChat={!isInternal}
          showLiveRoster={showLiveRoster}
          showFlights={showFlights}
          showNotifications={showNotifications}
          isCompactMode={isCompactMode}
        />
      </div>
      <div className="main-container">
        <MenuHandle
          onToggleCompactMode={() => {
            const menuMode = isCompactMode
              ? MenuMode.DEFAULT
              : MenuMode.COMPACT;
            SettingsManager.setSetting(SettingNames.MENU_MODE.name, menuMode);
            SettingsManager.updateState(dispatch);

            logAnalyticsEvent(
              dispatch,
              ANALYTICS_EVENTS.NAV_MENU_TOGGLE,
              menuMode
            );
          }}
        />
        <div className="main-header">
          <div className="header-left">
            {isSupportedBrowser && (
              <>
                {showTurnaroundToolbar && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.TURNAROUNDS_SEARCH}
                  />
                )}
                {section === "flights" && <SectionToolbar />}
                {section === "users" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.USERS_SEARCH} />
                )}
                {section === "vehicles" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.GSE_SEARCH} />
                )}
                {section === "map" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.MAP_SEARCH} />
                )}
                {section === "crew-shifts" && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.CREW_SHIFTS_SEARCH}
                  />
                )}
                {section === "live-roster" && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.LIVE_ROSTER_SEARCH}
                  />
                )}
                {section === "chat" && (
                  <SectionToolbar eventName={ANALYTICS_EVENTS.CHAT_SEARCH} />
                )}
                {section === "stand-assignments" && (
                  <SectionToolbar
                    eventName={ANALYTICS_EVENTS.STAND_ASSIGNMENTS_SEARCH}
                  />
                )}
              </>
            )}
          </div>
          <div className="header-center"></div>
          <div className="header-right">
            <UserBadge
              user={currentUser}
              menuEnabled={true}
              secondaryInfoField="userGroupUuid"
              isProfileBadge={true}
            />
          </div>
        </div>
        {isSupportedBrowser && (
          <div className="main-content-box">
            {section === "dashboard" && <Dashboard />}
            {showFlights && section === "flights" && <Flights />}
            {showLiveRoster && section === "live-roster" && (
              <CrewShiftsProvider>
                <TasksOverviewProvider>
                  <LiveRoster />
                </TasksOverviewProvider>
              </CrewShiftsProvider>
            )}
            {section === "map" && showMap && <Map />}
            {section === "chat" && <Chat />}
            {section === "turnarounds" && <TurnaroundsModule />}
            {section === "turnarounds-details" && <TurnaroundsModule />}
            {section === "turnarounds-timeline" && (
              <TurnaroundsModule subPath={"timeline"} />
            )}
            {section === "allocations" && <Dispatches />}
            {section === "users" && hasEditAdminUsers && (
              <CrewShiftsProvider>
                <Crew />
              </CrewShiftsProvider>
            )}
            {section === "vehicles" && hasEditAdminGse && <Vehicles />}
            {section === "templates" && showTurnaroundTemplates && (
              <Templates />
            )}
            {/** TODO: Remove
             section === "stand-assignments" && hasEditGateSchedule && (
              <GateSchedule />
            )**/}
            {section === "crew-shifts" && hasCrewShifts && (
              <CrewShiftsProvider>
                <CrewShifts />
              </CrewShiftsProvider>
            )}
            {showNotifications && section === "notifications" && (
              <Notifications />
            )}
            {section === "settings" && (
              <SettingsProvider>
                <Settings />
              </SettingsProvider>
            )}
            {section === "performance" && hasViewOnTimePerformance && (
              <OperationPerformance />
            )}
            {section === "leaderboard" && hasViewLeaderboard && (
              <CrewLeaderboard />
            )}
          </div>
        )}
        {!isSupportedBrowser && (
          <div className="main-content-box">
            <UnsupportedBrowser />
          </div>
        )}
      </div>
      <ConsoleDataHelper />
      {/* TODO: Remove {hasEditGateSchedule && <MoveAircraftReminder />} */}
      {
        // NOTE: Map page has its own keys handler
        section !== "map" && (
          <Keys ctrlKeyActions={ctrlKeyActions} escKeyAction={escKeyAction} />
        )
      }
    </div>
  );
}

export default MainConsole;
