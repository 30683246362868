import { useTranslation } from "react-i18next";
import "./styles.css";
function ButtonSwitch(props) {
  const { t } = useTranslation();

  const {
    selectedOption,
    onChange,
    labelValuePairs,
    wideMode,
    lightMode,
    disabled,
  } = props;

  const buttonSwitchStyle = {
    width: `${labelValuePairs?.length * 140}px`,
  };
  const buttonSwitchBoxStyle = {
    gridTemplateColumns: `repeat(${labelValuePairs?.length}, 1fr)`,
  };

  return (
    <div
      className={`button-switch${disabled ? " disabled" : ""}${
        wideMode ? " wide" : ""
      }${lightMode ? " light" : ""}`}
      style={buttonSwitchStyle}
    >
      <div className="button-switch-box" style={buttonSwitchBoxStyle}>
        {labelValuePairs &&
          labelValuePairs.map((labelValuePair, idx) => (
            <div
              key={idx}
              className={`button-switch-option ${
                selectedOption === labelValuePair.value ? "selected" : ""
              }`}
              onClick={() => {
                if (disabled) return;
                onChange(labelValuePair.value);
              }}
            >
              <span>{t(labelValuePair.value)}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
export default ButtonSwitch;
