import { useEffect, useState } from "react";
import {
  deepCopy,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  makeKeyFriendlyString,
} from "../../utils";
import { useTranslation } from "react-i18next";
import TimeRangeInput from "../TimeRangeInput";
import { selectInputFix } from "../../styleUtils";
import SelectCrewMember from "../Dispatches/SelectCrewMember";
import { getUserInfo } from "../../userUtils";
import moment from "moment-timezone";
import CrewInfo from "../CrewInfo";

function CrewShiftsSlot(props) {
  const { t } = useTranslation();

  const {
    isEditable,
    users,
    onAddCrewShift,
    selectedDate,
    airportTimezone,
    turnarounds,
    positions,
    onSaveCrewShift,
    departments,
    isActive,
    onSetActive,
  } = props;
  const [crewShiftToEdit, setCrewShiftToEdit] = useState(null);

  const mCrewShiftDate = moment(selectedDate)
    .tz(airportTimezone)
    .startOf("day");

  useEffect(() => {
    setCrewShiftToEdit(null);
  }, [selectedDate]);

  return (
    <>
      {
        /** Read mode **/
        isNullOrUndefined(crewShiftToEdit) && (
          <div
            className={`crew-shifts-module-row slot${
              !isEditable ? " read-only" : ""
            }${!isActive ? " hideout" : ""}`}
          >
            <div className="slot-placeholder">
              <div>
                <div>
                  {isActive && (
                    <SelectCrewMember
                      onSelect={(selectedUser) => {
                        if (!isNullOrUndefined(selectedUser?.user)) {
                          const userInfo = getUserInfo(
                            selectedUser?.user,
                            turnarounds,
                            positions
                          );
                          const mNextDay = moment(mCrewShiftDate).add(1, "day");
                          setCrewShiftToEdit({
                            userUuid: userInfo.uuid,
                            userInfo: userInfo,
                            startTime: mCrewShiftDate.toDate().toISOString(),
                            endTime: mNextDay.toDate().toISOString(),
                            departmentUuid: departments[0].uuid,
                            departmentName: departments[0].name,
                          });
                        }
                      }}
                      onCancel={() => {
                        onAddCrewShift(null);
                        onSetActive(false);
                      }}
                      users={users}
                      positions={positions}
                      turnarounds={turnarounds}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        /** Edit mode **/
        !isNullOrUndefined(crewShiftToEdit) && (
          <div className={`crew-shifts-module-row slot`}>
            <div>
              <CrewInfo
                user={crewShiftToEdit.userInfo.user}
                details={t(crewShiftToEdit.userInfo.dispatchStatus)}
                mapStatus={crewShiftToEdit.userInfo.mapStatus}
              />
            </div>
            <div>
              <div>
                <TimeRangeInput
                  onTimeRangeChange={(timerangeValues) => {
                    const updatedCrewShift = deepCopy(crewShiftToEdit);
                    updatedCrewShift.startTime = timerangeValues.startTime;
                    updatedCrewShift.endTime = timerangeValues.endTime;
                    setCrewShiftToEdit(updatedCrewShift);
                  }}
                  timeRangeValues={{
                    startTime: crewShiftToEdit.startTime,
                    endTime: crewShiftToEdit.endTime,
                  }}
                  timezone={airportTimezone}
                />
              </div>
            </div>
            <div>
              <div>
                {!isEmptyList(departments) && (
                  <select
                    className="departments-input"
                    style={selectInputFix}
                    value={
                      !isBlank(crewShiftToEdit.departmentUuid)
                        ? crewShiftToEdit.departmentUuid
                        : ""
                    }
                    onChange={(e) => {
                      crewShiftToEdit.departmentUuid = e.target.value;
                    }}
                  >
                    {departments &&
                      departments.map((department) => (
                        <option
                          value={department.uuid}
                          key={makeKeyFriendlyString(department.uuid)}
                        >
                          {department.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="actions">
              <div>
                <button
                  className="outlined"
                  onClick={() => {
                    setCrewShiftToEdit(null);
                    onAddCrewShift(null);
                    onSetActive(false);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  className="primary"
                  onClick={() => {
                    onSaveCrewShift(crewShiftToEdit);
                    setCrewShiftToEdit(null);
                    onSetActive(false);
                  }}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
export default CrewShiftsSlot;
