import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Attachment } from "../../assets/attachment.svg";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

import {
  CHAT_CATEGORY_TYPES,
  getChannelInfoFromSummary,
  getChannelInfoFromTurnaroundInfo,
} from "../../commsUtils";
import {
  deepCopy,
  getArrayDifference,
  getByFieldValue,
  getTimezoneFromUser,
  isBlank,
  isEmptyList,
  isNullOrUndefined,
  isSearchQueryMatch,
  logAnalyticsEvent,
  scrollElementIntoView,
  sortByField,
} from "../../utils";
import ChannelInfo from "./ChannelInfo";
import ChatChannelEditor from "./ChatChannelEditor";
import ChatMessage from "./ChatMessage";
import "./styles.css";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import LoadingIndicator from "../LoadingIndicator";
import ChatBubble from "./ChatBubble";
import ChatAttachmentModal from "./ChatAttachmentModal";
import {
  ANALYTICS_EVENTS,
  DEFAULT_TIMEZONE,
  POLLING_INTERVALS,
  SEARCH_QUERY_PLACEHOLDERS,
} from "../../constants";
import { useChatContext, useChatDispatchContext } from "../../ChatContext";
import {
  getChatAttachmentUpload,
  getChatChannelDetails,
  getChatSummary,
  patchChatChanneLastRead,
  patchChatChannelMembers,
  postChatChannel,
  postChatChannelMessage,
  uploadChatAttachment,
} from "../../chatApi";
import { getTurnaroundsSummary, getUsers } from "../../api";
import ChatChannel from "./ChatChannel";
import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";

export const ChatModuleMode = {
  STANDARD: "STANDARD",
  COMPACT: "COMPACT",
};

function ChatModule(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const chatDispatch = useChatDispatchContext();
  const chatContext = useChatContext();
  const {
    currentUser,
    turnaroundsSummary,
    selectedChannelId,
    selectedChatRequest,
    positions,
    isRefreshPaused,
    users,
    searchQuery,
  } = mainContext;
  const timezone = !isNullOrUndefined(currentUser)
    ? getTimezoneFromUser(currentUser)
    : DEFAULT_TIMEZONE;
  const { mode, isActive } = props;

  const {
    chatSummary,
    chatChannel,
    chatChannelLoading,
    chatChannelLastReadSaving,
    chatAttachmentUpload,
    selectedAttachment,
    createChatChannel, // newly created channels
  } = chatContext;
  const [messageText, setMessageText] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isPostingMessage, setIsPostingMessage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [channelEditorMode, setChannelEditorMode] = useState(null); // edit, create
  const [channelEditorType, setChannelEditorType] = useState(null);
  const [channelToEdit, setChannelToEdit] = useState(null);
  const [selectedChannelInfo, setSelectedChannelInfo] = useState(null);
  const [mediaSignatures, setMediaSignatures] = useState(null);
  const [selectedTurnaroundToView, setSelectedTurnaroundToView] =
    useState(null);
  const [isOtherTurnaroundChannelsOpen, setIsOtherTurnaroundChannelsOpen] =
    useState(false);
  const [scrollToChannelRequested, setScrollToChannelRequested] =
    useState(false);

  // Requests for polling
  const [selectedChatChannelRequested, setSelectedChatChannelRequested] =
    useState(false);
  const [chatChannelReadRequested, setChatChannelReadRequested] =
    useState(false);
  const chatInputRef = useRef(null);
  const chatFileInputRef = useRef(null);

  async function handleSend() {
    if (isPostingMessage) return;
    setIsPostingMessage(true);
    const content = {
      text: messageText,
    };
    setMessageText("");

    // Posting the message has a slight delay so for UX purposes
    // Create a temp message in the state (which animates in)
    chatDispatch({
      type: "setTempChannelMessage",
      value: messageText,
      channelId: selectedChannelId,
      sentBy: currentUser.uuid,
    });
    setTimeout(() => {
      scrollMessages();
    }, 100);

    // The following fetch will just overwrite the temp message in state
    async function handlePostMessage() {
      await postChatChannelMessage(chatDispatch, selectedChannelId, content);
      await getChatChannelDetails(chatDispatch, selectedChannelId);
      await getChatSummary(chatDispatch);
      setChatChannelReadRequested(true);
      setTimeout(() => {
        setIsPostingMessage(false);
        chatInputRef?.current?.focus();
        scrollMessages();
      }, 100);
    }
    handlePostMessage();
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.CHAT_SEND);
  }

  const chatMessagesRef = useRef(null);

  const resetEditor = useCallback(() => {
    setChannelEditorMode(null);
    setChannelToEdit(null);
    setChannelEditorType(null);
    setSelectedChannelInfo(null);
    chatDispatch({
      type: "setChatChannel",
      value: null,
    });
  }, [chatDispatch]);

  const scrollMessages = useCallback(
    (instant) => {
      if (!isNullOrUndefined(selectedChannelId)) {
        setChatChannelReadRequested(true);
      }
      const chatBody = document.querySelector(".chat-body");
      if (!isNullOrUndefined(chatBody)) {
        chatBody.scrollTo({
          top: document.querySelector(".chat-body").scrollHeight,
          behavior: instant ? "instant" : "smooth",
        });
      }
    },
    [selectedChannelId]
  );

  // Prepare the list of all channels
  const [channelInfoList, otherTurnaroundChannelList] = useMemo(() => {
    const channelInfos = [];
    const turnaroundChannels = [];
    const turnaroundsUuids = []; // Turnaround channels
    if (!isEmptyList(chatSummary?.channels)) {
      for (let j = 0; j < chatSummary.channels.length; j++) {
        const channel = chatSummary.channels[j];
        const channelInfo = getChannelInfoFromSummary(
          channel,
          users,
          turnaroundsSummary,
          positions
        );

        const isFiltered = !isBlank(searchQuery?.searchTerm)
          ? !isSearchQueryMatch(searchQuery?.searchTerm, channelInfo.name)
          : false;
        if (channelInfo.isValidChannel && !isFiltered) {
          channelInfos.push(channelInfo);
        }
        if (!isNullOrUndefined(channel.turnaroundUuid)) {
          turnaroundsUuids.push(channel.turnaroundUuid);
        }
      }
      sortByField(channelInfos, "name");
    }
    if (!isEmptyList(turnaroundsSummary)) {
      for (let i = 0; i < turnaroundsSummary.length; i++) {
        const turnaround = turnaroundsSummary[i];
        if (!turnaroundsUuids.includes(turnaround.uuid)) {
          const turnaroundInfo =
            getTurnaroundInfoForTurnaroundSummary(turnaround);

          // Create a channel info for other turnarounds
          const isFiltered = !isBlank(searchQuery?.searchTerm)
            ? !isSearchQueryMatch(
                searchQuery?.searchTerm,
                turnaroundInfo.combinedFlightName
              )
            : false;
          if (!isFiltered) {
            turnaroundChannels.push({
              isTurnaround: true,
              name: turnaroundInfo.combinedFlightName,
              channelId: turnaround.chatChannelId,
              turnaroundInfo: turnaroundInfo,
            });
          }
        }
      }
    }
    if (isEmptyList(channelInfos)) {
      setIsInitializing(false);
    }
    sortByField(turnaroundChannels, "name");
    return [channelInfos, turnaroundChannels];
  }, [
    chatSummary,
    users,
    positions,
    turnaroundsSummary,
    searchQuery?.searchTerm,
  ]);

  useEffect(() => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH,
      },
    });
  }, [dispatch]);

  // Initial load
  useEffect(() => {
    getChatSummary(chatDispatch);
  }, [chatDispatch]);

  useEffect(() => {
    dispatch({
      type: "setSelectedChannelId",
      value: null,
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      isNullOrUndefined(selectedChatRequest) &&
      isNullOrUndefined(selectedChannelId) &&
      !isEmptyList(channelInfoList)
    ) {
      // No selected chat requested, try selecting the first one
      dispatch({
        type: "setSelectedChannelId",
        value: channelInfoList[0].channelId,
      });
    } else if (
      !isNullOrUndefined(selectedChatRequest) &&
      CHAT_CATEGORY_TYPES.DIRECT === selectedChatRequest.type &&
      !isNullOrUndefined(selectedChatRequest.userUuid) &&
      !isEmptyList(channelInfoList)
    ) {
      // check if already exists
      let dmChannel = null;
      for (
        let i = 0;
        i < channelInfoList.length && isNullOrUndefined(dmChannel);
        i++
      ) {
        const channelToCheck = channelInfoList[i];
        if (selectedChatRequest.userUuid === channelToCheck.otherUserUuid) {
          dmChannel = channelToCheck;
        }
      }
      if (!isNullOrUndefined(dmChannel)) {
        // Found the channel, set to selected
        dispatch({
          type: "setSelectedChannelId",
          value: dmChannel.channelId,
        });
      } else {
        // Need to create the channel
        async function createChannel() {
          const result = await postChatChannel(chatDispatch, {
            members: [selectedChatRequest.userUuid],
          });
          if (result) {
            await getChatSummary(chatDispatch);
            // dispatch the selectedChatRequest again
            dispatch({
              type: "setSelectedChatRequest",
              value: selectedChatRequest,
            });
          }
        }
        createChannel();
      }
    } else if (
      !isNullOrUndefined(selectedChatRequest) &&
      CHAT_CATEGORY_TYPES.TURNAROUND === selectedChatRequest.type &&
      !isNullOrUndefined(selectedChatRequest.channelId)
    ) {
      // check if channel already exists
      const turnaroundChannel = !isEmptyList(channelInfoList)
        ? getByFieldValue(
            channelInfoList,
            "turnaroundUuid",
            selectedChatRequest.channelId
          )
        : null;
      if (isNullOrUndefined(turnaroundChannel)) {
        // User is not in the turnaround chat, but can still peer into it
        getChatChannelDetails(chatDispatch, selectedChatRequest.channelId);
        if (!isNullOrUndefined(selectedChatRequest.selectedTurnaround)) {
          setSelectedTurnaroundToView(selectedChatRequest.selectedTurnaround);
        }
        setIsOtherTurnaroundChannelsOpen(true);
        setScrollToChannelRequested(true);
      }

      dispatch({
        type: "setSelectedChannelId",
        value: selectedChatRequest.channelId,
      });
    }
    dispatch({
      type: "setSelectedChatRequest",
      value: null,
    });
  }, [
    dispatch,
    chatDispatch,
    selectedChatRequest,
    channelInfoList,
    selectedChannelId,
  ]);

  useEffect(() => {
    if (isNullOrUndefined(selectedChannelId)) return () => {};
    setChatChannelReadRequested(true);
    chatInputRef?.current?.focus();
    resetEditor();
    scrollMessages(true);
  }, [chatDispatch, selectedChannelId, scrollMessages, resetEditor]);

  // Update read state for channel
  useEffect(() => {
    if (!chatChannelLastReadSaving && chatChannelReadRequested) {
      const channelMembers = selectedChannelInfo?.channel?.members;
      if (isEmptyList(channelMembers)) return () => {};
      const isInChannel = channelMembers.includes(currentUser.uuid);
      if (!isInChannel) return () => {};

      async function updateReadState() {
        const mNow = moment().tz(timezone);
        await patchChatChanneLastRead(
          chatDispatch,
          selectedChannelInfo?.channel.channelId,
          mNow.toDate().toISOString()
        );
        await getChatSummary(chatDispatch);
      }
      updateReadState();
      setChatChannelReadRequested(false);
    }
  }, [
    currentUser,
    chatDispatch,
    chatChannelReadRequested,
    chatChannelLastReadSaving,
    selectedChannelInfo,
    timezone,
  ]);

  // Refresh the selected channel here
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!isActive) return () => {};
    if (!selectedChatChannelRequested) return () => {};
    if (!chatChannelLoading && selectedChannelId) {
      getChatChannelDetails(chatDispatch, selectedChannelId);
      setSelectedChatChannelRequested(false);
    }
  }, [
    chatDispatch,
    selectedChannelId,
    chatChannelLoading,
    isRefreshPaused,
    selectedChatChannelRequested,
    isActive,
  ]);

  // Get supporting data
  useEffect(() => {
    // These are polled for, but just get the latest on load
    getUsers(dispatch);
    getTurnaroundsSummary(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const intervals = [];
    // NOTE: Do NOT refresh channels here, see ConsoleDataHelper
    if (POLLING_INTERVALS.COMMS_CHANNEL > 0) {
      const interval = setInterval(() => {
        setSelectedChatChannelRequested(true);
      }, POLLING_INTERVALS.COMMS_CHANNEL);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !isNullOrUndefined(currentUser) &&
      !isNullOrUndefined(users) &&
      !isNullOrUndefined(turnaroundsSummary) &&
      !isNullOrUndefined(positions) &&
      !isNullOrUndefined(channelInfoList) &&
      !isNullOrUndefined(selectedChannelId) &&
      !isNullOrUndefined(chatChannel)
    ) {
      let channelInfo = !isNullOrUndefined(selectedChannelId)
        ? getByFieldValue(channelInfoList, "channelId", selectedChannelId)
        : null;

      if (
        isNullOrUndefined(channelInfo) &&
        !isNullOrUndefined(selectedTurnaroundToView)
      ) {
        // Create a placeholder channelInfo from the selectedTurnaroundToView
        channelInfo = getChannelInfoFromTurnaroundInfo(
          selectedTurnaroundToView
        );
        // Cleanup afterwards
        // setSelectedTurnaroundToView(null);
      }
      if (!isNullOrUndefined(channelInfo)) {
        const channelDetailsInfo = {
          ...channelInfo,
          channel: chatChannel,
        };
        setSelectedChannelInfo((prev) => {
          // Signatures constantly change, so once we have one keep it
          // This will prevent images from getting reloaded constantly
          if (prev?.channelId !== chatChannel.channelId) {
            setMediaSignatures(chatChannel.mediaSignatures);
          }
          return channelDetailsInfo;
        });
      }
      // Chat should be ready
      setIsInitializing((prev) => {
        if (prev) {
          // The initializing state has changed
          chatMessagesRef.current?.classList.add("temp-hidden");
          setTimeout(() => {
            scrollMessages(true);
            chatMessagesRef.current?.classList.remove("temp-hidden");
          }, 250);
        }
        return false;
      });
    } else if (
      !isNullOrUndefined(selectedChannelId) &&
      isNullOrUndefined(selectedTurnaroundToView)
    ) {
      setSelectedChannelInfo(null);
      setIsInitializing(true);
    }
  }, [
    chatChannel,
    currentUser,
    users,
    turnaroundsSummary,
    positions,
    channelInfoList,
    selectedChannelId,
    scrollMessages,
    selectedTurnaroundToView,
  ]);

  // Select the channel that was just created
  useEffect(() => {
    if (isNullOrUndefined(createChatChannel)) return () => {};
    if (isEmptyList(chatSummary?.channels)) return () => {};
    const channelId = createChatChannel?.channelId;
    const channelToSelect = !isNullOrUndefined(channelId)
      ? getByFieldValue(chatSummary.channels, "channelId", channelId)
      : null;
    if (!isNullOrUndefined(channelToSelect)) {
      dispatch({
        type: "setSelectedChannelId",
        value: channelId,
      });
    }
    if (channelId === selectedChannelId) {
      chatDispatch({
        type: "setCreateChatChannel",
        value: null,
      });
    }
  }, [
    dispatch,
    chatDispatch,
    createChatChannel,
    chatSummary,
    selectedChannelId,
  ]);

  // Scroll the side panel to the selected channel
  useEffect(() => {
    if (scrollToChannelRequested) {
      const targetEl = document.querySelector(".chat-side-panel-item.selected");
      const containerEl = document.querySelector(".chat-side-panel-channels");
      if (!isNullOrUndefined(targetEl) && !isNullOrUndefined(containerEl)) {
        scrollElementIntoView(containerEl, targetEl);
      }
      setScrollToChannelRequested(false);
    }
  }, [scrollToChannelRequested]);

  const isSendDisabled = isPostingMessage || isBlank(messageText);
  const selectedChannelUnreadMessages = !isNullOrUndefined(selectedChannelInfo)
    ? selectedChannelInfo?.hasUnreadMessages
    : false;

  function handleMessagesScroll(e) {
    if (isNullOrUndefined(selectedChannelId)) return;
    const chatBody = e.target;
    if (
      Math.round(chatBody.clientHeight + chatBody.scrollTop) ===
      chatBody.scrollHeight
    ) {
      // Reached end of the chat
      setChatChannelReadRequested(true);
    }
  }

  function handleCreate() {
    setChannelEditorMode("create");
    setChannelToEdit({
      members: [],
      name: "",
    });
  }

  function handleEditGroup() {
    setChannelEditorMode("edit");
    setChannelEditorType(CHAT_CATEGORY_TYPES.GROUP);
    const members = deepCopy(chatChannel.members);
    const originalMembers = deepCopy(chatChannel.members);

    setChannelToEdit({
      leave: false,
      members: members,
      name: chatChannel.name,
      originalMembers: originalMembers,
    });
  }

  async function handleSave() {
    if (channelEditorMode === "create") {
      if (channelToEdit.members.length === 1) {
        // Check if DM already exists
        const memberUuid = channelToEdit.members[0];
        let dmChannel = null;
        for (
          let i = 0;
          i < chatSummary.channels.length && isNullOrUndefined(dmChannel);
          i++
        ) {
          const channelToCheck = chatSummary.channels[i];
          if (memberUuid === channelToCheck.otherUserUuid) {
            dmChannel = channelToCheck;
          }
        }
        if (!isNullOrUndefined(dmChannel)) {
          // Found the channel, set to selected
          dispatch({
            type: "setSelectedChannelId",
            value: dmChannel.channelId,
          });
        } else {
          // DM can not have names
          delete channelToEdit["name"];
          // Create the DM channel
          await postChatChannel(chatDispatch, channelToEdit);
          // Refresh the channel list
          await getChatSummary(chatDispatch);
        }
      } else if (channelToEdit.members.length > 1) {
        // Create the group channel
        await postChatChannel(chatDispatch, channelToEdit);
        // Refresh the channel list
        await getChatSummary(chatDispatch);
      }
      logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.CHAT_CREATE);
    } else if (channelEditorMode === "edit") {
      // Full editing is disabled for now, only allow adding members
      // await putCommsChannel(commsDispatch, selectedChannelId, channelToEdit);
      const membersToAdd = getArrayDifference(
        channelToEdit.members,
        channelToEdit.originalMembers
      );
      if (!isEmptyList(membersToAdd)) {
        await patchChatChannelMembers(
          chatDispatch,
          selectedChannelId,
          membersToAdd
        );
      }
      await getChatChannelDetails(chatDispatch, selectedChannelId);
      logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.CHAT_EDIT);
    }
    await getChatSummary(chatDispatch);
    setChannelEditorMode(null);
    setChannelToEdit(null);
  }

  function handleCancelChannel() {
    setChannelEditorMode(null);
    setChannelToEdit(null);
  }

  async function handleChangeChannel(updatedChannel) {
    setChannelToEdit(updatedChannel);
  }

  function handleUploadFile() {
    setIsUploading(true);
    getChatAttachmentUpload(chatDispatch, selectedChannelId);
    setTimeout(() => {
      scrollMessages();
    }, 250);
  }

  // Handle uploading image attachments
  useEffect(() => {
    if (!isNullOrUndefined(chatAttachmentUpload)) {
      if (!isBlank(chatAttachmentUpload.uploadUrl)) {
        function resetAll() {
          setMessageText("");
          setIsPostingMessage(false);
          setIsUploading(false);
          chatInputRef?.current?.focus();
          scrollMessages();
        }
        const file = chatFileInputRef.current?.files[0];
        if (isNullOrUndefined(file)) {
          resetAll();
        } else {
          const reader = new FileReader();
          const attachmentUuid = chatAttachmentUpload.uuid;
          const uploadUrl = chatAttachmentUpload.uploadUrl;

          const fileType = file.type;
          const imageForUpload = new Image();
          async function fileOnloadHandler() {
            var arrayBuffer = reader.result;
            var bytes = new Uint8Array(arrayBuffer);

            await uploadChatAttachment(
              chatDispatch,
              uploadUrl,
              bytes,
              fileType
            );
            await postChatChannelMessage(chatDispatch, selectedChannelId, {
              attachment: {
                type: 2,
                uuid: attachmentUuid,
                width: imageForUpload.width,
                height: imageForUpload.height,
                // aspectRatio: imageForUpload.width / imageForUpload.height,
              },
            });
            scrollMessages();
            setIsUploading(false);
            setChatChannelReadRequested(true);
            await getChatChannelDetails(chatDispatch, selectedChannelId);
            await getChatSummary(chatDispatch);
            resetAll();
          }
          reader.onload = fileOnloadHandler;
          async function imageOnloadHandler() {
            reader.readAsArrayBuffer(file);
          }
          imageForUpload.onload = imageOnloadHandler;
          imageForUpload.src = URL.createObjectURL(file);
        }
      }

      chatDispatch({
        type: "setChatAttachmentUpload",
        value: null,
      });
      logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.CHAT_UPLOAD_ATTACHMENT);
    }
  }, [
    dispatch,
    chatDispatch,
    chatAttachmentUpload,
    selectedChannelId,
    scrollMessages,
  ]);

  const hasChatMessages =
    !isNullOrUndefined(selectedChannelInfo?.channel?.messages) &&
    selectedChannelInfo.channel.messages.length > 0;

  const isChatDisabled = currentUser?.isInternal;
  const isProcessing = isUploading;
  const showChatEmptyState =
    !isChatDisabled &&
    !isInitializing &&
    !isProcessing &&
    (isEmptyList(channelInfoList) ||
      (!hasChatMessages && !isNullOrUndefined(selectedChannelId)));

  const lastMessage = !isEmptyList(selectedChannelInfo?.channel?.messages)
    ? selectedChannelInfo.channel.messages[
        selectedChannelInfo.channel.messages.length - 1
      ]
    : null;
  const isLastMessageFromCurrentUser = !isNullOrUndefined(lastMessage)
    ? lastMessage.sentBy === currentUser.uuid
    : false;
  const showUnreadMessagesBanner =
    selectedChannelUnreadMessages && !isLastMessageFromCurrentUser;

  const isCompactMode = mode === ChatModuleMode.COMPACT;

  // Show link to map if:
  // 1) turnaround channel is selected
  // 2) turnaround in scope
  // 3) turnaround has position
  const turnaroundInScope =
    !isEmptyList(turnaroundsSummary) &&
    !isNullOrUndefined(selectedTurnaroundToView)
      ? getByFieldValue(
          turnaroundsSummary,
          "uuid",
          selectedTurnaroundToView.uuid
        )
      : null;

  const showTurnaroundChat =
    !isNullOrUndefined(selectedChannelInfo) &&
    selectedChannelInfo.isTurnaround &&
    !isNullOrUndefined(selectedTurnaroundToView) &&
    !isNullOrUndefined(turnaroundInScope);

  const turnaroundMapPosition =
    showTurnaroundChat && !isEmptyList(positions?.aircrafts)
      ? getByFieldValue(
          positions.aircrafts,
          "registration",
          selectedTurnaroundToView.registration
        )
      : false;

  return (
    <div className={`chat${isCompactMode ? " compact-mode" : ""}`}>
      <div className="chat-module">
        {!isCompactMode && (
          <div className="chat-side-panel">
            <div className="chat-side-panel-content">
              <div className="chat-side-panel-item">
                <div className="chat-category"></div>
                <div>
                  <div>
                    <button
                      disabled={isChatDisabled}
                      className="secondary"
                      onClick={() => {
                        handleCreate();
                      }}
                    >
                      {t("create_new")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="chat-side-panel-channels">
                {channelInfoList &&
                  channelInfoList.map((channelInfo) => (
                    <ChatChannel
                      key={channelInfo.channelId}
                      channelInfo={channelInfo}
                      onSelect={() => {
                        resetEditor();
                        setIsInitializing(true);
                        dispatch({
                          type: "setSelectedChannelId",
                          value: channelInfo.channelId,
                        });
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.CHAT_VIEW_CHANNEL
                        );
                      }}
                      timezone={timezone}
                    />
                  ))}
                {!isEmptyList(otherTurnaroundChannelList) && (
                  <div
                    className={`chat-side-panel-heading${
                      isOtherTurnaroundChannelsOpen ? " opened" : ""
                    }`}
                    onClick={() => {
                      setIsOtherTurnaroundChannelsOpen((prev) => !prev);
                    }}
                  >
                    <div>
                      <div>{`${t("other_turnarounds")} (${
                        otherTurnaroundChannelList.length
                      })`}</div>
                      <div className="chat-side-panel-heading-toggle">
                        <Caret />
                      </div>
                    </div>
                  </div>
                )}
                {isOtherTurnaroundChannelsOpen &&
                  !isEmptyList(otherTurnaroundChannelList) &&
                  otherTurnaroundChannelList.map((channelInfo) => (
                    <ChatChannel
                      key={channelInfo.channelId}
                      channelInfo={channelInfo}
                      onSelect={() => {
                        resetEditor();
                        setIsInitializing(true);
                        dispatch({
                          type: "setSelectedChannelId",
                          value: channelInfo.channelId,
                        });
                        setSelectedTurnaroundToView(channelInfo.turnaroundInfo);
                        // Refresh the list since selecting effectively joins
                        getChatSummary(chatDispatch);
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.CHAT_VIEW_CHANNEL
                        );
                      }}
                      timezone={timezone}
                    />
                  ))}
                <div></div>
              </div>
            </div>
          </div>
        )}
        {isNullOrUndefined(channelEditorMode) && (
          <div className="chat-main-panel">
            {showUnreadMessagesBanner && (
              <div className="chat-unread-overlay">
                <div>
                  <button onClick={scrollMessages} className="rounded-white">
                    {t("unread_messages")}
                  </button>
                </div>
              </div>
            )}

            <div className="chat-header">
              <div>
                <div>
                  {!isInitializing && !isNullOrUndefined(selectedChannelId) && (
                    <ChannelInfo
                      channelInfo={selectedChannelInfo}
                      isSelected={true}
                      isHeader={true}
                      timezone={timezone}
                    />
                  )}
                </div>
                <div className="actions">
                  {!isCompactMode &&
                    !isNullOrUndefined(selectedChannelInfo) &&
                    selectedChannelInfo.isGroup && (
                      <button className="secondary" onClick={handleEditGroup}>
                        {t("edit")}
                      </button>
                    )}
                  {!isCompactMode &&
                    showTurnaroundChat &&
                    !isNullOrUndefined(turnaroundMapPosition) && (
                      <button
                        className="secondary"
                        onClick={() => {
                          setTimeout(() => {
                            dispatch({
                              type: "setShowOnMap",
                              value: {
                                markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                                item: selectedTurnaroundToView,
                              },
                            });
                          }, 250);
                          navigate("/map");
                          logAnalyticsEvent(
                            dispatch,
                            ANALYTICS_EVENTS.CHAT_TURNAROUND_SHOW_ON_MAP
                          );
                        }}
                      >
                        {t("show_in_map")}
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className="chat-body" onScroll={handleMessagesScroll}>
              {isInitializing && <LoadingIndicator />}
              {isChatDisabled && (
                <div className="chat-messages-empty">
                  <div>{t("Chat is disabled for this user")}</div>
                </div>
              )}
              {showChatEmptyState && (
                <div className="chat-messages-empty">
                  <div>{t("nothing_here_yet")}</div>
                </div>
              )}
              <div
                className="chat-messages"
                data-channel-id={selectedChannelInfo?.channelId}
                ref={chatMessagesRef}
              >
                {hasChatMessages &&
                  selectedChannelInfo?.channel?.messages.map((message, idx) => (
                    <ChatMessage
                      key={message.id}
                      message={message}
                      mediaSignatures={mediaSignatures}
                      onImageLoaded={() => {
                        scrollMessages(true);
                      }}
                      index={idx}
                      timezone={timezone}
                    />
                  ))}
                {isUploading && (
                  <div className="chat-message outgoing">
                    <div className="chat-message-text">
                      <div>
                        <ChatBubble
                          isLoading={true}
                          mediaSignatures={mediaSignatures}
                          timezone={timezone}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="chat-messages-buffer"></div>
              </div>
            </div>
            <div className="chat-footer">
              <div>
                {!isNullOrUndefined(selectedChannelId) && (
                  <>
                    <div className="chat-message-input">
                      <div>
                        <input
                          ref={chatInputRef}
                          type="text"
                          placeholder={t("type_your_message_here")}
                          value={messageText}
                          onChange={(e) => {
                            setMessageText(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter" && !isSendDisabled) {
                              handleSend();
                            }
                          }}
                          data-testid="chat-message-input"
                        />
                      </div>
                      <div
                        className="chat-message-input-attachment"
                        onClick={() => {
                          chatFileInputRef.current.click();
                        }}
                      >
                        <Attachment />
                        <input
                          className="not-visible"
                          type="file"
                          ref={chatFileInputRef}
                          accept=".png,.jpeg,.jpg"
                          onChange={handleUploadFile}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={handleSend}
                        disabled={isSendDisabled}
                        data-testid="chat-message-send-btn"
                      >
                        {t("send")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {!isNullOrUndefined(channelEditorMode) && (
          <ChatChannelEditor
            channelToEdit={channelToEdit}
            onChange={handleChangeChannel}
            onCancel={handleCancelChannel}
            onSave={handleSave}
            channelEditorMode={channelEditorMode}
            channelEditorType={channelEditorType}
            showEditName={false}
          />
        )}
      </div>
      {!isNullOrUndefined(selectedAttachment) && (
        <ChatAttachmentModal
          attachment={selectedAttachment}
          mediaSignatures={mediaSignatures}
        />
      )}
    </div>
  );
}

export default ChatModule;
