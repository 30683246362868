import "./styles.css";
import { ReactComponent as LogoFullSvg } from "../../assets/moonware_logo.svg";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import { ReactComponent as CaretDownSvg } from "../../assets/caret-down.svg";
import { ReactComponent as Resources } from "../../assets/resources.svg";
import { ReactComponent as Performance } from "../../assets/performance.svg";
import { ReactComponent as Dashboard } from "../../assets/dashboard.svg";
import { ReactComponent as Flights } from "../../assets/flights.svg";
import { ReactComponent as Map } from "../../assets/map.svg";
import { ReactComponent as Chat } from "../../assets/chat.svg";
import { ReactComponent as Turnarounds } from "../../assets/turnarounds.svg";
import { ReactComponent as LiveRoster } from "../../assets/overview.svg";
import { ReactComponent as CrewShifts } from "../../assets/crew-roster.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { REACT_APP_ENV_NAME } from "../../api";
import { useTranslation } from "react-i18next";
import ChatIndicator from "../ChatIndicator";
import { ANALYTICS_EVENTS } from "../../constants";
import { logAnalyticsEvent } from "../../utils";
import { useMainDispatchContext } from "../../MainContext";
import { Fade, Tooltip } from "@mui/material";
import { tooltipLightStyles } from "../../styleUtils";

function MainMenu(props) {
  const { t } = useTranslation();
  const {
    showAdminUsers,
    showAdminGses,
    showPerformance,
    showUtilization,
    showLeaderboard,
    showTurnaroundTemplates,
    showCrewShifts,
    showChat,
    showLiveRoster,
    showFlights,
    showTurnarounds,
    isCompactMode,
  } = props;

  const dispatch = useMainDispatchContext();
  const showAdminSection =
    showAdminUsers || showAdminGses || showTurnaroundTemplates;
  const showPerformanceSection =
    showPerformance || showUtilization || showLeaderboard;
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isPerformanceOpen, setIsPerformanceOpen] = useState(false);

  return (
    <div
      className="main-menu"
      onMouseOver={() => {
        document.querySelector(".menu-handle").classList.add("hovered");
      }}
      onMouseOut={() => {
        document.querySelector(".menu-handle").classList.remove("hovered");
      }}
    >
      <div className="menu-header">
        <NavLink
          to="/dashboard"
          className="main-logo"
          onClick={() => {
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_DASHBOARD);
          }}
        >
          {isCompactMode ? <LogoSvg /> : <LogoFullSvg />}
        </NavLink>
      </div>
      <div className="menu-container">
        <div className="menu-list">
          <NavLink
            to="/dashboard"
            className="menu-item"
            onClick={() => {
              logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_DASHBOARD);
            }}
          >
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body">
              <div className="menu-item-icon">
                <Tooltip
                  title={
                    isCompactMode && (
                      <div className="tooltip-content">{t("dashboard")}</div>
                    )
                  }
                  placement="right"
                  arrow
                  componentsProps={tooltipLightStyles}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 500 }}
                >
                  <Dashboard />
                </Tooltip>
              </div>
              {!isCompactMode && t("dashboard")}
            </div>
          </NavLink>
          {showLiveRoster && (
            <NavLink
              to="/live-roster"
              className="menu-item"
              onClick={() => {
                logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_LIVE_ROSTER);
              }}
            >
              <div className="menu-item-indicator"></div>
              <div className="menu-item-body">
                <div className="menu-item-icon">
                  <Tooltip
                    title={
                      isCompactMode && (
                        <div className="tooltip-content">
                          {t("live_roster")}
                        </div>
                      )
                    }
                    placement="right"
                    arrow
                    componentsProps={tooltipLightStyles}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                  >
                    <LiveRoster />
                  </Tooltip>
                </div>
                {!isCompactMode && t("live_roster")}
              </div>
            </NavLink>
          )}
          <NavLink
            to="/map"
            className="menu-item"
            onClick={() => {
              logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_MAP);
            }}
          >
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body">
              <div className="menu-item-icon">
                <Tooltip
                  title={
                    isCompactMode && (
                      <div className="tooltip-content">{t("map_non_cap")}</div>
                    )
                  }
                  placement="right"
                  arrow
                  componentsProps={tooltipLightStyles}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 500 }}
                >
                  <Map />
                </Tooltip>
              </div>
              {!isCompactMode && t("map_non_cap")}
            </div>
          </NavLink>
          {showChat && (
            <NavLink
              to="/chat"
              className="menu-item"
              data-testid="menu-link-chat"
              onClick={() => {
                logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_CHAT);
              }}
            >
              <div className="menu-item-indicator"></div>
              <div className="menu-item-body indicators">
                <div className="menu-item-icon">
                  <Tooltip
                    title={
                      isCompactMode && (
                        <div className="tooltip-content">{t("chat")}</div>
                      )
                    }
                    placement="right"
                    arrow
                    componentsProps={tooltipLightStyles}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                  >
                    <Chat />
                  </Tooltip>
                </div>
                {!isCompactMode && <span>{t("chat")}</span>}
                <div>
                  <ChatIndicator />
                </div>
              </div>
            </NavLink>
          )}
          {showFlights && (
            <NavLink
              to="/flights"
              className="menu-item"
              onClick={() => {
                logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_FLIGHTS);
              }}
            >
              <div className="menu-item-indicator"></div>
              <div className="menu-item-body">
                <div className="menu-item-icon">
                  <Tooltip
                    title={
                      isCompactMode && (
                        <div className="tooltip-content">{t("flights")}</div>
                      )
                    }
                    placement="right"
                    arrow
                    componentsProps={tooltipLightStyles}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                  >
                    <Flights />
                  </Tooltip>
                </div>
                {!isCompactMode && t("flights")}
              </div>
            </NavLink>
          )}
          {showTurnarounds && (
            <NavLink
              to="/turnarounds"
              className="menu-item"
              onClick={() => {
                logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_TURNAROUNDS);
              }}
            >
              <div className="menu-item-indicator"></div>
              <div className="menu-item-body">
                <div className="menu-item-icon">
                  <Tooltip
                    title={
                      isCompactMode && (
                        <div className="tooltip-content">
                          {t("turnarounds")}
                        </div>
                      )
                    }
                    placement="right"
                    arrow
                    componentsProps={tooltipLightStyles}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                  >
                    <Turnarounds />
                  </Tooltip>
                </div>
                {!isCompactMode && t("turnarounds")}
              </div>
            </NavLink>
          )}
          {showCrewShifts && (
            <NavLink
              to="/crew-shifts"
              className="menu-item"
              onClick={() => {
                logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_CREW_SHIFTS);
              }}
            >
              <div className="menu-item-indicator"></div>
              <div className="menu-item-body">
                <div className="menu-item-icon">
                  <Tooltip
                    title={
                      isCompactMode && (
                        <div className="tooltip-content">
                          {t("crew_shifts")}
                        </div>
                      )
                    }
                    placement="right"
                    arrow
                    componentsProps={tooltipLightStyles}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                  >
                    <CrewShifts />
                  </Tooltip>
                </div>
                {!isCompactMode && <span>{t("crew_shifts")}</span>}
              </div>
            </NavLink>
          )}

          {showAdminSection && (
            <>
              {!isCompactMode && (
                <div
                  className={`menu-item toggle ${isAdminOpen ? "open" : ""}`}
                  onClick={() => {
                    setIsAdminOpen(!isAdminOpen);
                  }}
                >
                  <div className="menu-item-indicator"></div>
                  <div className="menu-item-body">
                    <div className="menu-item-icon">
                      <Resources />
                    </div>
                    {t("resources")}
                  </div>
                  <div className="menu-item-toggle">
                    <CaretDownSvg />
                  </div>
                </div>
              )}
              {(isAdminOpen || isCompactMode) && (
                <>
                  {showAdminUsers && (
                    <NavLink
                      to="/users"
                      className="menu-item"
                      onClick={() => {
                        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_USERS);
                      }}
                    >
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon">
                          {isCompactMode && (
                            <Tooltip
                              title={
                                isCompactMode && (
                                  <div className="tooltip-content">
                                    {t("users")}
                                  </div>
                                )
                              }
                              placement="right"
                              arrow
                              componentsProps={tooltipLightStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                            >
                              <Resources />
                            </Tooltip>
                          )}
                        </div>
                        {!isCompactMode && t("users")}
                      </div>
                    </NavLink>
                  )}
                  {showAdminGses && (
                    <NavLink
                      to="/vehicles"
                      className="menu-item"
                      onClick={() => {
                        logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_GSE);
                      }}
                    >
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon">
                          {isCompactMode && (
                            <Tooltip
                              title={
                                isCompactMode && (
                                  <div className="tooltip-content">
                                    {t("vehicles")}
                                  </div>
                                )
                              }
                              placement="right"
                              arrow
                              componentsProps={tooltipLightStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                            >
                              <Resources />
                            </Tooltip>
                          )}
                        </div>
                        {!isCompactMode && t("vehicles")}
                      </div>
                    </NavLink>
                  )}
                  {showTurnaroundTemplates && (
                    <NavLink
                      to="/templates"
                      className="menu-item"
                      onClick={() => {
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.NAV_TURNAROUND_TEMPLATES
                        );
                      }}
                    >
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon">
                          {isCompactMode && (
                            <Tooltip
                              title={
                                isCompactMode && (
                                  <div className="tooltip-content">
                                    {t("turnaround_templates")}
                                  </div>
                                )
                              }
                              placement="right"
                              arrow
                              componentsProps={tooltipLightStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                            >
                              <Resources />
                            </Tooltip>
                          )}
                        </div>
                        {!isCompactMode && t("turnaround_templates")}
                      </div>
                    </NavLink>
                  )}
                </>
              )}
            </>
          )}
          {showPerformanceSection && (
            <>
              {!isCompactMode && (
                <div
                  className={`menu-item toggle ${
                    isPerformanceOpen ? "open" : ""
                  }`}
                  onClick={() => {
                    setIsPerformanceOpen(!isPerformanceOpen);
                  }}
                >
                  <div className="menu-item-indicator"></div>
                  <div className="menu-item-body">
                    <div className="menu-item-icon">
                      <Performance />
                    </div>
                    {t("performance")}
                  </div>
                  <div className="menu-item-toggle">
                    <CaretDownSvg />
                  </div>
                </div>
              )}
              {(isPerformanceOpen || isCompactMode) && (
                <>
                  {showPerformance && !isCompactMode && (
                    <NavLink
                      to="/performance"
                      className="menu-item"
                      onClick={() => {
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.NAV_PERFORMANCE
                        );
                      }}
                    >
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        <span>{t("on_time_performance")}</span>
                      </div>
                    </NavLink>
                  )}
                  {(showUtilization || isCompactMode) && (
                    <NavLink to="/utilization" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon">
                          {isCompactMode && (
                            <Tooltip
                              title={
                                isCompactMode && (
                                  <div className="tooltip-content">
                                    {t("vehicle_utilization")}
                                  </div>
                                )
                              }
                              placement="right"
                              arrow
                              componentsProps={tooltipLightStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                            >
                              <Performance />
                            </Tooltip>
                          )}
                        </div>
                        <span>
                          {!isCompactMode && t("vehicle_utilization")}
                        </span>
                      </div>
                    </NavLink>
                  )}
                  {(showLeaderboard || isCompactMode) && (
                    <NavLink
                      to="/leaderboard"
                      className="menu-item"
                      onClick={() => {
                        logAnalyticsEvent(
                          dispatch,
                          ANALYTICS_EVENTS.NAV_LEADERBOARD
                        );
                      }}
                    >
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon">
                          {isCompactMode && (
                            <Tooltip
                              title={
                                isCompactMode && (
                                  <div className="tooltip-content">
                                    {t("crew_leaderboard")}
                                  </div>
                                )
                              }
                              placement="right"
                              arrow
                              componentsProps={tooltipLightStyles}
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 500 }}
                            >
                              <Performance />
                            </Tooltip>
                          )}
                        </div>
                        <span>{!isCompactMode && t("crew_leaderboard")}</span>
                      </div>
                    </NavLink>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={`menu-footer ${REACT_APP_ENV_NAME}`}>
        <div className="app-status">{REACT_APP_ENV_NAME}</div>
      </div>
    </div>
  );
}
export default MainMenu;
