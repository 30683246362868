import { isEmptyList, isNullOrUndefined } from "../../utils";
import { t } from "i18next";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { useState } from "react";
import { useMainDispatchContext } from "../../MainContext";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";
import { getGseRecordsByGseType } from "../../turnaroundUtils";
import TurnaroundModuleGseSlot from "./TurnaroundModuleGseSlot";

function TurnaroundModuleGseRequirement(props) {
  const {
    turnaroundInfo,
    selectedOperation,
    requirement,
    gseTypes,
    isProfileEditable,
    isDisabled,
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useMainDispatchContext();

  const gseRecordsByGseType = getGseRecordsByGseType(
    selectedOperation?.gseRecords
  );
  const groundVehicleTypeUuid = requirement.certification.groundVehicleTypeUuid;
  const gseRecordsForRequirement =
    !isNullOrUndefined(gseRecordsByGseType) &&
    groundVehicleTypeUuid in gseRecordsByGseType
      ? gseRecordsByGseType[groundVehicleTypeUuid]
      : [];

  const slots = [];
  if (!isEmptyList(gseRecordsForRequirement)) {
    for (let i = 0; i < gseRecordsForRequirement.length; i++) {
      const gseRecord = gseRecordsForRequirement[i];
      slots.push({
        requirement: requirement,
        serviceRecord: gseRecord,
      });
    }
  }
  const remainingSlots = requirement.quantity - gseRecordsForRequirement.length;
  for (let i = 0; i < remainingSlots; i++) {
    slots.push({
      requirement: requirement,
      serviceRecord: null,
    });
  }

  async function handleUpdateGseQuantity(amount) {
    if (isProcessing) return;
    setIsProcessing(true);
    const patchRequest = {
      uuid: turnaroundInfo.originalProfile.uuid,
      requirements: [
        {
          uuid: requirement.uuid,
          quantity: requirement.quantity + amount,
        },
      ],
    };
    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    if (result) {
      await getTurnaroundDetails(dispatch, turnaroundInfo);
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: turnaroundInfo.combinedFlightName,
        }),
      });
    }
    setIsProcessing(false);
  }

  return (
    <div>
      <div className="operation-requirement">
        <div>
          <div>
            <div className="operation-requirement-info">
              <div>{requirement.certification.name}</div>
              <div>
                <div className="quantity-values">
                  <div className="quantity-value">
                    <div>{requirement.quantity}</div>
                    <div>{t("required")}</div>
                  </div>
                  <div className="quantity-value">
                    <div>{gseRecordsForRequirement?.length}</div>
                    <div>{t("arrived")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="operation-requirement-slots">
            <div>
              {!isEmptyList(slots) &&
                slots.map((slot, idx) => (
                  <TurnaroundModuleGseSlot
                    key={`${requirement.certification.uuid}-${idx}`}
                    serviceRecord={slot.serviceRecord}
                    requirement={slot.requirement}
                    gseTypes={gseTypes}
                    onRemove={() => {
                      handleUpdateGseQuantity(-1);
                    }}
                    isProfileEditable={isProfileEditable}
                    isDisabled={isDisabled}
                  />
                ))}
            </div>
            {isProfileEditable && (
              <div className="actions">
                <div>
                  <button
                    className="secondary"
                    onClick={() => {
                      handleUpdateGseQuantity(1);
                    }}
                    disabled={isDisabled}
                  >
                    <Plus /> {t("add_gse")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundModuleGseRequirement;
