import { useTranslation } from "react-i18next";
import "./styles.css";
import CrewShiftsRow from "./CrewShiftsRow";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useState } from "react";

import { isEmptyList, isNullOrUndefined } from "../../utils";
import { DEFAULT_TIMEZONE } from "../../constants";
import CrewShiftsSlot from "./CrewShiftsSlot";
import { useCrewShiftsDispatchContext } from "../../CrewShiftsContext";
import {
  createCrewShift,
  deleteCrewShift,
  updateCrewShift,
} from "../../crewShiftsApi";

function CrewShiftsModule(props) {
  const { t } = useTranslation();
  const {
    crewShiftsToRender,
    onCrewShiftUpdate,
    departments,
    selectedDate,
    isActive,
    onSetActive,
  } = props;
  const mainContext = useMainContext();
  const dispatch = useMainDispatchContext();
  const crewShiftsDispatch = useCrewShiftsDispatchContext();

  const { users, currentUser, turnaroundsSummary, positions } = mainContext;
  const airportTimezone = !isNullOrUndefined(currentUser)
    ? currentUser.airport.timezone
    : DEFAULT_TIMEZONE;

  const [selectedCrewShiftUuid, setSelectedCrewShiftUuid] = useState(null);

  // Delete crew shift
  async function handleDeleteCrewShift(crewShiftToRemove) {
    if (isNullOrUndefined(crewShiftToRemove)) return;
    const result = await deleteCrewShift(crewShiftsDispatch, crewShiftToRemove);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("crew_shift_removed_web", {
          value: crewShiftToRemove.userInfo.fullName,
        }),
      });
    } else {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("crew_shift_removed_error_web", {
          value: crewShiftToRemove.userInfo.fullName,
        }),
      });
    }
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
    onCrewShiftUpdate();
    setSelectedCrewShiftUuid(null);
  }

  // Create crew shift
  async function handleCreateCrewShift(crewShiftToAdd) {
    if (isNullOrUndefined(crewShiftToAdd)) return;
    const result = await createCrewShift(crewShiftsDispatch, crewShiftToAdd);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("crew_shift_saved_web", {
          value: crewShiftToAdd.userInfo.fullName,
        }),
      });
    } else {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("crew_shift_saved_error_web", {
          value: crewShiftToAdd.userInfo.fullName,
        }),
      });
    }
    onCrewShiftUpdate();
    setSelectedCrewShiftUuid(null);
  }

  // Update crew shift
  async function handleUpdateCrewShift(crewShiftToUpdate) {
    if (isNullOrUndefined(crewShiftToUpdate)) return;
    const result = await updateCrewShift(crewShiftsDispatch, crewShiftToUpdate);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("crew_shift_saved_web", {
          value: crewShiftToUpdate.userInfo.fullName,
        }),
      });
    } else {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("crew_shift_saved_error_web", {
          value: crewShiftToUpdate.userInfo.fullName,
        }),
      });
    }
    onCrewShiftUpdate();
    setSelectedCrewShiftUuid(null);
  }
  return (
    <div className="crew-shifts-module">
      <div className="crew-shifts-module-header">
        <div>{t("name")}</div>
        <div>{t("shifts")}</div>
        <div>{t("department")}</div>
        <div className="actions"></div>
      </div>
      <div className="crew-shifts-module-rows">
        {isEmptyList(crewShiftsToRender) && (
          <div className="empty-state de-emphasize">
            {t("crew_roster_empty")}
          </div>
        )}
        <CrewShiftsSlot
          users={users}
          departments={departments}
          turnarounds={turnaroundsSummary}
          positions={positions}
          selectedDate={selectedDate}
          airportTimezone={airportTimezone}
          onAddCrewShift={(value) => {
            // To "activate" should set this to a fake userUuid of "_new"
            setSelectedCrewShiftUuid(value);
          }}
          isEditable={
            isNullOrUndefined(selectedCrewShiftUuid) ||
            selectedCrewShiftUuid === "_new"
          }
          onSaveCrewShift={(crewShiftToAdd) => {
            handleCreateCrewShift(crewShiftToAdd);
          }}
          isActive={isActive}
          onSetActive={onSetActive}
        />
        {!isEmptyList(crewShiftsToRender) &&
          crewShiftsToRender.map((crewShift) => (
            <CrewShiftsRow
              key={crewShift.uuid}
              crewShift={crewShift}
              departments={departments}
              airportTimezone={airportTimezone}
              isEditable={
                isNullOrUndefined(selectedCrewShiftUuid) ||
                selectedCrewShiftUuid === crewShift.uuid
              }
              onSelectCrewShift={(crewShiftUuid) => {
                setSelectedCrewShiftUuid(crewShiftUuid);
              }}
              onSaveCrewShift={(crewShiftToUpdate) => {
                handleUpdateCrewShift(crewShiftToUpdate);
              }}
              onRemoveCrewShift={(crewShiftToRemove) => {
                handleDeleteCrewShift(crewShiftToRemove);
              }}
            />
          ))}
      </div>
    </div>
  );
}

export default CrewShiftsModule;
