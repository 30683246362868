import "./styles.css";
import { ReactComponent as CaretDownSvg } from "../../assets/caret-down.svg";

function MenuHandle(props) {
  const { onToggleCompactMode } = props;
  return (
    <div
      className="menu-handle"
      onClick={() => {
        onToggleCompactMode();
      }}
    >
      <div>
        <CaretDownSvg />
      </div>
    </div>
  );
}

export default MenuHandle;
