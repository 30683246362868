// Please sync with https://github.com/Moonware-Inc/halo/blob/develop/src/sdk/common/permissions/resourceactions/resourceactions.go

import { isNullOrUndefined } from "./utils";

export const ResourceActions = {
  ViewEquipment: 1,
  // Users (1000-1999)
  ViewUsers: 1000,

  // Turnarounds (2000-2999)
  ViewTurnarounds: 2000,
  EditStandAssignment: 2001,

  // Turnaround Profiles (3000-3999)
  ViewTurnProfile: 3000,
  EditTurnProfile: 3001,
  ViewTurnTemplate: 3002,
  EditTurnTemplate: 3003,

  // Locations (4000-4999)
  ReportLocation: 4000,
  ReportBackgroundLocation: 4001,

  // Admin (5000-5999)
  ViewWebApp: 5000,
  EditAdminUsers: 5001,
  EditAdminGse: 5002,
  ViewLiveRoster: 5003,
  ViewCrewShifts: 5004,
  EditCrewShifts: 5005,

  // Performance (6000-6999)
  ViewOnTimePerformance: 6000,
  ViewGseUtilization: 6001,
  ViewUserLeaderboard: 6002,

  // Manual Flight Changes (7000-7999)
  ImportFlights: 7000,
  CancelFlight: 7001,
  PatchFlight: 7002,
};

export function userHasResourceAction(user, resourceActionId, airlineUuid) {
  if (isNullOrUndefined(user) || isNullOrUndefined(resourceActionId))
    return false;
  if (isNullOrUndefined(user.permissions) || user.permissions.length < 1)
    return false;
  const companyUuid = user?.company.uuid;
  const airportUuid = user?.airport.uuid;

  const resourceAction = user.permissions.find((p) => {
    return (
      p.resourceActionId === resourceActionId &&
      (isNullOrUndefined(p.companyUuid) || p.companyUuid === companyUuid) &&
      (isNullOrUndefined(p.airportUuid) || p.airportUuid === airportUuid) &&
      (isNullOrUndefined(p.airlineUuid) || p.airlineUuid === airlineUuid)
    );
  });
  return !isNullOrUndefined(resourceAction);
}

export function userHasAnyResourceAction(user, resourceActionId) {
  if (isNullOrUndefined(user) || isNullOrUndefined(resourceActionId))
    return false;
  if (isNullOrUndefined(user.permissions) || user.permissions.length < 1)
    return false;

  const resourceAction = user.permissions.find((p) => {
    return p.resourceActionId === resourceActionId;
  });
  return !isNullOrUndefined(resourceAction);
}
