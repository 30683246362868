import { useTranslation } from "react-i18next";
import { formatTime, isEmptyList, isNullOrUndefined } from "../../utils";
import { EMPTY_TIME } from "../../constants";
import { useMemo } from "react";
import { Fade, Tooltip } from "@mui/material";
import { getUserInfo } from "../../userUtils";
import { tooltipLightStyles } from "../../styleUtils";

function TurnaroundModuleStats(props) {
  const { t } = useTranslation();
  const { selectedOperation, timezone } = props;

  const stats = useMemo(() => {
    const statsValues = {
      allocatedCrew: 0,
      requiredCrew: 0,
      allocatedGse: 0,
      requiredGse: 0,
    };
    const requirements = selectedOperation?.turnaroundRequirements;
    if (!isEmptyList(requirements)) {
      for (let i = 0; i < requirements.length; i++) {
        const requirement = requirements[i];
        const qty = requirement.quantity;
        if (
          !isNullOrUndefined(requirement?.certification?.groundVehicleTypeUuid)
        ) {
          // Quantity applies to both GSE and Crew
          statsValues.requiredGse = statsValues.requiredGse + qty;
          statsValues.requiredCrew = statsValues.requiredCrew + qty;
        } else {
          statsValues.requiredCrew = statsValues.requiredCrew + qty;
        }
        // For Crew, use crewAssignments as the allocated value
        if (!isNullOrUndefined(requirement.crewAssignments)) {
          statsValues.allocatedCrew =
            statsValues.allocatedCrew + requirement.crewAssignments.length;
        }
      }
      // For GSE, use gseRecords as the allocated value
      if (!isEmptyList(selectedOperation?.gseRecords)) {
        statsValues.allocatedGse =
          statsValues.allocatedGse + selectedOperation.gseRecords.length;
      }
    }

    return statsValues;
  }, [selectedOperation]);

  const completedByUserInfo = !isNullOrUndefined(
    selectedOperation?.markedCompleteByUser
  )
    ? getUserInfo(selectedOperation?.markedCompleteByUser)
    : null;

  const markedCompleteAt = selectedOperation?.markedCompleteAt;
  const startedAt = selectedOperation?.startedAt;
  const startedByUserInfo = !isNullOrUndefined(selectedOperation?.startedByUser)
    ? getUserInfo(selectedOperation?.startedByUser)
    : null;
  const expectedStartTime = selectedOperation.expectedStartTime;
  const expectedEndTime = selectedOperation.expectedEndTime;

  return (
    <div className="stats">
      <div className="stat">
        <div>
          <div>
            <span>{t("required_crew")}</span>
          </div>
          <div>{`${stats?.allocatedCrew}/${stats?.requiredCrew}`}</div>
        </div>
      </div>
      <div className="stat">
        <div>
          <div>
            <span>{t("required_gse")}</span>
          </div>
          <div>{`${stats?.allocatedGse}/${stats?.requiredGse}`}</div>
        </div>
      </div>
      <div className="divider">
        <div></div>
      </div>

      <div className="stat">
        <Tooltip
          title={
            !isNullOrUndefined(startedByUserInfo) ? (
              <div className="tooltip-content">
                <div>
                  {t("started_at_web", {
                    value: formatTime(startedAt, timezone),
                    name: startedByUserInfo.fullName,
                  })}
                </div>
              </div>
            ) : null
          }
          placement="top"
          arrow
          componentsProps={tooltipLightStyles}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <div>
            <div>
              <span>
                {!isNullOrUndefined(startedAt)
                  ? t("initiated")
                  : t("expected_start")}
              </span>
            </div>
            <div>
              {!isNullOrUndefined(startedAt)
                ? formatTime(startedAt, timezone)
                : !isNullOrUndefined(expectedStartTime)
                ? formatTime(expectedStartTime, timezone)
                : EMPTY_TIME}
            </div>
          </div>
        </Tooltip>
      </div>
      <div className="stat">
        <Tooltip
          title={
            !isNullOrUndefined(completedByUserInfo) ? (
              <div className="tooltip-content">
                <div>
                  {t("completed_at_web", {
                    value: formatTime(markedCompleteAt, timezone),
                    name: completedByUserInfo.fullName,
                  })}
                </div>
              </div>
            ) : null
          }
          placement="top"
          arrow
          componentsProps={tooltipLightStyles}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
        >
          <div>
            <div>
              <span>
                {!isNullOrUndefined(markedCompleteAt)
                  ? t("completed_non_cap")
                  : t("expected_complete")}
              </span>
            </div>
            <div>
              {!isNullOrUndefined(markedCompleteAt)
                ? formatTime(markedCompleteAt, timezone)
                : !isNullOrUndefined(expectedEndTime)
                ? formatTime(expectedEndTime, timezone)
                : EMPTY_TIME}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default TurnaroundModuleStats;
