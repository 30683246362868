import { getByFieldValue, isNullOrUndefined } from "../../utils";
import { t } from "i18next";
import GseInfo from "./GseInfo";

function TurnaroundModuleGseSlot(props) {
  const {
    requirement,
    serviceRecord,
    onRemove,
    timezone,
    gseTypes,
    isProfileEditable,
    isDisabled,
  } = props;

  const gseTypeForRequirement = !isNullOrUndefined(gseTypes)
    ? getByFieldValue(
        gseTypes,
        "uuid",
        requirement.certification.groundVehicleTypeUuid
      )
    : null;

  return (
    <div
      className={`turnaround-module-slot${
        isNullOrUndefined(serviceRecord) ? " empty" : ""
      }`}
    >
      <div>
        {!isNullOrUndefined(serviceRecord?.groundVehicle) && (
          <>
            <div>
              <GseInfo serviceRecord={serviceRecord} timezone={timezone} />
            </div>
            <div className="actions">
              <div></div>
              <div></div>
            </div>
          </>
        )}
        {isNullOrUndefined(serviceRecord) && (
          <>
            <div>
              <GseInfo
                placeholder={{
                  name: t("allocated"),
                  type: gseTypeForRequirement?.name,
                }}
                timezone={timezone}
              />
            </div>
            <div className="actions">
              <div></div>
              <div>
                {isProfileEditable && (
                  <button
                    className="secondary"
                    onClick={() => {
                      onRemove();
                    }}
                    disabled={isDisabled}
                  >
                    {t("remove")}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TurnaroundModuleGseSlot;
