import { useMainDispatchContext } from "../../MainContext";
import { logAnalyticsEvent } from "../../utils";
import "./styles.css";

function TabControl(props) {
  const { selectedTabIdx, tabNames, tabEventNames, clickHandler } = props;
  const dispatch = useMainDispatchContext();
  return (
    <div className="tab-control">
      {tabNames &&
        tabNames.map((tab, idx) => (
          <div
            className={`${selectedTabIdx === idx ? "selected" : ""}`}
            onClick={() => {
              clickHandler(idx);
              logAnalyticsEvent(dispatch, tabEventNames[idx]);
            }}
            key={tab}
          >
            <span>{tab}</span>
          </div>
        ))}
    </div>
  );
}

export default TabControl;
