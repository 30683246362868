import { useMainDispatchContext } from "../../MainContext";
import { isBlank, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import "./styles.css";
function SubTabs(props) {
  const { menuTitle, menuItems, selectedView, onClick } = props;
  const dispatch = useMainDispatchContext();
  return (
    <div className="sub-tabs">
      <div>
        {!isBlank(menuTitle) && (
          <div className="sub-tabs-label">
            <div>{menuTitle}:</div>
          </div>
        )}
        {menuItems &&
          menuItems.map((item) => (
            <div
              key={item.id}
              className={`sub-tabs-item${
                selectedView === item.id ? " selected" : ""
              }`}
              onClick={() => {
                onClick(item.id);
                if (!isNullOrUndefined(item.eventName)) {
                  logAnalyticsEvent(dispatch, item.eventName);
                }
              }}
            >
              <div>{item.name}</div>
            </div>
          ))}
        <div></div>
      </div>
    </div>
  );
}

export default SubTabs;
