import { isEmptyList } from "../../utils";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { useTranslation } from "react-i18next";

function TurnaroundLeads(props) {
  const { item, onClick, isReadonly } = props;
  const { t } = useTranslation();

  return (
    <div
      className={`turnaround-leads${!isReadonly ? " active" : ""}`}
      onClick={onClick}
    >
      <div>
        <div className="turnaround-leads-rows">
          {isEmptyList(item?.leads) && (
            <div className="turnaround-leads-row empty">{t("na")}</div>
          )}
          {!isEmptyList(item?.leads) &&
            item.leads.map((userInfo) => (
              <div className="turnaround-leads-row" key={userInfo.uuid}>
                {userInfo.fullName}
              </div>
            ))}
        </div>

        <div className="turnaround-leads-actions">
          <div>
            <div className="action-icon">
              <Pencil />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundLeads;
