import "./styles.css";
import { Fade, Menu, MenuItem, Tooltip } from "@mui/material";
import { isEmptyList, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import { useState } from "react";
import { logout } from "../../api";
import UserPic from "./UserPic";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ReactComponent as CaretDownSvg } from "../../assets/caret-down.svg";
import { ReactComponent as Logout } from "../../assets/logout.svg";
import { ReactComponent as Settings } from "../../assets/settings.svg";
import { ReactComponent as SwitchDeployment } from "../../assets/switch-deployment.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { tooltipSolidStyles } from "../../styleUtils";
import { ANALYTICS_EVENTS } from "../../constants";

function UserBadge(props) {
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const dispatch = useMainDispatchContext();

  const { currentUser, userGroups, profilePictureCacheBuster } = mainContext;
  const { user, menuEnabled, secondaryInfoField, isProfileBadge } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  if (isNullOrUndefined(user)) return null;
  const isInternal = currentUser?.isInternal;
  const isProfileInternal = isProfileBadge && isInternal;

  const handleClick = (e) => {
    if (!menuEnabled) return;
    if (isNullOrUndefined(anchorEl)) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  let secondaryInfoValue = user[secondaryInfoField];
  if (secondaryInfoField === "userGroupUuid" && !isEmptyList(userGroups)) {
    const userGroupObj = userGroups.find(
      (group) => group.uuid === user.userGroupUuid
    );
    secondaryInfoValue = !isNullOrUndefined(userGroupObj)
      ? userGroupObj.name
      : user?.company?.name; // default to company name
  } else {
    secondaryInfoValue = user.email;
  }

  let userPic = (
    <UserPic
      user={user}
      cacheBuster={profilePictureCacheBuster}
      fullSize={false}
    />
  );
  if (isProfileInternal) {
    userPic = (
      <Tooltip
        title={t("internal_user")}
        placement="bottom"
        componentsProps={tooltipSolidStyles}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        <div>{userPic}</div>
      </Tooltip>
    );
  }

  function handleNavigate(path, eventName) {
    navigate(path);
    setAnchorEl(null);
    logAnalyticsEvent(dispatch, eventName);
  }
  return (
    <>
      <div
        className={`user-badge ${
          !isNullOrUndefined(anchorEl) ? "active" : ""
        } ${menuEnabled ? "menu-enabled" : ""}${
          isProfileInternal ? " internal" : ""
        }`}
        onClick={handleClick}
      >
        <div>
          <div>{userPic}</div>
          <div className="user-info">
            <span className="user-info-primary">
              {user.firstName} {user.lastName}
            </span>
            <span className="user-info-secondary">
              {!isNullOrUndefined(secondaryInfoValue) ? secondaryInfoValue : ""}
            </span>
          </div>
          <div className="caret">{menuEnabled && <CaretDownSvg />}</div>
        </div>

        {menuEnabled && (
          <Menu
            className="user-badge-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={!isNullOrUndefined(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                border: "1px solid var(--outline-color)",
                borderRadius: "8px",
                backgroundColor: "var(--darker-bg-color)",
                width: "220px",
                "& .MuiList-root": {
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
                "& .MuiMenuItem-root": {
                  color: "#fff",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "14px",
                  "&:active": {},
                  "&:hover": {
                    backgroundColor: "var(--intermediate-bg-color)",
                  },
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleNavigate(
                  "/settings/profile",
                  ANALYTICS_EVENTS.NAV_EDIT_PROFILE
                );
              }}
            >
              <div className="menu-item">
                <span>{t("edit_profile")}</span>
                <div>&nbsp;</div>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/settings");
                setAnchorEl(null);
                handleNavigate(
                  "/settings/profile",
                  ANALYTICS_EVENTS.NAV_EDIT_PROFILE
                );
              }}
            >
              <div className="menu-item">
                <span>{t("settings")}</span>
                <div>
                  <Settings width={18} />
                </div>
              </div>
            </MenuItem>
            {isInternal && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleNavigate(
                    "/settings/deployments",
                    ANALYTICS_EVENTS.NAV_EDIT_PROFILE
                  );
                }}
              >
                <div className="menu-item">
                  <span>{t("switch_deployment")}</span>
                  <div>
                    <SwitchDeployment width={18} />
                  </div>
                </div>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              <div className="menu-item">
                <span className="danger">{t("log_out")}</span>
                <div>
                  <Logout width={18} className="danger" />
                </div>
              </div>
            </MenuItem>
          </Menu>
        )}
      </div>
    </>
  );
}

export default UserBadge;
