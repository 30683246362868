import "./styles.css";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
import { useTranslation } from "react-i18next";
function UnsupportedBrowser() {
  const { t } = useTranslation();

  return (
    <div className="unsupported-browser">
      <div>
        <div>
          <CriticalIcon width={24} height={24} />
        </div>
        <div>
          <div>
            <h3>{t("unsupported_browser")}</h3>
          </div>
          <div>
            <span>{t("unsupported_browser_note")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnsupportedBrowser;
