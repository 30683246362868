import { useEffect, useRef, useState } from "react";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import { MAP_ZOOM_PRESETS } from "../../mapUtils";
import { useTranslation } from "react-i18next";
import { logAnalyticsEvent } from "../../utils";
import { useMainDispatchContext } from "../../MainContext";
import { ANALYTICS_EVENTS } from "../../constants";

function MapRecenterControl(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();

  const [isActive, setIsActive] = useState(false);
  const { onClick, showActiveTurnaroundOption } = props;
  const componentRef = useRef(null);
  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [componentRef]);

  function handleSelection(value) {
    onClick(value);
    setIsActive(false);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.MAP_CHANGE_ZOOM_PRESET);
  }

  return (
    <div className="map-recenter" ref={componentRef}>
      <button
        onClick={() => {
          setIsActive((prev) => !prev);
        }}
        className={`outlined ${isActive ? "active" : ""}`}
      >
        {t("re_center")} <Caret />
      </button>
      {isActive && (
        <div className="map-recenter-menu-container fadeIn">
          <div className="map-recenter-menu">
            <div>
              {showActiveTurnaroundOption && (
                <div
                  className="map-recenter-menu-item"
                  onClick={() => {
                    handleSelection(MAP_ZOOM_PRESETS.OFF);
                  }}
                >
                  <div>{t("active_turnarounds")}</div>
                </div>
              )}
              <div
                className="map-recenter-menu-item"
                onClick={() => {
                  handleSelection(MAP_ZOOM_PRESETS.STATION);
                }}
              >
                <div>{t("station")}</div>
              </div>
              <div
                className="map-recenter-menu-item"
                onClick={() => {
                  handleSelection(MAP_ZOOM_PRESETS.APPROACH);
                }}
              >
                <div>
                  {t("final_approach")}&nbsp;
                  <span>({t("ten_miles_from_station")})</span>
                </div>
              </div>
              <div
                className="map-recenter-menu-item"
                onClick={() => {
                  handleSelection(MAP_ZOOM_PRESETS.IN_RANGE);
                }}
              >
                <div>
                  {t("in_range")}&nbsp;
                  <span>({t("one_hundred_miles_from_station")})</span>
                </div>
              </div>
              <div
                className="map-recenter-menu-item"
                onClick={() => {
                  handleSelection(MAP_ZOOM_PRESETS.EN_ROUTE);
                }}
              >
                <div>{t("five_hundred_miles_from_station")}</div>
              </div>
              <div
                className="map-recenter-menu-item"
                onClick={() => {
                  handleSelection(MAP_ZOOM_PRESETS.MAX);
                }}
              >
                <div>{t("one_thousand_miles_from_station")}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MapRecenterControl;
