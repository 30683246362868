import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import {
  formatTime,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import CrewInfo from "../CrewInfo";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import SubTabs from "../SubTabs";
import { getTaskDetail, getTaskInfo } from "./utils";
import { ANALYTICS_EVENTS } from "../../constants";

function CrewDetailModal(props) {
  const { t } = useTranslation();
  const { crewInfo, onClose, timezone, operationTypes, turnarounds, users } =
    props;
  const [selectedView, setSelectedView] = useState("tasks");

  function handleCloseModal() {
    onClose();
  }

  const tasksToRender = useMemo(() => {
    const taskList = [];
    const taskOperationUuids = [];
    if (!isEmptyList(crewInfo?.tasks)) {
      crewInfo.tasks.forEach((task) => {
        const operationType = getByFieldValue(
          operationTypes,
          "uuid",
          task.operationTypeUuid
        );
        const turnaround = getByFieldValue(
          turnarounds,
          "uuid",
          task.turnaroundUuid
        );
        const turnaroundInfo = !isNullOrUndefined(turnaround)
          ? getTurnaroundInfoForTurnaroundSummary(turnaround)
          : null;
        const turnaroundName = !isNullOrUndefined(turnaroundInfo)
          ? turnaroundInfo.combinedFlightName
          : null;

        const taskInfo = getTaskInfo(task, operationTypes, turnarounds);

        if (
          !taskOperationUuids.includes(task.operationUuid) &&
          !isNullOrUndefined(turnaroundInfo) &&
          taskInfo.isConfirmed
        ) {
          taskList.push({
            ...task,
            operationType: operationType,
            turnaroundName: turnaroundName,
            taskInfo: taskInfo,
          });
          taskOperationUuids.push(task.operationUuid);
        }
      });
    }
    return taskList;
  }, [crewInfo, operationTypes, turnarounds]);

  const mNow = moment().tz(timezone);

  return (
    <div>
      <Modal open={!isNullOrUndefined(crewInfo)} onClose={handleCloseModal}>
        <div className="modal crew-details-modal">
          {!isNullOrUndefined(crewInfo) && (
            <>
              <div>
                <div className="modal-header">
                  <h2>{t("view_crew")}</h2>
                  <div className="button-icon" onClick={handleCloseModal}>
                    <Cross />
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="modal-container">
                  <div className="crew-details-content">
                    <div className="crew-details-profile">
                      <CrewInfo
                        user={crewInfo.userInfo.user}
                        details={t(crewInfo.userInfo.dispatchStatus)}
                        mapStatus={crewInfo.userInfo.mapStatus}
                      />
                    </div>
                    <div className="crew-details-tabs">
                      <SubTabs
                        menuItems={[
                          {
                            id: "tasks",
                            name: `${t("confirmed_tasks")} (${
                              !isEmptyList(tasksToRender)
                                ? tasksToRender.length
                                : 0
                            })`,
                            eventName:
                              ANALYTICS_EVENTS.LIVE_ROSTER_VIEW_CREW_CONFIRMED_TASKS,
                          },
                          {
                            id: "shifts",
                            name: `${t("crew_shifts")} (${
                              !isEmptyList(crewInfo.userCrewShifts)
                                ? crewInfo.userCrewShifts.length
                                : 0
                            })`,
                            eventName:
                              ANALYTICS_EVENTS.LIVE_ROSTER_VIEW_CREW_SHIFTS,
                          },
                          {
                            id: "certs",
                            name: `${t("certifications")} (${
                              !isEmptyList(crewInfo.userInfo.certList)
                                ? crewInfo.userInfo.certList.length
                                : 0
                            })`,
                            eventName:
                              ANALYTICS_EVENTS.LIVE_ROSTER_VIEW_CREW_CERTIFICATIONS,
                          },
                        ]}
                        selectedView={selectedView}
                        onClick={(value) => {
                          setSelectedView(value);
                        }}
                      />
                    </div>
                    <div className="crew-details-body">
                      {selectedView === "shifts" && (
                        <div className="crew-details-module">
                          <div className="crew-shift-list">
                            {!isEmptyList(crewInfo.userCrewShifts) &&
                              crewInfo.userCrewShifts.map((shift, idx) => (
                                <div className="crew-shift-row" key={idx}>
                                  <div>
                                    {formatTime(
                                      shift.expectedStartTime,
                                      timezone,
                                      true,
                                      mNow.toDate()
                                    )}
                                    &nbsp;-&nbsp;
                                    {formatTime(
                                      shift.expectedEndTime,
                                      timezone,
                                      true,
                                      mNow.toDate()
                                    )}
                                  </div>
                                  <div>{shift.departmentName}</div>
                                </div>
                              ))}
                            {isEmptyList(crewInfo.userCrewShifts) && (
                              <div className="crew-shift-row">
                                <div className="empty-state">
                                  {t("crew_shifts_empty_department")}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {selectedView === "certs" && (
                        <div className="crew-details-module">
                          <div className="crew-cert-list">
                            {!isEmptyList(crewInfo.userInfo.certList) &&
                              crewInfo.userInfo.certList.map(
                                (certName, idx) => (
                                  <div className="crew-cert-row" key={idx}>
                                    <div>{certName}</div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      )}
                      {selectedView === "tasks" && (
                        <div className="crew-details-module">
                          <div className="crew-tasks-list">
                            {isEmptyList(tasksToRender) && (
                              <div className="crew-shift-row">
                                <div className="empty-state">
                                  {t("no_assignments")}
                                </div>
                              </div>
                            )}
                            {!isEmptyList(tasksToRender) &&
                              tasksToRender.map((taskToRender, idx) => {
                                const taskDetail = getTaskDetail(
                                  taskToRender,
                                  users,
                                  timezone
                                );
                                return (
                                  <div className="crew-task-row" key={idx}>
                                    <div>{`${formatTime(
                                      taskToRender.expectedStartTime,
                                      timezone,
                                      true,
                                      mNow.toISOString()
                                    )} - ${formatTime(
                                      taskToRender.expectedEndTime,
                                      timezone,
                                      true,
                                      mNow.toISOString()
                                    )}`}</div>
                                    <div
                                      className={`crew-task-row-details${
                                        taskToRender.completed
                                          ? " completed"
                                          : taskToRender.started
                                          ? " in-progress"
                                          : ""
                                      }`}
                                    >
                                      <div>{`${taskToRender.turnaroundName} - ${taskToRender.operationType.name}`}</div>
                                      {!isNullOrUndefined(taskDetail) && (
                                        <div>
                                          <span>{taskDetail}</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div></div>
                <div>
                  <button className="secondary" onClick={handleCloseModal}>
                    {t("close")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CrewDetailModal;
