import { isBlank, isNullOrUndefined, trimText } from "../../utils";
import { EMPTY_VALUE } from "../../constants";
import { tooltipLightStyles } from "../../styleUtils";
import { Fade, Tooltip } from "@mui/material";

function TurnaroundRemarks(props) {
  const { item, onClick, isReadonly } = props;

  const trimmedRemarks = !isBlank(item?.remarks)
    ? trimText(item?.remarks, 50)
    : null;
  const trimmed = !isBlank(trimmedRemarks)
    ? trimmedRemarks.length < item?.remarks.length
    : false;

  return (
    <div
      className={`turnaround-remarks${!isReadonly ? " active" : ""}`}
      onClick={onClick}
    >
      <Tooltip
        title={
          !isNullOrUndefined(item?.remarks) ? (
            <div className="tooltip-content">
              <div>{item?.remarks}</div>
            </div>
          ) : null
        }
        placement="top"
        componentsProps={tooltipLightStyles}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        enterDelay={500}
        arrow
      >
        <div>
          <div className="turnaround-remarks-content">
            {!isBlank(trimmedRemarks) && (
              <>
                {trimmedRemarks}
                {trimmed && <>&hellip;</>}
              </>
            )}
            {isBlank(trimmedRemarks) && <>{EMPTY_VALUE}</>}
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default TurnaroundRemarks;
