// TODO: consider changing this to the deployment date
export const MIN_DATE = "2024-02-09";

export const EMPTY_TIME = "--:--";

export const EMPTY_STAND = "-/-";

export const EMPTY_VALUE = "--";

export const DEFAULT_TIMEZONE = "America/Los_Angeles";

export const ALERT_DURATION = {
  DEFAULT: 5000,
  ERROR: 30000,
};

// in milliseconds
export const POLLING_INTERVALS = {
  TURNAROUNDS: 10000,
  TURNAROUNDS_SUMMARY: 60000,
  MAP_POSITIONS: 3000,
  MAP_POSITION_PATH: 3000,
  TIME_OF_DAY: 60000,
  COMMS_CHANNEL: 3000,
  CHAT_SUMMARY: 10000,
  LIVE_ROSTER: 30000,
};

export const DEFAULT_START_TIME_OFFSET = 5;

export const ID_FIELDS = {
  ID: "id",
  UUID: "uuid",
};

export const SEARCH_QUERY_PLACEHOLDERS = {
  SEARCH: "search",
  SEARCH_FLIGHTS: "search_flights",
  SEARCH_USERS: "search_users",
  SEARCH_VEHICLES: "search_gses",
  SEARCH_TURNAROUNDS: "search_turnarounds",
  SEARCH_CREW: "search_crew",
  SEARCH_AIRCRAFT_MODELS: "search_aircraft_models",
};

export const MAP_ASSET_CATEGORIES = {
  TURNAROUNDS: {
    label: "Turnarounds",
    value: "turnarounds",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
  },
  CREW: {
    label: "Crew",
    value: "crew",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_CREW,
  },
  FLIGHTS: {
    label: "Flights",
    value: "flights",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_FLIGHTS,
  },
  VEHICLES: {
    label: "Vehicles",
    value: "vehicles",
    searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_VEHICLES,
  },
};

export const OVERLAY_PANEL_MODES = {
  TURNAROUNDS: {
    value: "turnarounds",
  },
  CREW: {
    value: "crew",
  },
  VEHICLES: {
    value: "vehicles",
  },
  FLIGHTS: {
    value: "flights",
  },
};

export const MAP_OVERLAY_PANEL_MODES = [
  OVERLAY_PANEL_MODES.TURNAROUNDS,
  OVERLAY_PANEL_MODES.CREW,
  OVERLAY_PANEL_MODES.VEHICLES,
  OVERLAY_PANEL_MODES.FLIGHTS,
];

export const METRICS_SORT_FIELDS = {
  COMPLETED_ONTIME: "completed_on_time",
  COMPLETED_LATE: "completed_late",
  COMPLETED_PARTIALLY: "completed_partially",
  MISSING: "missing",
};

export const METRICS_TERMS = {
  ALL_OPERATIONS: "All operations",
};

export const METRICS_VALUE_MODES = {
  PERCENT: {
    value: "symbol_percent",
  },
  NUMERIC: {
    value: "symbol_numeric",
  },
};

// For 3-part pie missing/late/on-time
export const CHART_COLORS = ["#4A5654", "#2BB2FE", "#1779DA", "#C38306"];

// For Line/Bar charts that can have multiple series
export const CHART_SERIES_COLORS = [
  "#1779DA",
  "#626262",
  "#B0B0B0",
  "#C20962",
  "#F76DAF",
  "#9D09C2",
  "#DC6DF7",
  "#5B10BB",
  "#AA72F2",
  "#0B28C2",
  "#6E84F7",
  "#0A7FC1",
  "#6DC5F7",
  "#07B0A6",
  "#6BEAE2",
  "#0AB372",
  "#9EF3D2",
  "#438E09",
  "#99D06D",
  "#C38306",
  "#F9C86B",
  "#BF4C0C",
  "#F59F6F",
  "#C20909",
  "#F76D6D",
];
export const CHART_COLOR_ALL = "#1779DA";

export const ANALYTICS_EVENTS = {
  CLIENT_ERROR: "CLIENT_ERROR",
  NAV_DASHBOARD: "NAV_DASHBOARD",
  NAV_LIVE_ROSTER: "NAV_LIVE_ROSTER",
  NAV_CHAT: "NAV_CHAT",
  NAV_MAP: "NAV_MAP",
  NAV_TURNAROUNDS: "NAV_TURNAROUNDS",
  NAV_CREW_SHIFTS: "NAV_CREW_SHIFTS",
  NAV_STAND_ASSIGNMENTS: "NAV_STAND_ASSIGNMENTS",
  NAV_USERS: "NAV_USERS",
  NAV_GSE: "NAV_GSE",
  NAV_TURNAROUND_TEMPLATES: "NAV_TURNAROUND_TEMPLATES",
  NAV_PERFORMANCE: "NAV_PERFORMANCE",
  NAV_LEADERBOARD: "NAV_LEADERBOARD",
  NAV_EDIT_PROFILE: "NAV_EDIT_PROFILE",
  NAV_MAP_SETTINGS: "NAV_MAP_SETTINGS",
  NAV_CHANGE_PASSWORD: "NAV_CHANGE_PASSWORD",
  NAV_MENU_TOGGLE: "NAV_MENU_TOGGLE",
  DASHBOARD_DISMISS_RELEASE_NOTES: "DASHBOARD_DISMISS_RELEASE_NOTES",
  LIVE_ROSTER_CHANGE_DEPARTMENT: "LIVE_ROSTER_CHANGE_DEPARTMENT",
  LIVE_ROSTER_FILTER: "LIVE_ROSTER_FILTER",
  LIVE_ROSTER_SEARCH: "LIVE_ROSTER_SEARCH",
  LIVE_ROSTER_TASK_VIEW: "LIVE_ROSTER_TASK_VIEW",
  LIVE_ROSTER_UNASSIGNED_VIEW: "LIVE_ROSTER_UNASSIGNED_VIEW",
  LIVE_ROSTER_VIEW_CREW: "LIVE_ROSTER_VIEW_CREW",
  LIVE_ROSTER_VIEW_CREW_CONFIRMED_TASKS:
    "LIVE_ROSTER_VIEW_CREW_CONFIRMED_TASKS",
  LIVE_ROSTER_VIEW_CREW_SHIFTS: "LIVE_ROSTER_VIEW_CREW_SHIFTS",
  LIVE_ROSTER_VIEW_CREW_CERTIFICATIONS: "LIVE_ROSTER_VIEW_CREW_CERTIFICATIONS",
  LIVE_ROSTER_TASK_REMOVE_ASSIGNMENT: "LIVE_ROSTER_TASK_REMOVE_ASSIGNMENT",
  LIVE_ROSTER_TASK_ADD_ASSIGNMENT: "LIVE_ROSTER_TASK_ADD_ASSIGNMENT",
  LIVE_ROSTER_TASK_SAVE: "LIVE_ROSTER_TASK_SAVE",
  CHAT_SEARCH: "CHAT_SEARCH",
  CHAT_CREATE: "CHAT_CREATE",
  CHAT_EDIT: "CHAT_EDIT",
  CHAT_SEND: "CHAT_SEND",
  CHAT_UPLOAD_ATTACHMENT: "CHAT_UPLOAD_ATTACHMENT",
  CHAT_VIEW_CHANNEL: "CHAT_VIEW_CHANNEL",
  CHAT_TURNAROUND_SHOW_ON_MAP: "CHAT_TURNAROUND_SHOW_ON_MAP",
  MAP_VIEW_TURNAROUNDS_TAB: "MAP_VIEW_TURNAROUNDS_TAB",
  MAP_VIEW_CREW_TAB: "MAP_VIEW_CREW_TAB",
  MAP_VIEW_GSE_TAB: "MAP_VIEW_GSE_TAB",
  MAP_VIEW_FLIGHTS_TAB: "MAP_VIEW_FLIGHTS_TAB",
  MAP_CHANGE_FILTERS: "MAP_CHANGE_FILTERS",
  MAP_SEARCH: "MAP_SEARCH",
  MAP_CHANGE_ZOOM_PRESET: "MAP_CHANGE_ZOOM_PRESET",
  MAP_VIEW_TURNAROUND_TILE: "MAP_VIEW_TURNAROUND_TILE",
  MAP_VIEW_CREW_TILE: "MAP_VIEW_CREW_TILE",
  MAP_VIEW_GSE_TILE: "MAP_VIEW_GSE_TILE",
  MAP_VIEW_FLIGHTS_TILE: "MAP_VIEW_FLIGHTS_TILE",
  MAP_VIEW_TURNAROUND_MARKER: "MAP_VIEW_TURNAROUND_MARKER",
  MAP_VIEW_CREW_MARKER: "MAP_VIEW_CREW_MARKER",
  MAP_VIEW_GSE_MARKER: "MAP_VIEW_GSE_MARKER",
  MAP_VIEW_AIRCRAFT_MARKER: "MAP_VIEW_AIRCRAFT_MARKER",
  MAP_TURNAROUND_VIEW_TURNAROUND: "MAP_TURNAROUND_VIEW_TURNAROUND",
  MAP_TURNAROUND_VIEW_INBOUND: "MAP_TURNAROUND_VIEW_INBOUND",
  MAP_TURNAROUND_VIEW_OUTBOUND: "MAP_TURNAROUND_VIEW_OUTBOUND",
  MAP_TURNAROUND_VIEW_OPERATION: "MAP_TURNAROUND_VIEW_OPERATION",
  MAP_TURNAROUND_CONFIGURE_TURNAROUND: "MAP_TURNAROUND_CONFIGURE_TURNAROUND",
  MAP_TURNAROUND_VIEW_TIMELINE: "MAP_TURNAROUND_VIEW_TIMELINE",
  MAP_TURNAROUND_VIEW_CHAT: "MAP_TURNAROUND_VIEW_CHAT",
  MAP_TURNAROUND_MOVE_AIRCRAFT: "MAP_TURNAROUND_MOVE_AIRCRAFT",
  MAP_TURNAROUND_MOVE_AIRCRAFT_CONFIRM: "MAP_TURNAROUND_MOVE_AIRCRAFT_CONFIRM",
  TURNAROUNDS_CONFIGURE: "TURNAROUNDS_CONFIGURE",
  TURNAROUNDS_SEARCH: "TURNAROUNDS_SEARCH",
  TURNAROUNDS_EDIT_REMARKS: "TURNAROUNDS_EDIT_REMARKS",
  TURNAROUNDS_SAVE_REMARKS: "TURNAROUNDS_SAVE_REMARKS",
  TURNAROUNDS_VIEW_DETAILS: "TURNAROUNDS_VIEW_DETAILS",
  TURNAROUNDS_DETAILS_ADD_CREW: "TURNAROUNDS_DETAILS_ADD_CREW",
  TURNAROUNDS_DETAILS_ADD_GSE: "TURNAROUNDS_DETAILS_ADD_GSE",
  TURNAROUNDS_DETAILS_REMOVE_CREW: "TURNAROUNDS_DETAILS_REMOVE_CREW",
  TURNAROUNDS_DETAILS_REMOVE_GSE: "TURNAROUNDS_DETAILS_REMOVE_GSE",
  TURNAROUNDS_DETAILS_TOGGLE_OPERATION_ENABLED:
    "TURNAROUNDS_DETAILS_TOGGLE_OPERATION_ENABLED",
  TURNAROUNDS_DETAILS_TOGGLE_OPERATION_DISABLED:
    "TURNAROUNDS_DETAILS_TOGGLE_OPERATION_DISABLED",
  TURNAROUNDS_DETAILS_SAVE_OPERATION_ENABLED:
    "TURNAROUNDS_DETAILS_SAVE_OPERATION_ENABLED",
  TURNAROUNDS_DETAILS_SAVE_OPERATION_DISABLED:
    "TURNAROUNDS_DETAILS_SAVE_OPERATION_DISABLED",
  TURNAROUNDS_DETAILS_SHOW_ON_MAP: "TURNAROUNDS_DETAILS_SHOW_ON_MAP",
  CREW_SHIFTS_ADD_CREW_SHIFT: "CREW_SHIFTS_ADD_CREW_SHIFT",
  CREW_SHIFTS_DOWNLOAD_CSV_TEMPLATE: "CREW_SHIFTS_DOWNLOAD_CSV_TEMPLATE",
  CREW_SHIFTS_UPLOAD_CSV: "CREW_SHIFTS_UPLOAD_CSV",
  CREW_SHIFTS_EXPORT_CSV: "CREW_SHIFTS_EXPORT_CSV",
  CREW_SHIFTS_IMPORT_CSV: "CREW_SHIFTS_IMPORT_CSV",
  CREW_SHIFTS_EDIT_CREW_SHIFT: "CREW_SHIFTS_EDIT_CREW_SHIFT",
  CREW_SHIFTS_REMOVE_CREW_SHIFT: "CREW_SHIFTS_REMOVE_CREW_SHIFT",
  CREW_SHIFTS_SAVE_CREW_SHIFT: "CREW_SHIFTS_SAVE_CREW_SHIFT",
  CREW_SHIFTS_SEARCH: "CREW_SHIFTS_SEARCH",
  STAND_ASSIGNMENTS_SAVE: "STAND_ASSIGNMENTS_SAVE",
  STAND_ASSIGNMENTS_SEARCH: "STAND_ASSIGNMENTS_SEARCH",
  USERS_EDIT: "USERS_EDIT",
  USERS_DELETE: "USERS_DELETE",
  USERS_SAVE: "USERS_SAVE",
  USERS_SEARCH: "USERS_SEARCH",
  USERS_CHANGE_FILTER: "USERS_CHANGE_FILTER",
  GSE_SET_ONLINE: "GSE_SET_ONLINE",
  GSE_SET_OFFLINE: "GSE_SET_OFFLINE",
  GSE_DELETE: "GSE_DELETE",
  GSE_ADD: "GSE_ADD",
  GSE_CHANGE_FILTERS: "GSE_CHANGE_FILTERS",
  GSE_SAVE: "GSE_SAVE",
  GSE_SHOW_ON_MAP: "GSE_SHOW_ON_MAP",
  GSE_SEARCH: "GSE_SEARCH",
  TURNAROUND_TEMPLATE_EDIT: "TURNAROUND_TEMPLATE_EDIT",
  TURNAROUND_TEMPLATE_TOGGLE_OPERATION: "TURNAROUND_TEMPLATE_TOGGLE_OPERATION",
  TURNAROUND_TEMPLATE_NEXT: "TURNAROUND_TEMPLATE_NEXT",
  TURNAROUND_TEMPLATE_SAVE: "TURNAROUND_TEMPLATE_SAVE",
};

export const FEATURE_FLAGS = {
  WEB_RELEASE_NOTES: "web-release-notes",
};

export const DATE_GRANULARITY = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
};

export const TIMELINE_EVENTS = {
  UNKNOWN: 0,
  SERVICE_INITIATED: 1,
  STAND_UPDATE: 2,
  INBOUND_AIRBORNE: 3,
  AIRCRAFT_LANDED: 4,
  GATE_IN: 5,
  GATE_OUT: 6,
  AIRCRAFT_DEPARTED: 7,
  OPERATION_INITIATED: 8,
  OPERATION_COMPLETED: 9,
  OPERATION_DELAYED: 10,
  CREW_DISPATCHED: 11,
};

export const MapStyles = {
  DYNAMIC: "dynamic_lighting_style",
  STANDARD: "standard_style",
};

export const MenuMode = {
  DEFAULT: "DEFAULT",
  COMPACT: "COMPACT",
};

export const TurnaroundsPageMode = {
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
};

export const UserDispatchStatus = {
  AVAILABLE: "available",
  UNAVAILABLE_NOT_ONLINE: "offline",
  UNAVAILABLE_IN_SERVICE: "unavailable_servicing",
  AVAILABLE_DURING_TURNAROUND: "available_during_turnaround",
  UNAVAILABLE_NOT_ONLINE_DURING_TURNAROUND:
    "unavailable_not_online_during_turnaround",
  UNAVAILABLE_IN_SERVICE_DURING_TURNAROUND:
    "unavailable_servicing_during_turnaround",
};

// TODO: Refactor to use operation types
// Rely on the operation name to identify a Reposition operation
export const RepositionOperationNames = {
  REPOSITION_1: "Reposition 1",
  REPOSITION_2: "Reposition 2",
};

export const MAP_RIGHT_PANEL_MODES = {
  TIMELINE: "TIMELINE",
  CHAT: "CHAT",
  CREW_ALLOCATIONS: "CREW_ALLOCATIONS",
  CREW_ASSIGNMENTS: "CREW_ASSIGNMENTS",
  ADD_CREW_MEMBER: "ADD_CREW_MEMBER",
};

export const SCROLL_STYLES = {
  SMOOTH: "smooth",
  INSTANT: "instant",
};

export const DEFAULT_GROUP_NAME = "Default";
