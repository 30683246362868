import "./styles.css";
import CrewInfo from "../CrewInfo";
import { useTranslation } from "react-i18next";
import SelectCrewMember from "../Dispatches/SelectCrewMember";
import { deepCopy, isEmptyList, isNullOrUndefined } from "../../utils";
import { getUserInfo } from "../../userUtils";

function SelectCrew(props) {
  const { t } = useTranslation();
  const {
    isActive,
    isEditable,
    isProfileEditable,
    isSelected,
    onRemove,
    onClick,
    onCancel,
    onSelect,
    users,
    positions,
    turnarounds,
    assignedUser,
    assignedByUser,
    requirement,
    assignments,
    shiftContext,
  } = props;

  const userInfo = !isNullOrUndefined(assignedUser)
    ? getUserInfo(assignedUser, turnarounds, positions)
    : null;
  const assignedByUserInfo = !isNullOrUndefined(assignedByUser)
    ? getUserInfo(assignedByUser, turnarounds, positions)
    : null;

  const crewAssignmentsToExclude = deepCopy(assignments);
  if (!isEmptyList(assignments)) {
    for (let i = 0; i < assignments.length; i++) {
      const assignment = assignments[i];
      const assignedUserUuid = assignment?.assignedUser?.uuid;
      if (!isNullOrUndefined(assignedUserUuid)) {
        crewAssignmentsToExclude.push({
          userUuid: assignment.assignedUser.uuid,
        });
      }
    }
  }

  return (
    <div
      className={`select-crew-slot${
        !isNullOrUndefined(assignedUser) ? " assigned" : ""
      }${isSelected ? " selected" : ""}${
        !isProfileEditable ? " disabled" : ""
      }`}
    >
      <div>
        {isActive && (
          <SelectCrewMember
            onSelect={onSelect}
            onCancel={onCancel}
            users={users}
            positions={positions}
            turnarounds={turnarounds}
            certification={requirement.certification}
            crewAssignments={crewAssignmentsToExclude}
            shiftContext={shiftContext}
          />
        )}
        {!isActive && (
          <>
            {isNullOrUndefined(userInfo) && (
              <div
                className="select-crew-slot-content"
                onClick={() => {
                  if (isProfileEditable) {
                    onClick();
                  }
                }}
              >
                <div className="unassigned">
                  <CrewInfo
                    user={{
                      firstName: t("allocated"),
                      lastName: "",
                    }}
                    hideMapStatus={true}
                    details={
                      <div className="timestamps de-emphasize">
                        <div>{t("crew_member_not_assigned")}</div>
                      </div>
                    }
                  />
                </div>
                <div className="actions">
                  <div></div>
                  <div>
                    {isProfileEditable && (
                      <button
                        className="secondary"
                        onClick={(e) => {
                          onClick();
                          e.stopPropagation();
                        }}
                      >
                        {t("assign")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!isNullOrUndefined(userInfo) && (
              <div className="select-crew-slot-content">
                <div>
                  <CrewInfo
                    user={userInfo.user}
                    mapStatus={userInfo.mapStatus}
                    details={
                      !isNullOrUndefined(assignedByUserInfo)
                        ? t("assigned_by_web", {
                            name: assignedByUserInfo.fullName,
                          })
                        : null
                    }
                  />
                </div>
                <div className="actions">
                  <div></div>
                  <div>
                    {isEditable && (
                      <button
                        className="secondary"
                        onClick={() => {
                          onRemove();
                        }}
                      >
                        {t("remove")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SelectCrew;
