import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getPositions, getTurnaroundsSummary } from "../../api";
import { POLLING_INTERVALS } from "../../constants";
import { isEmptyList } from "../../utils";
import { useChatContext, useChatDispatchContext } from "../../ChatContext";
import { getChatSummary } from "../../chatApi";

/* Component that just polls/fetches data that can be used globally by components */
function ConsoleDataHelper() {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundsSummaryLoading,
    positionsLoading,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
  } = mainContext;
  const chatDispatch = useChatDispatchContext();
  const chatContext = useChatContext();
  const { chatSummary, chatSummaryLoading, chatChannelMessageSaving } =
    chatContext;

  const [refreshPositionsRequested, setRefreshPositionsRequested] =
    useState(false);
  const [chatSummaryRequested, setChatSummaryRequested] = useState(false);
  const [
    refreshTurnaroundsSummaryRequested,
    setRefreshTurnaroundsSummaryRequested,
  ] = useState(false);

  useEffect(() => {
    let unreadChatChannels = 0;
    if (!isEmptyList(chatSummary?.channels)) {
      chatSummary.channels.forEach((item) => {
        if (item.hasUnreadMessages) {
          unreadChatChannels++;
        }
      });
    }
    dispatch({
      type: "setUnreadChatChannels",
      value: unreadChatChannels,
    });
  }, [dispatch, chatSummary]);

  // Refresh comms
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (
      !chatSummaryLoading &&
      !chatChannelMessageSaving &&
      chatSummaryRequested
    ) {
      getChatSummary(chatDispatch);
      setChatSummaryRequested(false);
    }
  }, [
    chatDispatch,
    chatSummaryRequested,
    chatSummaryLoading,
    chatChannelMessageSaving,
    isRefreshPaused,
  ]);

  // Refresh positions for dispatch
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshPositionsRequested) return () => {};
    if (positionsLoading) return () => {};
    getPositions(dispatch);
    setRefreshPositionsRequested(false);
  }, [
    dispatch,
    positionsLoading,
    refreshPositionsRequested,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
  ]);

  // Refresh TurnarnoundsSummary (previously Flights to Render)
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (turnaroundsSummaryLoading) return () => {};
    if (!refreshTurnaroundsSummaryRequested) return () => {};
    getTurnaroundsSummary(dispatch);
    setRefreshTurnaroundsSummaryRequested(false);
  }, [
    dispatch,
    turnaroundsSummaryLoading,
    refreshTurnaroundsSummaryRequested,
    isRefreshPaused,
  ]);

  // Setup intervals here (only once)
  useEffect(() => {
    const intervals = [];

    if (POLLING_INTERVALS.MAP_POSITIONS > 0) {
      const interval = setInterval(() => {
        setRefreshPositionsRequested(true);
      }, POLLING_INTERVALS.MAP_POSITIONS);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.CHAT_SUMMARY > 0) {
      const interval = setInterval(() => {
        setChatSummaryRequested(true);
      }, POLLING_INTERVALS.CHAT_SUMMARY);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.TURNAROUNDS_SUMMARY > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundsSummaryRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS_SUMMARY);
      intervals.push(interval);
    }

    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  return <></>;
}
export default ConsoleDataHelper;
