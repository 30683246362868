import "./styles.css";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import SettingsMenuItem from "./SettingsMenuItem";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import Deployments from "../Deployments";
import { isBlank, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import { useTranslation } from "react-i18next";
import MapStyle from "./MapStyle";
// import AlertPreferences from "./AlertPreferences";
import { useNavigate, useParams } from "react-router-dom";
import AdminConsole from "../AdminConsole";
import { useSettingsContext } from "../../SettingsContext";
import { useEffect } from "react";
import { ANALYTICS_EVENTS } from "../../constants";

function Settings() {
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const dispatch = useMainDispatchContext();
  const { currentUser } = mainContext;
  const settingsContext = useSettingsContext();
  const { globalError } = settingsContext;

  const navigate = useNavigate();
  const { section } = useParams();
  const isInternal = currentUser?.isInternal;
  const currentSection = !isBlank(section) ? section : "profile";

  // Temp disabled until there is a fix for the Standard style airplane issue
  const isDynamicStyleEnabled = true;

  // Bubble up to the main context
  useEffect(() => {
    dispatch({
      type: "setGlobalError",
      globalError: globalError,
    });
  }, [dispatch, globalError]);

  function handleNavigate(path, eventName) {
    navigate(path);
    logAnalyticsEvent(dispatch, eventName);
  }

  return (
    <div className="settings">
      <div className="settings-container">
        <div className="settings-sidebar">
          <div>
            <div className="settings-menu">
              <div className="settings-menu-content">
                <div className="settings-menu-header">
                  <h3>{t("settings")}</h3>
                </div>

                <SettingsMenuItem
                  label={t("edit_profile")}
                  handleClick={() => {
                    handleNavigate(
                      "/settings/profile",
                      ANALYTICS_EVENTS.NAV_EDIT_PROFILE
                    );
                  }}
                  isSelected={currentSection === "profile"}
                />
                {/** Alerts are temp disabled 
                <SettingsMenuItem
                  label={t("alerts")}
                  handleClick={() => {
                    navigate("/settings/alerts");
                  }}
                  isSelected={currentSection === "alerts"}
                />
                **/}
                {isDynamicStyleEnabled && (
                  <SettingsMenuItem
                    label={t("map_style")}
                    handleClick={() => {
                      navigate("/settings/map");
                      handleNavigate(
                        "/settings/map",
                        ANALYTICS_EVENTS.NAV_MAP_SETTINGS
                      );
                    }}
                    isSelected={currentSection === "map"}
                  />
                )}
                <SettingsMenuItem
                  label={t("change_password")}
                  handleClick={() => {
                    handleNavigate(
                      "/settings/change-password",
                      ANALYTICS_EVENTS.NAV_CHANGE_PASSWORD
                    );
                  }}
                  isSelected={currentSection === "change-password"}
                />
              </div>
            </div>

            {isInternal && (
              <div className="settings-menu">
                <div className="settings-menu-content">
                  <div className="settings-menu-header two">
                    <h3>{t("admin")}</h3>
                    <div>
                      <div className="internal-badge">{t("internal")}</div>
                    </div>
                  </div>
                  <SettingsMenuItem
                    label={t("switch_deployment")}
                    handleClick={() => {
                      navigate("/settings/deployments");
                    }}
                    isSelected={currentSection === "deployments"}
                  />
                  <SettingsMenuItem
                    label={t("admin_console")}
                    handleClick={() => {
                      navigate("/settings/admin-console");
                    }}
                    isSelected={currentSection === "admin-console"}
                  />
                </div>
              </div>
            )}
          </div>
          <div></div>
          <div>
            <div className="copyright">
              <div>{t("copyright")} &copy; 2025 Moonware Inc.</div>
              <div>{t("all_rights_reserved")}</div>
            </div>
          </div>
        </div>
        <div className="settings-main">
          {!isNullOrUndefined(currentUser) && (
            <>
              {currentSection === "profile" && <EditProfile />}
              {/** Alerts are temp disabled
               currentSection === "alerts" && <AlertPreferences />
               **/}
              {currentSection === "change-password" && <ChangePassword />}
              {currentSection === "map" && <MapStyle />}
              {currentSection === "deployments" && isInternal && (
                <Deployments />
              )}
              {currentSection === "admin-console" && isInternal && (
                <AdminConsole />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
