import "./styles.css";
import { useState, useEffect } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getDeployments, getUser } from "../../api";
import { isBlank, isNullOrUndefined } from "../../utils";
import { selectInputFix } from "../../styleUtils";
import LoadingIndicator from "../LoadingIndicator";
import { CookieManager } from "../../cookies";
import { useTranslation } from "react-i18next";
import { switchDeployment } from "../../settingsApi";
import { useSettingsDispatchContext } from "../../SettingsContext";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";

function Deployments() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const settingsDispatch = useSettingsDispatchContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    deployments,
    deploymentsLoading,
    deploymentSaving,
    deployment,
  } = mainContext;

  const [selectedDeployment, setSelectedDeployment] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedDeploymentTitles, setSelectedDeploymentTitles] = useState([]);
  const [switchInitiated, setSwitchInitiated] = useState(false);
  const [deploymentsList, setDeploymentsList] = useState([]);

  useEffect(() => {
    setDeploymentsList([]);
    getDeployments(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!isNullOrUndefined(deployments)) {
      setDeploymentsList(deployments);
      const userGroupUuid = !isNullOrUndefined(currentUser)
        ? currentUser.userGroupUuid
        : null;
      let deploymentFound = false;
      for (let i = 0; i < deployments.length && !deploymentFound; i++) {
        const deployment = deployments[i];
        if (!isNullOrUndefined(deployment.userGroups)) {
          const userGroups = deployment.userGroups;
          for (let j = 0; j < userGroups.length && !deploymentFound; j++) {
            const userGroup = deployment.userGroups[j];
            if (userGroup.uuid === userGroupUuid) {
              setSelectedDeployment(deployment.uuid);
              setSelectedTitle(userGroup.uuid);
              setSelectedGroupName(userGroup.name);
              setSelectedDeploymentTitles(userGroups);
            }
          }
        }
      }
    }
  }, [deployments, currentUser]);

  useEffect(() => {
    if (switchInitiated && !deploymentSaving) {
      // refetch the user
      getUser(dispatch);
      setSwitchInitiated(false);
    }
  }, [dispatch, switchInitiated, deploymentSaving]);

  useEffect(() => {
    if (!isNullOrUndefined(deployment?.mediaSignatures)) {
      CookieManager.setCookieValue(
        "mediaSignatures",
        JSON.stringify(deployment.mediaSignatures)
      );
    }
  }, [deployment]);

  async function handleSwitchDeployment() {
    setSwitchInitiated(true);
    const result = await switchDeployment(settingsDispatch, selectedTitle);
    if (result) {
      await getUser(dispatch);
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("deployment_switched_msg"),
      });
    }
  }

  const isValid = () => {
    return !isBlank(selectedDeployment) && !isBlank(selectedTitle);
  };

  const isChanged = () => {
    const userGroupUuid = !isNullOrUndefined(currentUser)
      ? currentUser.userGroupUuid
      : null;
    return !isNullOrUndefined(userGroupUuid) && selectedTitle !== userGroupUuid;
  };

  return (
    <div className="deployments">
      <div>
        <div className="settings-form-header">
          <h3>{t("switch_deployment")}</h3>
        </div>
        <div className="settings-form">
          {deploymentsLoading && <LoadingIndicator />}
          {!deploymentsLoading && (
            <>
              <div className="form-container">
                <div className="field-row">
                  <label>{t("deployment")}</label>

                  {!isNullOrUndefined(deploymentsList) &&
                    deploymentsList.length > 0 && (
                      <select
                        value={selectedDeployment}
                        onChange={(e) => {
                          const deploymentId = e.target.value;
                          setSelectedDeployment(deploymentId);
                          let deploymentFound = false;
                          for (
                            let i = 0;
                            i < deployments.length && !deploymentFound;
                            i++
                          ) {
                            const deployment = deployments[i];
                            if (deployment.uuid === deploymentId) {
                              const userGroups = deployment.userGroups;
                              setSelectedDeploymentTitles(userGroups);
                              setSelectedTitle(deployment.userGroups[0].uuid);
                              deploymentFound = true;
                            }
                          }
                        }}
                        style={selectInputFix}
                      >
                        {deployments &&
                          deployments.map((item) => (
                            <option value={item.uuid} key={item.uuid}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    )}
                </div>
                <div className="field-row">
                  <label>{t("user_groups")}</label>
                  <select
                    value={selectedTitle}
                    onChange={(e) => {
                      setSelectedTitle(e.target.value);
                    }}
                    style={selectInputFix}
                  >
                    {selectedDeploymentTitles &&
                      selectedDeploymentTitles.map((item) => (
                        <option value={item.uuid} key={item.uuid}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="field-row">
                  <button
                    onClick={handleSwitchDeployment}
                    disabled={!isValid || deploymentSaving || !isChanged()}
                  >
                    {t("update")}
                  </button>
                </div>

                <div></div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="deployments-right-panel">
        <div>
          <div className="form-container">
            <div className="field-row">
              <label>Resource Actions: {selectedGroupName}</label>
              <div className="resource-actions-rows">
                {Object.keys(ResourceActions).map((i) => {
                  const value = userHasAnyResourceAction(
                    currentUser,
                    ResourceActions[i]
                  );
                  return (
                    <div
                      className={`resource-actions${value ? " enabled" : ""}`}
                      key={i}
                    >
                      <div>
                        <div>{ResourceActions[i]}</div>
                        <div>{i}</div>
                        <div className="resource-actions-value">
                          {value ? "true" : "false"}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deployments;
