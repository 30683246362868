import { useTranslation } from "react-i18next";
import { DEFAULT_TIMEZONE, MIN_DATE } from "../../constants";
import { isNullOrUndefined } from "../../utils";
import "./styles.css";
import moment from "moment-timezone";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import { getPerformanceCrew, getUsers } from "../../api";
import CrewLeaderboardChart from "./CrewLeaderboardChart";
import RangePicker from "../ButtonDatePicker/RangePicker";

function CrewLeaderboard() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, performanceCrew, users } = mainContext;
  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const mToday = moment().tz(airportTimezone).startOf("minute");
  const mThirtyDaysAgo = moment(mToday).add(-90, "day").tz(airportTimezone);
  const [selectedStartDate, setSelectedStartDate] = useState(mThirtyDaysAgo);
  const [selectedEndDate, setSelectedEndDate] = useState(mToday);

  // Load additional data
  useEffect(() => {
    getUsers(dispatch);
  }, [dispatch]);

  // Load required chart data
  useEffect(() => {
    const startTime = moment(selectedStartDate).startOf("day").format();
    const endTime = moment(selectedEndDate).endOf("day").format();
    getPerformanceCrew(dispatch, startTime, endTime);
  }, [dispatch, selectedStartDate, selectedEndDate]);

  const handleChangeDateRange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  return (
    <div className="leaderboard">
      <div className="leaderboard-container">
        <div className="leaderboard-toolbar">
          <div>
            <h2>{t("crew_leaderboard")}</h2>
          </div>
          <div className="metrics-filters">
            <div>
              <RangePicker
                today={mToday}
                airportTimezone={airportTimezone}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                minDate={mMinDate}
                maxDate={mToday}
                maxRange={180}
                onChange={(startValue, endValue) => {
                  handleChangeDateRange(startValue, endValue);
                }}
              />
            </div>
          </div>
        </div>
        <div className="leaderboard-content">
          <CrewLeaderboardChart chartData={performanceCrew} users={users} />
        </div>
      </div>
    </div>
  );
}

export default CrewLeaderboard;
