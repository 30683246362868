import { useEffect, useRef, useState } from "react";
import { DEFAULT_TIMEZONE, MAP_ASSET_CATEGORIES } from "../../constants";
import {
  getFlightInfoForTurnaroundSummary,
  getPositionByRegistration,
} from "../../flightUtils";
import {
  MAPINFO_MARKER_TYPE,
  MAPINFO_STATUS,
  getPositionByTrackerCode,
  getVehicleInfoForMap,
  isFilteredOnMap,
  // isWithinAirportBounds,
} from "../../mapUtils";
import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import { getUserInfo } from "../../userUtils";
import {
  isEmptyList,
  isNullOrUndefined,
  isSearchQueryMatch,
  sortByDateField,
  sortByField,
} from "../../utils";
import CrewInfoModule from "./CrewInfoModule";
import FlightInfoModule from "./FlightInfoModule";
import TurnaroundInfoModule from "./TurnaroundInfoModule";
import VehicleInfoModule from "./VehicleInfoModule";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import moment from "moment-timezone";
import { searchCrewShifts } from "../../crewShiftsApi";

function MapOverlayPanel(props) {
  const {
    airport,
    turnarounds,
    flights,
    panelMode,
    searchQuery,
    users,
    positions,
    showMapInfo,
    vehicles,
    selectedMarker,
    mapFilters,
    pinMarker,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { crewShifts } = mainContext;
  const [currentPanelMode, setCurrentPanelMode] = useState(null);
  let turnaroundInfoList = [];
  let generalTurnaroundList = [];
  let inboundFlights = [];
  let outboundFlights = [];
  let inServiceCrewList = [];
  let availableCrewList = [];
  let inServiceVehiclesList = [];
  let mainVehiclesList = [];

  const airportCode = !isNullOrUndefined(airport) ? airport.iata : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const searchTerm = searchQuery?.searchTerm;
  if (panelMode === MAP_ASSET_CATEGORIES.TURNAROUNDS.value) {
    turnaroundInfoList = turnarounds?.map((item) =>
      getTurnaroundInfoForTurnaroundSummary(item)
    );

    if (!isNullOrUndefined(turnaroundInfoList)) {
      for (let i = 0; i < turnaroundInfoList.length; i++) {
        const turnaroundInfo = turnaroundInfoList[i];
        // const isFiltered = isFilteredOnMap(
        //   MAPINFO_MARKER_TYPE.AIRCRAFT,
        //   turnaroundInfo,
        //   mapFilters
        // );

        // Show tiles only for active turnarounds
        const isFiltered = !turnaroundInfo.isActive;
        const matchesSearchQuery = isSearchQueryMatch(
          searchTerm,
          turnaroundInfo?.combinedFlightName
        );
        if (!isFiltered && matchesSearchQuery) {
          generalTurnaroundList.push(turnaroundInfo);
        }
      }
      sortByDateField(generalTurnaroundList, "takeoff");
    }
  }

  if (panelMode === MAP_ASSET_CATEGORIES.FLIGHTS.value) {
    const flightInfoList = [];
    if (!isNullOrUndefined(flights)) {
      for (let i = 0; i < flights.length; i++) {
        const flight = flights[i];
        const flightInfo = getFlightInfoForTurnaroundSummary(
          flight,
          airportCode,
          getPositionByRegistration(positions, flight.registration),
          airportTimezone
        );
        const isFiltered = isFilteredOnMap(
          MAPINFO_MARKER_TYPE.AIRCRAFT,
          flightInfo,
          mapFilters,
          searchQuery
        );
        const matchesSearchQuery = isSearchQueryMatch(
          searchTerm,
          flightInfo?.flightName
        );
        if (!isFiltered && matchesSearchQuery) {
          flightInfoList.push(flightInfo);
        }
      }
    }

    if (!isNullOrUndefined(flightInfoList)) {
      for (let i = 0; i < flightInfoList.length; i++) {
        const flightInfo = flightInfoList[i];
        if (flightInfo.isInbound) {
          inboundFlights.push(flightInfo);
        } else if (flightInfo.isOutbound) {
          outboundFlights.push(flightInfo);
        }
      }
      sortByDateField(inboundFlights, "landing");
      sortByDateField(outboundFlights, "takeoff");
    }
  }

  if (panelMode === MAP_ASSET_CATEGORIES.CREW.value) {
    const userInfoList = [];
    if (!isNullOrUndefined(users)) {
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        const crewShiftsForUser = !isEmptyList(crewShifts)
          ? crewShifts.filter((item) => item.userUuid === user.uuid)
          : null;
        const userInfo = getUserInfo(
          user,
          turnarounds,
          positions,
          crewShiftsForUser
        );
        const isFiltered = isFilteredOnMap(
          MAPINFO_MARKER_TYPE.USER,
          userInfo,
          mapFilters,
          searchQuery
        );
        const matchesSearchQuery = isSearchQueryMatch(
          searchTerm,
          userInfo?.fullName
        );
        if (!isFiltered && matchesSearchQuery) {
          userInfoList.push(userInfo);
        }
      }
    }

    if (!isNullOrUndefined(userInfoList)) {
      for (let i = 0; i < userInfoList.length; i++) {
        const userInfo = userInfoList[i];
        if (!isNullOrUndefined(userInfo.activeTurnaroundInfo)) {
          inServiceCrewList.push(userInfo);
        } else {
          availableCrewList.push(userInfo);
        }
      }
      sortByField(inServiceCrewList, "fullName");
      sortByField(availableCrewList, "fullName");
    }
  }

  if (panelMode === MAP_ASSET_CATEGORIES.VEHICLES.value) {
    const vehicleInfoList = [];
    if (!isNullOrUndefined(vehicles)) {
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i];
        const vehicleInfo = getVehicleInfoForMap(
          vehicle,
          getPositionByTrackerCode(positions, vehicle.trackerCode),
          turnarounds
        );
        const isFiltered = isFilteredOnMap(
          MAPINFO_MARKER_TYPE.VEHICLE,
          vehicleInfo,
          mapFilters,
          searchQuery
        );
        const matchesSearchQuery = isSearchQueryMatch(
          searchTerm,
          vehicleInfo?.name
        );
        if (!isFiltered && matchesSearchQuery) {
          vehicleInfoList.push(vehicleInfo);
        }
      }
    }

    if (!isNullOrUndefined(vehicleInfoList)) {
      for (let i = 0; i < vehicleInfoList.length; i++) {
        const vehicleInfo = vehicleInfoList[i];
        // TBD: Display only vehicles in bounds of airport (station)
        // const shouldInclude =
        //   !isNullOrUndefined(vehicleInfo.position) &&
        //   isWithinAirportBounds(airport, vehicleInfo.position);

        // Check for position of vehicle, don't show if we can't locate it
        const hasPosition =
          !isNullOrUndefined(vehicleInfo?.mapInfo?.position?.longitude) &&
          !isNullOrUndefined(vehicleInfo?.mapInfo?.position?.latitude);

        if (hasPosition) {
          if (vehicleInfo.mapInfo.status === MAPINFO_STATUS.IN_PROGRESS) {
            inServiceVehiclesList.push(vehicleInfo);
          } else {
            mainVehiclesList.push(vehicleInfo);
          }
        }
      }
      sortByField(inServiceVehiclesList, "searchableName");
      sortByField(mainVehiclesList, "searchableName");
    }
  }

  // Reset scrolling when switching
  const panelContentRef = useRef(null);
  useEffect(() => {
    if (
      isNullOrUndefined(panelContentRef.current) ||
      isNullOrUndefined(currentPanelMode === panelMode) ||
      !isNullOrUndefined(selectedMarker)
    ) {
      return () => {};
    }
    panelContentRef.current.scrollTo(0, 0);
    setCurrentPanelMode(panelMode);
  }, [panelMode, currentPanelMode, selectedMarker]);

  useEffect(() => {
    if (panelMode === MAP_ASSET_CATEGORIES.CREW.value) {
      // Fetch crewShifts from yesterday to end of today
      const mToday = moment().tz(airportTimezone).startOf("day");
      const startTime = moment(mToday).add(-1, "day").toDate().toISOString();
      const endTime = moment(mToday).endOf("day").toDate().toISOString();
      searchCrewShifts(dispatch, startTime, endTime);
    }
  }, [dispatch, panelMode, airportTimezone]);

  const hasInServiceAndAvailableCrew =
    inServiceCrewList.length > 0 && availableCrewList.length > 0;

  return (
    <div className="overlay-panel">
      <div className="overlay-panel-container">
        <div className="overlay-panel-content" ref={panelContentRef}>
          {generalTurnaroundList &&
            generalTurnaroundList.map((turnaroundInfo) => (
              <TurnaroundInfoModule
                key={`turnaround-${turnaroundInfo.uuid}`}
                turnaroundInfo={turnaroundInfo}
              />
            ))}
          {inboundFlights && inboundFlights.length > 0 && (
            <div className="overlay-section-label" key={"arrival-label"}>
              {t("arrivals")}
            </div>
          )}
          {inboundFlights &&
            inboundFlights.map((flightInfo) => (
              <FlightInfoModule
                key={`flight-${flightInfo.uuid}`}
                flightInfo={flightInfo}
                showMapInfo={showMapInfo}
              />
            ))}
          {outboundFlights && outboundFlights.length > 0 && (
            <div className="overlay-section-label" key={"departure-label"}>
              {t("departures")}
            </div>
          )}
          {outboundFlights &&
            outboundFlights.map((flightInfo) => (
              <FlightInfoModule
                key={`flight-${flightInfo.uuid}`}
                flightInfo={flightInfo}
              />
            ))}
          {panelMode === MAP_ASSET_CATEGORIES.CREW.value && (
            <>
              {hasInServiceAndAvailableCrew && (
                <div className="overlay-section-label" key={"departure-label"}>
                  {t("in_service_crew_members")} ({inServiceCrewList.length})
                </div>
              )}
              {inServiceCrewList &&
                inServiceCrewList.map((info) => (
                  <CrewInfoModule
                    key={`crew-${info.uuid}`}
                    user
                    info={info}
                    airportTimezone={airportTimezone}
                    isSelected={isSelected(selectedMarker, info.uuid)}
                  />
                ))}
              {hasInServiceAndAvailableCrew && (
                <div className="overlay-section-label" key={"arrival-label"}>
                  {t("available_crew_members")} ({availableCrewList.length})
                </div>
              )}
              {availableCrewList &&
                availableCrewList.map((info) => (
                  <CrewInfoModule
                    key={`crew-${info.uuid}`}
                    info={info}
                    airportTimezone={airportTimezone}
                    isSelected={isSelected(selectedMarker, info.uuid)}
                  />
                ))}
            </>
          )}
          {panelMode === MAP_ASSET_CATEGORIES.VEHICLES.value && (
            <>
              <div className="overlay-section-label">
                {t("in_service_vehicles")} ({inServiceVehiclesList.length})
              </div>
              {inServiceVehiclesList &&
                inServiceVehiclesList.map((vehicleInfo) => (
                  <VehicleInfoModule
                    key={vehicleInfo.uuid}
                    vehicleInfo={vehicleInfo}
                    isSelected={isSelected(
                      selectedMarker,
                      vehicleInfo?.mapInfo?.position?.trackerCode
                    )}
                    pinMarker={pinMarker}
                  />
                ))}
              <div className="overlay-section-label">
                {t("idle_vehicles")} ({mainVehiclesList.length})
              </div>
              {mainVehiclesList &&
                mainVehiclesList.map((vehicleInfo) => (
                  <VehicleInfoModule
                    key={vehicleInfo.uuid}
                    vehicleInfo={vehicleInfo}
                    isSelected={isSelected(
                      selectedMarker,
                      vehicleInfo?.mapInfo?.position?.trackerCode
                    )}
                    pinMarker={pinMarker}
                  />
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function isSelected(selectedMarker, value) {
  if (isNullOrUndefined(selectedMarker)) return false;
  const idValue =
    selectedMarker.mapInfo.markerType === MAPINFO_MARKER_TYPE.USER
      ? selectedMarker.uuid
      : selectedMarker.mapInfo.markerType === MAPINFO_MARKER_TYPE.VEHICLE
      ? selectedMarker.trackerCode
      : null;
  return idValue === value;
}

export default MapOverlayPanel;
