import { useTranslation } from "react-i18next";
import { GroundVehicleStatus } from "../../gseUtils";
import {
  getByFieldValue,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainDispatchContext } from "../../MainContext";
import {
  ResourceActions,
  userHasResourceAction,
} from "../../resourceActionsUtils";

function ResourcesWidget(props) {
  const { vehicles, users, positions, currentUser } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useMainDispatchContext();

  const hasEditAdminGse = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminGse
  );

  if (isNullOrUndefined(vehicles) || isNullOrUndefined(users)) return null;

  const resourceStatus = {
    gseOnline: 0,
    gseOffline: 0,
    crewOnline: 0, // On airfield
    crewOffline: 0, // Not on airfield
  };
  if (!isNullOrUndefined(vehicles)) {
    // check all gse records for deployed vehicles
    for (let i = 0; i < vehicles.length; i++) {
      const vehicle = vehicles[i];
      if (vehicle.statusId === GroundVehicleStatus.Available) {
        resourceStatus.gseOnline++;
      } else {
        resourceStatus.gseOffline++;
      }
    }
  }
  if (!isNullOrUndefined(users)) {
    // check all gse records for deployed vehicles
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      const userPosition = !isNullOrUndefined(positions?.users)
        ? getByFieldValue(positions?.users, "uuid", user.uuid)
        : null;
      if (!isNullOrUndefined(userPosition)) {
        resourceStatus.crewOnline++;
      } else {
        resourceStatus.crewOffline++;
      }
    }
  }
  return (
    <div className="stats">
      <div
        className={`stat online${hasEditAdminGse ? " clickable" : ""}`}
        onClick={() => {
          if (hasEditAdminGse) {
            navigate("/vehicles");
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_GSE);
          }
        }}
      >
        <h3>{resourceStatus.gseOnline}</h3>
        <span>{t("online_vehicles")}</span>
      </div>
      <div
        className={`stat offline${hasEditAdminGse ? " clickable" : ""}`}
        onClick={() => {
          if (hasEditAdminGse) {
            navigate("/vehicles");
            logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.NAV_GSE);
          }
        }}
      >
        <h3>{resourceStatus.gseOffline}</h3>
        <span>{t("offline_vehicles")}</span>
      </div>
    </div>
  );
}

export default ResourcesWidget;
