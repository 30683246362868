import {
  getByFieldValue,
  getTimezoneFromUser,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "./utils";
import moment from "moment-timezone";

// mDay should be start of the day
export function hasCrewShiftsForDate(user, crewShifts, mDay) {
  if (
    isNullOrUndefined(user) ||
    isEmptyList(crewShifts) ||
    isNullOrUndefined(mDay)
  ) {
    return false;
  }
  const timezone = getTimezoneFromUser(user);
  for (let i = 0; i < crewShifts.length; i++) {
    const crewShiftToCheck = crewShifts[i];
    if (crewShiftToCheck.userUuid === user.uuid) {
      const mStartTime = moment(crewShiftToCheck.startTime)
        .tz(timezone)
        .startOf("day");
      if (mDay.toDate().toISOString() === mStartTime.toDate().toISOString()) {
        // user has a shift on this day
        return true;
      }
    }
  }
  return false;
}

export function getCrewShiftsForDate(user, crewShifts, mDay) {
  if (
    isNullOrUndefined(user) ||
    isEmptyList(crewShifts) ||
    isNullOrUndefined(mDay)
  ) {
    return false;
  }
  const timezone = getTimezoneFromUser(user);
  const crewShiftsForDate = [];
  for (let i = 0; i < crewShifts.length; i++) {
    const crewShiftToCheck = crewShifts[i];
    if (crewShiftToCheck.userUuid === user.uuid) {
      const mStartTime = moment(crewShiftToCheck.startTime)
        .tz(timezone)
        .startOf("day");
      if (mDay.toDate().toISOString() === mStartTime.toDate().toISOString()) {
        // user has a shift on this day
        crewShiftsForDate.push(crewShiftToCheck);
      }
    }
  }
  sortByField(crewShiftsForDate, "startTime");
  return crewShiftsForDate;
}

// Returns current shift and next shift for today
export function getRelativeCrewShifts(user, crewShifts) {
  if (isEmptyList(crewShifts)) {
    return null;
  }
  const timezone = getTimezoneFromUser(user);
  const relativeShifts = {};
  const mNow = moment().tz(timezone);
  for (let i = 0; i < crewShifts.length; i++) {
    const crewShiftToCheck = crewShifts[i];
    if (crewShiftToCheck.userUuid === user.uuid) {
      const mStartTime = moment(crewShiftToCheck.startTime).tz(timezone);
      const mEndTime = moment(crewShiftToCheck.endTime).tz(timezone);
      if (mStartTime.isBefore(mNow) && mEndTime.isAfter(mNow)) {
        // Current shift
        relativeShifts.currentShift = crewShiftToCheck;
      } else if (mStartTime.isAfter(mNow)) {
        if (isNullOrUndefined(relativeShifts.nextShift)) {
          relativeShifts.nextShift = crewShiftToCheck;
        } else if (
          mStartTime.isBefore(
            moment(relativeShifts.nextShift.startTime).tz(timezone)
          )
        ) {
          // Earlier next shift
          relativeShifts.nextShift = crewShiftToCheck;
        }
      }
    }
  }
  return relativeShifts;
}

// Checks if the specified user is On-shift and/or Assigned
// shiftContext should include the
// 1. rosterContext: shift and assignment information
// 2. taskInfo - the operation to be fulfilled
export function getCrewAvailability(userInfo, shiftContext) {
  if (isNullOrUndefined(userInfo) || isNullOrUndefined(shiftContext)) {
    return null;
  }
  const crewAvailability = {
    userUuid: userInfo.uuid,
    userShift: null, // can be null (not on shift)
    assignedTask: null, // can be null (no conflicting shift)
  };
  const { taskInfo, rosterInfo, timezone } = shiftContext;
  const rosterEntry = !isEmptyList(rosterInfo?.users)
    ? getByFieldValue(rosterInfo.users, "uuid", userInfo.uuid)
    : null;

  const taskStart = taskInfo.expectedStartTime;
  const taskEnd = taskInfo.expectedEndTime;
  const taskTime = { start: taskStart, end: taskEnd };
  if (!isEmptyList(rosterEntry?.shifts)) {
    // Find a shift which can fully encompass the task start/end
    let shift = null;
    for (
      let i = 0;
      isNullOrUndefined(shift) && i < rosterEntry.shifts.length;
      i++
    ) {
      shift = rosterEntry.shifts[i];
      const shiftTime = { start: shift.startTime, end: shift.endTime };
      if (isTimeWithin(taskTime, shiftTime, timezone)) {
        // User is on shift
        crewAvailability.userShift = shift;
      }
    }
  }

  if (!isEmptyList(rosterEntry?.tasks)) {
    // Find a task which will overlap with the task start/end
    for (
      let i = 0;
      isNullOrUndefined(crewAvailability.assignedTask) &&
      i < rosterEntry.tasks.length;
      i++
    ) {
      const taskToCheck = rosterEntry.tasks[i];
      const taskToCheckTime = {
        start: taskToCheck.expectedStartTime,
        end: taskToCheck.expectedEndTime,
      };

      const isOverlapping = isTimeOverlapping(
        taskTime,
        taskToCheckTime,
        timezone
      );
      if (isOverlapping) {
        // Tasks overlap
        // TBD: should we consider if the task is already done?
        crewAvailability.assignedTask = taskToCheck;
      }
    }
  }

  return crewAvailability;
}

// t1 is entirely in t2
export function isTimeWithin(t1, t2, timezone) {
  const m1 = {
    start: moment(t1.start).tz(timezone),
    end: moment(t1.end).tz(timezone),
  };
  const m2 = {
    start: moment(t2.start).tz(timezone),
    end: moment(t2.end).tz(timezone),
  };

  return (
    (m2.start.isSame(m1.start) || m2.start.isBefore(m1.start)) &&
    (m2.end.isSame(m1.end) || m2.end.isAfter(m1.end))
  );
}

// t1 overlaps with any part of t2
export function isTimeOverlapping(t1, t2, timezone) {
  const m1 = {
    start: moment(t1.start).tz(timezone),
    end: moment(t1.end).tz(timezone),
  };
  const m2 = {
    start: moment(t2.start).tz(timezone),
    end: moment(t2.end).tz(timezone),
  };
  return !(m2.end.isBefore(m1.start) || m2.start.isAfter(m1.end));
}
