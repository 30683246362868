import { isEmptyList, logAnalyticsEvent } from "../../utils";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { useTranslation } from "react-i18next";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { ANALYTICS_EVENTS } from "../../constants";

function TurnaroundLeads(props) {
  const { item, onClick } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();

  const mainContext = useMainContext();
  const { currentUser } = mainContext;

  const isReadonly =
    !userHasAnyResourceAction(currentUser, ResourceActions.EditTurnProfile) ||
    item.isCompleted;

  return (
    <div
      className={`turnaround-leads${!isReadonly ? " active" : ""}`}
      onClick={(e) => {
        if (!isReadonly) {
          onClick();
          logAnalyticsEvent(
            dispatch,
            ANALYTICS_EVENTS.TURNAROUNDS_ASSIGN_LEADS
          );
        }
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div>
        <div className="turnaround-leads-rows">
          {isEmptyList(item?.leads) && (
            <div className="turnaround-leads-row empty">{t("na")}</div>
          )}
          {!isEmptyList(item?.leads) &&
            item.leads.map((userInfo) => (
              <div className="turnaround-leads-row" key={userInfo.uuid}>
                {userInfo.fullName}
              </div>
            ))}
        </div>

        <div className="turnaround-leads-actions">
          <div>
            <div className="action-icon">
              <Pencil />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundLeads;
