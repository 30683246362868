import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getTurnaroundTemplates, patchTurnaroundTemplate } from "../../api";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { getProfilePatchRequest } from "../../turnaroundProfileUtils";
import { deepCopy, isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import TemplateEditor from "./TemplateEditor";
import TemplateRow from "./TemplateRow";
import "./styles.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import TemplateAircraftSelector from "./TemplateAircraftSelector";
import { ANALYTICS_EVENTS, SEARCH_QUERY_PLACEHOLDERS } from "../../constants";

function Templates() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundTemplates,
    currentUser,
    showAircraftTypeSelector,
    searchQuery,
  } = mainContext;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isTemplateSaving, setIsTemplateSaving] = useState(false);

  // TODO: Remove this feature
  const showCreateTemplate = false;

  const hasEditTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnTemplate
  );

  useEffect(() => {
    getTurnaroundTemplates(dispatch);
    return () => {
      // Clear this state when exiting
      dispatch({
        type: "setShowAircraftTypeSelector",
        value: false,
      });
    };
  }, [dispatch]);

  async function handleSaveTemplate(templateToSave) {
    if (isNullOrUndefined(templateToSave)) return;
    if (isTemplateSaving) return;

    setIsTemplateSaving(true);
    // The act of saving should also approve/dispatch
    let result = null;
    // getProfilePatchRequest also works for Template
    const profilePatchRequest = getProfilePatchRequest(
      selectedTemplate,
      templateToSave,
      true
    );
    result = await patchTurnaroundTemplate(dispatch, profilePatchRequest);

    if (result) {
      const airline = selectedTemplate?.airline
        ? selectedTemplate?.airline.name
        : "-";
      const airport = selectedTemplate?.airport
        ? selectedTemplate?.airport.iata
        : "-";
      const aircraft = selectedTemplate?.aircraftType
        ? selectedTemplate?.aircraftType.name
        : "-";
      const templateName = `${aircraft} - ${airline} ${airport}`;
      getTurnaroundTemplates(dispatch);
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", { name: templateName }),
      });
    }
    // reset
    setSelectedTemplate(null);
    setIsTemplateSaving(false);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_SAVE);
  }

  const templatesList = deepCopy(turnaroundTemplates);
  const templatesByAircraftType = {};
  if (!isNullOrUndefined(templatesList)) {
    for (let i = 0; i < templatesList.length; i++) {
      const template = templatesList[i];
      const aircraftType = template?.aircraftType.name;
      if (aircraftType in templatesByAircraftType) {
        templatesByAircraftType[aircraftType] =
          templatesByAircraftType[aircraftType] + 1;
      } else {
        templatesByAircraftType[aircraftType] = 1;
      }
    }
  }
  function handleCreateTemplate() {
    dispatch({
      type: "setShowAircraftTypeSelector",
      value: true,
    });

    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_AIRCRAFT_MODELS,
      },
    });
  }

  return (
    <div className="templates">
      <div className="templates-container">
        {showAircraftTypeSelector && (
          <TemplateAircraftSelector
            onClose={() => {
              dispatch({
                type: "setShowAircraftTypeSelector",
                value: false,
              });
            }}
            templatesByAircraftType={templatesByAircraftType}
            searchQuery={searchQuery}
          />
        )}
        {isNullOrUndefined(selectedTemplate) && !showAircraftTypeSelector && (
          <div className="templates-content">
            <div className="templates-content-header">
              <div>
                <h3>{t("turnaround_templates")}</h3>
              </div>
              <div>
                {showCreateTemplate && (
                  <div>
                    <button onClick={handleCreateTemplate}>
                      <Plus style={{ marginRight: "4px" }} />
                      {t("create_new")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="listing">
              <div className="listing-header">
                <div>{t("aircraft")}</div>
                <div>{t("airline")}</div>
                <div>{t("airport")}</div>
                <div>{t("action")}</div>
              </div>
              {templatesList.length === 0 && (
                <div className="listing-empty">
                  <div>{t("turnaround_templates_empty")}</div>
                </div>
              )}
              {templatesList &&
                templatesList.map((template, idx) => (
                  <TemplateRow
                    key={template.uuid}
                    template={template}
                    showEdit={hasEditTurnTemplate}
                    showView={false}
                    onSelect={(template) => {
                      setSelectedTemplate(template);
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUND_TEMPLATE_EDIT
                      );
                    }}
                  />
                ))}
            </div>
          </div>
        )}
        {!isNullOrUndefined(selectedTemplate) && !showAircraftTypeSelector && (
          <div>
            <TemplateEditor
              template={selectedTemplate}
              onBack={() => {
                setSelectedTemplate(null);
              }}
              onSave={(templateToSave) => {
                handleSaveTemplate(templateToSave);
              }}
              isTemplateSaving={isTemplateSaving}
              isReadOnly={!hasEditTurnTemplate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Templates;
