import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import { t } from "i18next";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { useState } from "react";
import { useMainDispatchContext } from "../../MainContext";
import { getTurnaroundDetails, patchTurnaroundProfile } from "../../api";
import { getCrewRecordsForCrewAssignments } from "../../turnaroundUtils";
import TurnaroundModuleCrewSlot from "./TurnaroundModuleCrewSlot";
import { ANALYTICS_EVENTS } from "../../constants";

function TurnaroundModuleCrewRequirement(props) {
  const {
    turnaroundInfo,
    selectedOperation,
    requirement,
    users,
    positions,
    turnarounds,
    timezone,
    isProfileEditable,
    isDisabled,
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useMainDispatchContext();

  const crewRecords = getCrewRecordsForCrewAssignments(
    selectedOperation?.userRecords,
    requirement?.crewAssignments
  );

  const slots = [];
  for (let i = 0; i < requirement.quantity; i++) {
    const crewAssignment =
      i < requirement?.crewAssignments.length
        ? requirement?.crewAssignments[i]
        : null;
    const serviceRecordForUser =
      !isEmptyList(crewRecords) && !isNullOrUndefined(crewAssignment)
        ? getByFieldValue(crewRecords, "user.uuid", crewAssignment.userUuid)
        : null;

    // Each slot may have a crewAssignment which may also have a serviceRecord
    slots.push({
      crewAssignment: crewAssignment,
      serviceRecord: serviceRecordForUser,
    });
  }

  async function handleUpdateCrewQuantity(amount, userUuid) {
    if (isProcessing) return;
    setIsProcessing(true);
    let requirementToUpdate = {
      uuid: requirement.uuid,
      quantity: requirement.quantity + amount,
    };
    if (amount < 0 && !isNullOrUndefined(userUuid)) {
      requirementToUpdate.userUuidsToRemove = [userUuid];
    }
    const patchRequest = {
      uuid: turnaroundInfo.originalProfile.uuid,
      requirements: [requirementToUpdate],
    };

    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    if (result) {
      await getTurnaroundDetails(dispatch, turnaroundInfo);
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: turnaroundInfo.combinedFlightName,
        }),
      });
    }
    setIsProcessing(false);
  }

  return (
    <div>
      <div className="operation-requirement">
        <div>
          <div>
            <div className="operation-requirement-info">
              <div>{requirement.certification.name}</div>
              <div>
                <div className="quantity-values">
                  <div className="quantity-value">
                    <div>{requirement.quantity}</div>
                    <div>{t("required")}</div>
                  </div>
                  <div className="quantity-value">
                    <div>{requirement?.crewAssignments?.length}</div>
                    <div>{t("assigned")}</div>
                  </div>
                  <div className="quantity-value">
                    <div>{crewRecords?.length}</div>
                    <div>{t("arrived")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="operation-requirement-slots">
            <div>
              {!isEmptyList(slots) &&
                slots.map((slot, idx) => (
                  <TurnaroundModuleCrewSlot
                    key={`${requirement.certification.uuid}-${idx}`}
                    crewAssignment={slot.crewAssignment}
                    serviceRecord={slot.serviceRecord}
                    users={users}
                    positions={positions}
                    turnarounds={turnarounds}
                    timezone={timezone}
                    onRemove={(userUuid) => {
                      handleUpdateCrewQuantity(-1, userUuid);
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUNDS_DETAILS_REMOVE_CREW
                      );
                    }}
                    isProfileEditable={isProfileEditable}
                    isDisabled={isDisabled}
                  />
                ))}
            </div>
            <div className="actions">
              <div>
                {isProfileEditable && (
                  <button
                    className="secondary"
                    onClick={() => {
                      handleUpdateCrewQuantity(1);
                      logAnalyticsEvent(
                        dispatch,
                        ANALYTICS_EVENTS.TURNAROUNDS_DETAILS_ADD_CREW
                      );
                    }}
                    disabled={isDisabled}
                  >
                    <Plus /> {t("add_crew_member")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundModuleCrewRequirement;
