import { getEnvName } from "../api";

export function checkSupportedBrowser() {
  const agent = window.navigator.userAgent.toLowerCase();
  return isSupportedBrowser(agent, window, getEnvName());
}
export function isSupportedBrowser(agent, windowObj, envName) {
  let isSupported = false;
  switch (true) {
    case agent.indexOf("edge") > -1:
      isSupported = false;
      break;
    case agent.indexOf("edg") > -1:
      isSupported = true;
      break;
    case agent.indexOf("chrome") > -1 && !!windowObj?.chrome:
      isSupported = true;
      break;
    case agent.indexOf("safari") > -1:
      isSupported = false;
      break;
    case agent.indexOf("firefox") > -1 && envName !== "prod":
      isSupported = true;
      break;
    default:
      isSupported = false;
      break;
  }
  return isSupported;
}
