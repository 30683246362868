import { Modal } from "@mui/material";
import { isNullOrUndefined, logAnalyticsEvent } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import RangePicker from "../ButtonDatePicker/RangePicker";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ANALYTICS_EVENTS, DEFAULT_TIMEZONE, MIN_DATE } from "../../constants";
import moment from "moment-timezone";
import { getCrewShiftsExport } from "../../api";

function CrewShiftExportModal(props) {
  const { isOpen, handleClose } = props;
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, crewShiftsExport } = mainContext;
  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const mToday = moment().tz(airportTimezone).startOf("minute");
  const mThirtyDays = moment(mToday).add(30, "day").tz(airportTimezone);
  const [selectedStartDate, setSelectedStartDate] = useState(mToday);
  const [selectedEndDate, setSelectedEndDate] = useState(mThirtyDays);

  function handleChangeDateRange(startDate, endDate) {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }

  function handleExport() {
    setIsProcessing(true);
    const startTime = moment(selectedStartDate).startOf("day").format();
    const endTime = moment(selectedEndDate).endOf("day").format();
    getCrewShiftsExport(dispatch, startTime, endTime);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.CREW_SHIFTS_EXPORT_CSV);
  }

  useEffect(() => {
    if (!isNullOrUndefined(crewShiftsExport?.downloadUrl)) {
      window.open(crewShiftsExport?.downloadUrl, "_blank");
      setIsProcessing(false);
      handleClose();
      dispatch({
        type: "setCrewShiftsExport",
        value: null,
      });
    }
  }, [dispatch, crewShiftsExport, handleClose]);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className="modal crew-shift-export">
          <div>
            <div className="modal-header">
              <div>
                <h2>{t("crew_shift_export")}</h2>
              </div>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>

          <div className="modal-body">
            <div className="crew-shift-export-content">
              <div>
                <span>{t("crew_shift_export_note")}</span>
              </div>
              <div>
                <RangePicker
                  today={mToday}
                  airportTimezone={airportTimezone}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  minDate={mMinDate}
                  maxDate={mToday}
                  maxRange={180}
                  onChange={(startValue, endValue) => {
                    handleChangeDateRange(startValue, endValue);
                  }}
                  isForwardDates={true}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button onClick={handleExport} disabled={isProcessing}>
                  {`${isProcessing ? t("processing") : t("export")}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CrewShiftExportModal;
