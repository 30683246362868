import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { isBlank, isNullOrUndefined } from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { GroundVehicleStatus } from "../../gseUtils";
import { selectInputFix } from "../../styleUtils";
import { useTranslation } from "react-i18next";
import ButtonSwitch from "../ButtonSwitch";

function VehicleModal(props) {
  const {
    onCloseHandler,
    onSaveHandler,
    selectedItem,
    gseTypes,
    gsesSaving,
    trackers,
  } = props;
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [trackerId, setTrackerId] = useState("");
  const [gseType, setGseType] = useState("");
  const [statusId, setStatusId] = useState(1);
  const trackersList = [...trackers].sort((a, b) => {
    const val1 = a.code;
    const val2 = b.code;
    if (val1 === val2) return 0;
    return val1 < val2 ? -1 : 1;
  });

  useEffect(() => {
    if (!isNullOrUndefined(selectedItem)) {
      setName(selectedItem.name);
      setTrackerId(selectedItem.trackerCode);
      if (!isNullOrUndefined(selectedItem.gseType?.uuid)) {
        setGseType(selectedItem.gseType.uuid);
      }
      setStatusId(selectedItem.statusId);
    }
  }, [selectedItem]);

  const isExisting = !isNullOrUndefined(selectedItem)
    ? !isBlank(selectedItem.uuid)
    : null;

  const handleClose = () => {
    // reset fields
    setName("");
    setTrackerId("");
    setGseType("");
    setStatusId(1);

    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };

  const handleSave = () => {
    if (!isNullOrUndefined(onSaveHandler)) {
      onSaveHandler(
        {
          uuid: selectedItem.uuid,
          name: name,
          trackerCode: !isBlank(trackerId) ? trackerId : null,
          gseTypeUuid: gseType,
          statusId: statusId,
        },
        isExisting
      );
    }
  };

  const isValid = () => {
    const nameValid = !isBlank(name);
    const gseTypeValid = !isBlank(gseType);
    return nameValid && gseTypeValid;
  };

  const noTrackersAvailable =
    trackersList.length === 0 && isBlank(selectedItem?.trackerCode);

  return (
    <div>
      <Modal
        open={!isNullOrUndefined(selectedItem)}
        onClose={handleClose}
        className="vehicle-modal"
      >
        <div className="modal">
          <div className="modal-header">
            <h2>{isExisting ? t("edit_gse") : t("add_gse")}</h2>
            <div className="button-icon" onClick={handleClose}>
              <Cross />
            </div>
          </div>
          <div className="modal-body">
            <div
              className="modal-container"
              style={{
                gridTemplateRows: "repeat(4, auto) 1fr",
              }}
            >
              <div className="modal-field-row">
                <label>{t("gse_type")}</label>
                <select
                  value={gseType}
                  onChange={(e) => {
                    setGseType(e.target.value);
                  }}
                  style={selectInputFix}
                >
                  {isBlank(gseType) && <option key="" value=""></option>}
                  {!isNullOrUndefined(gseTypes) &&
                    gseTypes.map((type) => (
                      <option key={type.uuid} value={type.uuid}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="modal-field-row">
                <label>{t("gse_name")}</label>
                <input
                  type="text"
                  placeholder={t("gse_name")}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="modal-field-row">
                <label>{t("tracker_id_lower_case")}</label>
                {noTrackersAvailable && (
                  <span className="not-available">
                    {t("no_available_trackers")}
                  </span>
                )}
                {!noTrackersAvailable && (
                  <select
                    value={trackerId}
                    placeholder={t("select_tracker")}
                    onChange={(e) => {
                      setTrackerId(e.target.value);
                    }}
                    style={selectInputFix}
                  >
                    <option key="_blank" value=""></option>
                    {!isBlank(selectedItem?.trackerCode) && (
                      <option
                        key={selectedItem.trackerCode}
                        value={selectedItem.trackerCode}
                      >
                        {selectedItem.trackerCode}
                      </option>
                    )}
                    {trackersList &&
                      trackersList.map((item) => (
                        <option key={item.code} value={item.code}>
                          {item.code}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              <div
                className={`modal-field-row toggle-input${
                  GroundVehicleStatus.Offline === statusId ? " offline" : ""
                }`}
              >
                <div>
                  <div>
                    <label>{t("status")}</label>
                  </div>
                  <div>
                    <ButtonSwitch
                      selectedOption={
                        GroundVehicleStatus.Available === statusId
                          ? "online"
                          : "offline"
                      }
                      labelValuePairs={[
                        {
                          value: "online",
                        },
                        {
                          value: "offline",
                        },
                      ]}
                      onChange={(mode) => {
                        if (mode === "offline") {
                          setStatusId(GroundVehicleStatus.Offline);
                        } else {
                          setStatusId(GroundVehicleStatus.Available);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button className="secondary" onClick={handleClose}>
                {t("cancel")}
              </button>
            </div>
            <div>
              <button onClick={handleSave} disabled={!isValid() || gsesSaving}>
                {isExisting ? t("save") : t("add_non_cap")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VehicleModal;
