import { useEffect, useMemo, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  deepCopy,
  isNullOrUndefined,
  logAnalyticsEvent,
  sortByField,
} from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";

import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getTurnaroundDetailsForPatch, saveTurnaroundPatch } from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import { getTurnaroundInfo } from "../../turnaroundUtils";
import GateSelector from "../GateSelector";

const DEFAULT_VALUE = {
  remarks: "",
  assignedStand: null,
};

export default function TurnaroundEditModal(props) {
  const { onCloseHandler, onSaved, selectedTurnaroundUuid } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();

  const { turnaroundDetailsForPatch, airportDetail } = mainContext;

  const [isProcessing, setIsProcessing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [turnaroundEditRequest, setTurnaroundEditRequest] =
    useState(DEFAULT_VALUE);

  const gatesList = useMemo(() => {
    const list = !isNullOrUndefined(airportDetail?.stands)
      ? airportDetail.stands
      : [];
    sortByField(list, "name");
    return list;
  }, [airportDetail]);

  // Load the turnaround details
  useEffect(() => {
    if (!isNullOrUndefined(selectedTurnaroundUuid)) {
      getTurnaroundDetailsForPatch(dispatch, { uuid: selectedTurnaroundUuid });
      setIsProcessing(true);
    }
  }, [dispatch, selectedTurnaroundUuid]);

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetailsForPatch)) {
      setTurnaroundEditRequest({
        turnaroundInfo: getTurnaroundInfo(turnaroundDetailsForPatch),
        uuid: turnaroundDetailsForPatch.uuid,
        remarks: !isNullOrUndefined(turnaroundDetailsForPatch.remarks)
          ? turnaroundDetailsForPatch.remarks
          : "",
        assignedStand: !isNullOrUndefined(
          turnaroundDetailsForPatch?.assignedStand?.uuid
        )
          ? turnaroundDetailsForPatch.assignedStand.uuid
          : null,
      });
      setIsProcessing(false);
    }
  }, [turnaroundDetailsForPatch]);

  const handleClose = () => {
    setTurnaroundEditRequest(DEFAULT_VALUE);
    setHasChanges(false);
    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };

  async function handleSave() {
    setIsProcessing(true);
    let patchRequest = {
      uuid: turnaroundEditRequest.uuid,
    };
    if (turnaroundEditRequest.remarks !== turnaroundDetailsForPatch.remarks) {
      patchRequest.remarks = turnaroundEditRequest.remarks;
    }
    if (
      turnaroundEditRequest.assignedStand !==
      turnaroundDetailsForPatch.assignedStandUuid
    ) {
      patchRequest.assignedStandUuid = turnaroundEditRequest.assignedStand;
    }
    const result = await saveTurnaroundPatch(dispatch, patchRequest);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: turnaroundEditRequest?.turnaroundInfo?.combinedFlightName,
        }),
      });
      if (!isNullOrUndefined(onSaved)) {
        onSaved();
      }
    }

    setIsProcessing(false);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_SAVE_REMARKS);
    handleClose();
  }

  return (
    <div>
      <Modal
        open={!isNullOrUndefined(selectedTurnaroundUuid)}
        onClose={handleClose}
      >
        <div className="modal">
          <div>
            <div className="modal-header">
              <h2>
                {!isNullOrUndefined(turnaroundEditRequest?.turnaroundInfo) && (
                  <>{`${t("turnaround")}: ${
                    turnaroundEditRequest?.turnaroundInfo?.combinedFlightName
                  }`}</>
                )}
              </h2>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="turnaround-edit-modal-content">
                {isProcessing && isNullOrUndefined(turnaroundEditRequest) && (
                  <div>
                    <LoadingIndicator />
                  </div>
                )}
                {!isNullOrUndefined(turnaroundEditRequest) && (
                  <>
                    <div className="turnaround-edit-modal-row">
                      <div>
                        <label>{t("remarks")}</label>
                      </div>
                      <div>
                        <textarea
                          rows={6}
                          width="100%"
                          value={turnaroundEditRequest.remarks}
                          onChange={(e) => {
                            const updatedValue = deepCopy(
                              turnaroundEditRequest
                            );
                            updatedValue.remarks = e.target.value;
                            setTurnaroundEditRequest(updatedValue);
                            setHasChanges(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="turnaround-edit-modal-row">
                      <div>
                        <label>{t("assigned_stand")}</label>
                      </div>
                      <div>
                        <GateSelector
                          onSelect={(value) => {
                            const updatedValue = deepCopy(
                              turnaroundEditRequest
                            );
                            updatedValue.assignedStand = value?.uuid;
                            setTurnaroundEditRequest(updatedValue);
                            setHasChanges(true);
                          }}
                          gates={gatesList}
                          value={turnaroundEditRequest.assignedStand}
                          allowClear={false}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  disabled={isProcessing || !hasChanges}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
