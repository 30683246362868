import {
  formatHoursMins,
  formatTime,
  isBlank,
  isNullOrUndefined,
  zeroPad,
} from "../../utils";
import { TurnaroundStatus } from "../../turnaroundUtils";
import TurnaroundLeads from "../TurnaroundsModule/TurnaroundLeads";
import { EMPTY_VALUE } from "../../constants";
import TurnaroundRemarks from "../TurnaroundsModule/TurnaroundRemarks";
import { ReactComponent as Completed } from "../../assets/operations/status-completed.svg";

export const TurnaroundsModuleColumns = {
  TurnaroundName: {
    id: "turnaround_name",
    displayName: "turnaround",
    formatter: (item) => {
      return (
        <div className={`turnaround-flights`}>
          <div className="flight-status">
            <div
              className={`flight-status-icon status-${item.statusCode}${
                item.statusCode === TurnaroundStatus.TurnaroundInProgress
                  ? " pulseOpacity"
                  : ""
              }`}
            >
              {item.statusCode === TurnaroundStatus.TurnaroundComplete && (
                <Completed />
              )}
            </div>
          </div>
          <div className="flight-names">
            <div>
              {item?.inboundFlightName}
              {!isBlank(item?.outboundFlightName) && <>&nbsp;-&nbsp;</>}
            </div>
            {!isBlank(item?.outboundFlightName) && (
              <div>{item.outboundFlightName}</div>
            )}
          </div>
        </div>
      );
    },
    sortValue: (item) => `${item.combinedFlightName}`,
  },
  Registration: {
    id: "registration",
    displayName: "registration",
    formatter: (item) => {
      return item?.registration;
    },
    sortValue: (item) => `${item.registration}`,
  },
  Landing: {
    id: "landing",
    displayName: "landing_non_cap",
    formatter: (item) => {
      return formatTime(item?.landing, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.landing) ? new Date(item?.landing) : null,
  },
  GateIn: {
    id: "gate_in",
    displayName: "gate_in_non_cap",
    formatter: (item) => {
      return formatTime(item?.gateIn, item?.timezone);
    },
    sortValue: (item) => {
      // Status
      let statusCode = "";
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        statusCode = 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        statusCode = 3;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundPlanned) {
        statusCode = 1;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundNotConfigured) {
        statusCode = 2;
      }

      // Gate-in time
      const dateVal = !isNullOrUndefined(item?.gateIn)
        ? new Date(item?.gateIn).getTime()
        : 0;
      const dateValPadded = zeroPad(dateVal);
      return `${statusCode}_${dateValPadded}`;
    },
  },
  GateOut: {
    id: "gate_out",
    displayName: "gate_out_non_cap",
    formatter: (item) => {
      return formatTime(item?.gateOut, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateOut) ? new Date(item?.gateOut) : null,
  },
  Takeoff: {
    id: "takeoff",
    displayName: "takeoff_non_cap",
    formatter: (item) => {
      return formatTime(item?.takeoff, item?.timezone);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.takeoff) ? new Date(item?.takeoff) : null,
  },
  TotalGate: {
    id: "total_gate_time",
    displayName: "total_gate_time",
    formatter: (item) => {
      return formatHoursMins(item?.gateTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.gateTimeMinutes) ? item?.gateTimeMinutes : 0,
  },
  TotalGround: {
    id: "total_ground_time",
    displayName: "total_ground_time",
    formatter: (item) => {
      return formatHoursMins(item?.groundTimeMinutes);
    },
    sortValue: (item) =>
      !isNullOrUndefined(item?.groundTimeMinutes) ? item?.groundTimeMinutes : 0,
  },
  Status: {
    id: "status",
    displayName: "status",
    formatter: (item) => {
      let statusClass = `status-${item.statusCode}`;
      const statusName = item?.statusDisplayName;
      return (
        <div
          className={`datatable-turnaround-status ${statusClass}`}
          data-status={statusName}
        >
          {statusName}
        </div>
      );
    },
    sortValue: (item) => {
      if (item.statusCode === TurnaroundStatus.TurnaroundInProgress) {
        return 0;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundComplete) {
        return 3;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundPlanned) {
        return 1;
      } else if (item.statusCode === TurnaroundStatus.TurnaroundNotConfigured) {
        return 2;
      }
    },
  },
  Leads: {
    id: "lead",
    displayName: "lead",
    formatter: (item) => {
      return (
        <div>
          <TurnaroundLeads
            item={item}
            isReadonly={true}
            onClick={(e) => {
              // e.preventDefault();
              // e.stopPropagation();
            }}
          />
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  Stand: {
    id: "stand",
    displayName: "stand_non_cap",
    formatter: (item) => {
      const assignedStandName = item.assignedStandName;
      const snappedStandName = item.snappedStandName;
      const displayStand = !isNullOrUndefined(snappedStandName)
        ? snappedStandName
        : !isNullOrUndefined(assignedStandName)
        ? assignedStandName
        : EMPTY_VALUE;
      return (
        <div>
          <div>{displayStand}</div>
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  Remarks: {
    id: "remarks",
    displayName: "remarks",
    formatter: (item) => {
      return (
        <div>
          <TurnaroundRemarks
            item={item}
            isReadonly={true}
            onClick={(e) => {
              // e.preventDefault();
              // e.stopPropagation();
            }}
          />
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
  OriginDestination: {
    id: "origin_destination",
    displayName: "origin_destination",
    formatter: (item) => {
      const origin = !isBlank(item?.inboundFlight?.originAirportIata)
        ? item?.inboundFlight?.originAirportIata
        : null;
      const destination = !isBlank(item?.outboundFlight?.destAirportIata)
        ? item?.outboundFlight?.destAirportIata
        : null;
      return (
        <div className="origin-destination">
          <div>
            {origin}
            {!isBlank(origin) && !isBlank(destination) && <>&nbsp;-&nbsp;</>}
            {destination}
          </div>
        </div>
      );
    },
    sortValue: (item) => item.combinedFlightName,
  },
};
