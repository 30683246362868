import { useTranslation } from "react-i18next";
import { isEmptyList } from "../../utils";
import Checkbox from "../Checkbox";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import { ReactComponent as Close } from "../../assets/close-cross.svg";
import { useEffect, useMemo, useRef, useState } from "react";

function CrewFilter(props) {
  const { t } = useTranslation();
  const { userGroups, crewFilters, onChange, itemList } = props;
  const [isOpened, setIsOpened] = useState(false);

  const componentRef = useRef(null);
  // Handle clicking away
  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setIsOpened(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  const hasFilters = !isEmptyList(crewFilters.userGroups);

  const countsByType = useMemo(() => {
    const counts = {};
    if (!isEmptyList(itemList)) {
      itemList.forEach((item) => {
        if (item.userGroupUuid in counts) {
          counts[item.userGroupUuid] = counts[item.userGroupUuid] + 1;
        } else {
          counts[item.userGroupUuid] = 1;
        }
      });
    }
    return counts;
  }, [itemList]);

  const userGroupsToRender = [];
  if (!isEmptyList(userGroups)) {
    userGroups.forEach((item) => {
      const countValue =
        item.uuid in countsByType ? countsByType[item.uuid] : 0;
      if (countValue > 0) {
        userGroupsToRender.push(item);
      }
    });
  }

  return (
    <div className={`crew-filter${isOpened ? " open" : ""}`} ref={componentRef}>
      <button
        className={`${hasFilters ? "primary" : "secondary"}`}
        onClick={() => {
          setIsOpened((prev) => !prev);
        }}
      >
        <Filter /> {t("filter")}
        {hasFilters && (
          <>
            {` (${crewFilters.userGroups.length})`}
            &nbsp;
            <Close
              onClick={(e) => {
                onChange("_none");
                setIsOpened(false);
                e?.stopPropagation();
              }}
            />
          </>
        )}
      </button>
      <div className="crew-filter-menu fadeIn drop-shadow">
        <div className="crew-filter-menu-content">
          <div className="crew-filter-menu-title">
            <div>{t("user_groups")}</div>
          </div>
          {!isEmptyList(userGroupsToRender) &&
            userGroupsToRender.map((userGroup) => {
              const isSelected = crewFilters.userGroups.includes(
                userGroup.uuid
              );
              const countValue =
                userGroup.uuid in countsByType
                  ? countsByType[userGroup.uuid]
                  : 0;
              return (
                <div
                  className={`crew-filter-menu-row${
                    isSelected ? " selected" : ""
                  }`}
                  onClick={() => {
                    onChange(userGroup.uuid);
                  }}
                  key={userGroup.uuid}
                >
                  <div>{`${userGroup.name} (${countValue})`}</div>
                  <div>
                    <Checkbox
                      isChecked={isSelected}
                      changeHandler={(e) => {
                        onChange(userGroup.uuid);
                        e?.stopPropagation();
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default CrewFilter;
