import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  deepCopy,
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";

import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getDepartments,
  getTurnaroundDetailsForPatch,
  patchTurnaroundProfile,
} from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import { getTurnaroundInfo } from "../../turnaroundUtils";
import SelectCrew from "../SelectCrew";
import {
  ResourceActions,
  userHasAnyResourceAction,
} from "../../resourceActionsUtils";
import { getDepartmentRoster } from "../../tasksOverviewApi";
import {
  useTasksOverviewContext,
  useTasksOverviewDispatchContext,
} from "../../TasksOverviewContext";
export default function AssignLeadsModal(props) {
  const { onCloseHandler, onSaved, selectedTurnaroundUuid, timezone } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const tasksOverviewDispatch = useTasksOverviewDispatchContext();
  const tasksOverviewContext = useTasksOverviewContext();
  const { departmentRoster } = tasksOverviewContext;

  const {
    currentUser,
    turnaroundDetailsForPatch,
    users,
    turnaroundsSummary,
    positions,
    departments,
  } = mainContext;

  const [isProcessing, setIsProcessing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [assignmentsToEdit, setAssignmentsToEdit] = useState(null);
  const [selectedTurnaroundInfo, setSelectedTurnaroundInfo] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);

  useEffect(() => {
    // Load supporting data
    getDepartments(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // Load supporting data
    if (!isEmptyList(departments)) {
      // TODO: HACK
      const departmentName = "PAX Oficiales";
      let dept = getByFieldValue(departments, "name", departmentName);
      if (isNullOrUndefined(dept)) {
        dept = departments[0];
      }
      getDepartmentRoster(tasksOverviewDispatch, dept.uuid);
    }
  }, [tasksOverviewDispatch, departments]);

  // Load the turnaround details
  useEffect(() => {
    if (!isNullOrUndefined(selectedTurnaroundUuid)) {
      getTurnaroundDetailsForPatch(dispatch, { uuid: selectedTurnaroundUuid });
      setIsProcessing(true);

      dispatch({
        type: "setIsRefreshPaused",
        value: true,
      });
    }
  }, [dispatch, selectedTurnaroundUuid]);

  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetailsForPatch)) {
      // Find the leads operation within the profile
      const turnaroundOperations =
        turnaroundDetailsForPatch?.profile?.turnaroundOperations;
      const leadOperation = !isEmptyList(turnaroundOperations)
        ? getByFieldValue(turnaroundOperations, "type.isLeadOperation", true)
        : null;
      const leadOperationRequirement = !isEmptyList(
        leadOperation?.turnaroundRequirements
      )
        ? leadOperation.turnaroundRequirements[0]
        : null;
      // TODO: We assume the lead operation has only one main requirement

      if (!isNullOrUndefined(leadOperationRequirement)) {
        const turnaroundInfo = getTurnaroundInfo(turnaroundDetailsForPatch);
        setSelectedTurnaroundInfo(turnaroundInfo);

        // Create slots
        const slots = [];
        for (let i = 0; i < leadOperationRequirement.quantity; i++) {
          // Pair each slot with crewAssignment if there are enough existing
          const crewAssignment =
            i < leadOperationRequirement?.crewAssignments?.length
              ? leadOperationRequirement?.crewAssignments[i]
              : null;
          const assignedUser =
            !isEmptyList(users) && !isNullOrUndefined(crewAssignment)
              ? getByFieldValue(users, "uuid", crewAssignment.userUuid)
              : null;

          slots.push({
            slotId: `slot-${i}`,
            assignedUser: assignedUser,
            assignments: leadOperationRequirement?.crewAssignments,
            requirement: leadOperationRequirement,
          });
        }

        setAssignmentsToEdit({
          uuid: turnaroundDetailsForPatch?.profile?.uuid,
          leadOperation: leadOperation,
          leadOperationRequirement: leadOperationRequirement,
          slots: slots,
        });
      }
      setTimeout(() => {
        setIsProcessing(false);
      }, 250);
    }
  }, [turnaroundDetailsForPatch, users]);

  const handleClose = () => {
    setAssignmentsToEdit(null);
    setSelectedTurnaroundInfo(null);
    setHasChanges(false);
    setIsProcessing(false);
    setSelectedSlotId(null);

    // Un-pause
    dispatch({
      type: "setIsRefreshPaused",
      value: false,
    });
    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };

  async function handleSave() {
    setIsProcessing(true);
    // Creat the patch request
    let patchRequest = {
      uuid: assignmentsToEdit.uuid,
      requirements: [],
    };
    // check what assignments need to be removed/added
    const userUuidsToAdd = [];
    const userUuidsToRemove = [];
    const originalAssignments = [];
    const updatedAssignments = [];

    // Assignments edited by user
    if (!isEmptyList(assignmentsToEdit.slots)) {
      assignmentsToEdit.slots.forEach((slot) => {
        if (!isNullOrUndefined(slot.assignedUser)) {
          updatedAssignments.push(slot.assignedUser.uuid);
        }
      });
    }
    // Original assignments
    if (
      !isEmptyList(assignmentsToEdit?.leadOperationRequirement?.crewAssignments)
    ) {
      assignmentsToEdit?.leadOperationRequirement?.crewAssignments.forEach(
        (crewAssignment) => {
          originalAssignments.push(crewAssignment.userUuid);
        }
      );
    }
    originalAssignments.forEach((userUuid) => {
      if (!updatedAssignments.includes(userUuid)) {
        // Assigned user was removed
        userUuidsToRemove.push(userUuid);
      }
    });
    updatedAssignments.forEach((userUuid) => {
      if (!originalAssignments.includes(userUuid)) {
        // Assigned user was added
        userUuidsToAdd.push(userUuid);
      }
    });
    let requirement = {
      uuid: assignmentsToEdit?.leadOperationRequirement.uuid,
    };
    if (!isEmptyList(userUuidsToAdd)) {
      requirement.userUuidsToAdd = userUuidsToAdd;
    }
    if (!isEmptyList(userUuidsToRemove)) {
      requirement.userUuidsToRemove = userUuidsToRemove;
    }
    patchRequest.requirements.push(requirement);

    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", {
          name: selectedTurnaroundInfo?.combinedFlightName,
        }),
      });
      if (!isNullOrUndefined(onSaved)) {
        onSaved();
      }
    }

    setIsProcessing(false);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.TURNAROUNDS_SAVE_LEADS);
    handleClose();
  }

  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );

  const isReadonly = selectedTurnaroundInfo?.isCompleted;

  function updateSlots(slot, selectedUser) {
    const assignmentsToUpdate = deepCopy(assignmentsToEdit);
    const slotToUpdate = getByFieldValue(
      assignmentsToUpdate.slots,
      "slotId",
      slot.slotId
    );
    slotToUpdate.assignedUser = selectedUser;
    setAssignmentsToEdit(assignmentsToUpdate);
    setSelectedSlotId(null);
    setHasChanges(true);
  }

  //TODO: need to get shifts
  // In order to get shifts, need to load roster and need to know what department to load

  const shiftContext = {
    taskInfo: {
      expectedStartTime: assignmentsToEdit?.leadOperation?.expectedStartTime,
      expectedEndTime: assignmentsToEdit?.leadOperation?.expectedEndTime,
    },
    rosterInfo: departmentRoster,
    timezone: timezone,
  };

  return (
    <div>
      <Modal
        open={!isNullOrUndefined(selectedTurnaroundUuid)}
        onClose={handleClose}
      >
        <div className="modal">
          <div>
            <div className="modal-header">
              <h2>
                {!isNullOrUndefined(selectedTurnaroundInfo) && (
                  <>{`${t("turnaround")}: ${
                    selectedTurnaroundInfo?.combinedFlightName
                  }`}</>
                )}
              </h2>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              <div className="turnaround-edit-modal-content">
                {isProcessing && (
                  <div>
                    <LoadingIndicator />
                  </div>
                )}
                {!isProcessing && !isNullOrUndefined(assignmentsToEdit) && (
                  <>
                    <div className="turnaround-edit-modal-row">
                      <div>
                        <label>
                          {
                            assignmentsToEdit?.leadOperationRequirement
                              ?.certification?.name
                          }
                        </label>
                      </div>
                      <div className="turnaround-edit-slots">
                        {isNullOrUndefined(assignmentsToEdit.leadOperation) && (
                          <div>{t("na")}</div>
                        )}
                        {!isEmptyList(assignmentsToEdit?.slots) &&
                          assignmentsToEdit.slots.map((slot) => (
                            <div className="" key={slot.slotId}>
                              <SelectCrew
                                isActive={selectedSlotId === slot.slotId}
                                isProfileEditable={hasEditTurnProfile}
                                isEditable={!isReadonly}
                                isSelected={false}
                                onClick={() => {
                                  setSelectedSlotId(slot.slotId);
                                }}
                                onSelect={(selectedUser) => {
                                  updateSlots(slot, selectedUser.user);
                                  logAnalyticsEvent(
                                    dispatch,
                                    ANALYTICS_EVENTS.TURNAROUNDS_ADD_ASSIGNMENT
                                  );
                                }}
                                onRemove={() => {
                                  updateSlots(slot, null);
                                  logAnalyticsEvent(
                                    dispatch,
                                    ANALYTICS_EVENTS.TURNAROUNDS_REMOVE_ASSIGNMENT
                                  );
                                }}
                                onCancel={() => {
                                  setSelectedSlotId(null);
                                }}
                                requirement={slot.requirement}
                                assignments={slot.assignments}
                                assignedUser={slot.assignedUser}
                                users={users}
                                positions={positions}
                                turnarounds={turnaroundsSummary}
                                shiftContext={shiftContext}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  disabled={isProcessing || !hasChanges}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
