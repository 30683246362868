import { ReactComponent as Filter } from "../../assets/filter.svg";
import { ReactComponent as Close } from "../../assets/close-cross.svg";
import { useState, useRef, useEffect } from "react";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import MapFilterChoice from "./MapFilterChoice";
import { useTranslation } from "react-i18next";
import { deepCopy, logAnalyticsEvent } from "../../utils";
import { ANALYTICS_EVENTS } from "../../constants";
import { useMainDispatchContext } from "../../MainContext";

function MapFilterButton(props) {
  const {
    mapFilters,
    onChange,
    vehicleTypes,
    disabled,
    showMapLabels,
    onMapLabelsChange,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const componentRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);
  const handleAssetsChange = (value) => {
    const newFilters = deepCopy(mapFilters);
    if (value === MAPINFO_MARKER_TYPE.ALL) {
      const indexOfAll = newFilters.markerTypes.indexOf(
        MAPINFO_MARKER_TYPE.ALL
      );
      if (indexOfAll > -1) {
        newFilters.markerTypes.splice(indexOfAll, 1);
      } else {
        newFilters.markerTypes = [MAPINFO_MARKER_TYPE.ALL];
      }
    } else {
      const indexOfOption = newFilters.markerTypes.indexOf(value);
      if (indexOfOption > -1) {
        newFilters.markerTypes.splice(indexOfOption, 1);
      } else {
        newFilters.markerTypes.push(value);
      }
      const indexOfAll = newFilters.markerTypes.indexOf(
        MAPINFO_MARKER_TYPE.ALL
      );
      if (indexOfAll > -1) {
        newFilters.markerTypes.splice(indexOfAll, 1);
      }
    }
    if (newFilters.markerTypes.length === 0) {
      newFilters.markerTypes.push(MAPINFO_MARKER_TYPE.ALL);
    }
    if (!newFilters.markerTypes.includes(MAPINFO_MARKER_TYPE.VEHICLE)) {
      newFilters.vehicleTypes = [MAPINFO_MARKER_TYPE.ALL];
    }
    updateFilters(newFilters);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.MAP_CHANGE_FILTERS);
  };

  const handleVehicleChange = (value) => {
    const newFilters = deepCopy(mapFilters);
    if (value === MAPINFO_MARKER_TYPE.ALL) {
      const indexOfAll = newFilters.vehicleTypes.indexOf(
        MAPINFO_MARKER_TYPE.ALL
      );
      if (indexOfAll > -1) {
        newFilters.vehicleTypes.splice(indexOfAll, 1);
      } else {
        newFilters.vehicleTypes = [MAPINFO_MARKER_TYPE.ALL];
      }
    } else {
      const indexOfOption = newFilters.vehicleTypes.indexOf(value);
      if (indexOfOption > -1) {
        newFilters.vehicleTypes.splice(indexOfOption, 1);
      } else {
        newFilters.vehicleTypes.push(value);
      }
      const indexOfAll = newFilters.vehicleTypes.indexOf(
        MAPINFO_MARKER_TYPE.ALL
      );
      if (indexOfAll > -1) {
        newFilters.vehicleTypes.splice(indexOfAll, 1);
      }
    }
    if (newFilters.vehicleTypes.length === 0) {
      newFilters.vehicleTypes.push(MAPINFO_MARKER_TYPE.ALL);
    }
    updateFilters(newFilters);
    logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.MAP_CHANGE_FILTERS);
  };

  const updateFilters = (newFilters) => {
    // This function will return the filters via callback
    onChange(newFilters);
  };

  const showVehiclesFilter = mapFilters.markerTypes.includes(
    MAPINFO_MARKER_TYPE.VEHICLE
  );
  // Filter buttons are highlighted when there are filters other than ALL
  const hasAssetFilter =
    mapFilters.markerTypes.length > 0 &&
    !mapFilters.markerTypes.includes(MAPINFO_MARKER_TYPE.ALL);

  const hasVehicleFilter =
    mapFilters.vehicleTypes.length > 0 &&
    !mapFilters.vehicleTypes.includes(MAPINFO_MARKER_TYPE.ALL);

  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setShowMenu(false);
        setShowSecondaryMenu(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [componentRef]);

  const filterButtonClass = `${hasAssetFilter ? "primary" : "menu"} ${
    showMenu ? " open" : ""
  }`;
  const filterVehiclesButtonClass = `${hasVehicleFilter ? "primary" : "menu"} ${
    showSecondaryMenu ? " open" : ""
  }`;

  // Allow filtering by main types (except aircraft)
  const showFilterButton = true;

  return (
    <div className="filter-button" ref={componentRef}>
      <div
        className={`filter-button-actions ${
          showVehiclesFilter ? "secondary" : ""
        }`}
      >
        {showFilterButton && (
          <div className="filter-button-container">
            <button
              className={filterButtonClass}
              onClick={() => {
                setShowMenu((prev) => !prev);
                setShowSecondaryMenu(false);
              }}
              disabled={disabled}
            >
              {!hasAssetFilter && <Filter />}
              {hasAssetFilter && (
                <Close
                  onClick={() => {
                    handleAssetsChange(MAPINFO_MARKER_TYPE.ALL);
                  }}
                />
              )}
              <span>{t("filter_resources")}</span>
            </button>
            {showMenu && (
              <div className="filter-button-menu">
                <div className="filter-menu fadeIn">
                  <div className="filter-menu-container">
                    <div className="filter-menu-content">
                      <MapFilterChoice
                        onChange={(value) => {
                          handleAssetsChange(value);
                        }}
                        selected={mapFilters.markerTypes.includes(
                          MAPINFO_MARKER_TYPE.ALL
                        )}
                        value={MAPINFO_MARKER_TYPE.ALL}
                        display={t("all_resources")}
                      />
                      <MapFilterChoice
                        onChange={(value) => {
                          handleAssetsChange(value);
                        }}
                        selected={mapFilters.markerTypes.includes(
                          MAPINFO_MARKER_TYPE.VEHICLE
                        )}
                        value={MAPINFO_MARKER_TYPE.VEHICLE}
                        display={t("vehicles")}
                      />
                      <MapFilterChoice
                        onChange={(value) => {
                          handleAssetsChange(value);
                        }}
                        selected={mapFilters.markerTypes.includes(
                          MAPINFO_MARKER_TYPE.USER
                        )}
                        value={MAPINFO_MARKER_TYPE.USER}
                        display={t("crew")}
                      />
                      <hr />
                      <MapFilterChoice
                        onChange={onMapLabelsChange}
                        selected={showMapLabels}
                        value={showMapLabels}
                        display={t("show_labels")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {showVehiclesFilter && (
          <div className="filter-button-container">
            <button
              className={filterVehiclesButtonClass}
              onClick={() => {
                setShowMenu(false);
                setShowSecondaryMenu((prev) => !prev);
              }}
              disabled={disabled}
            >
              {!hasVehicleFilter && <Filter />}
              {hasVehicleFilter && (
                <Close
                  onClick={() => {
                    handleVehicleChange(MAPINFO_MARKER_TYPE.ALL);
                  }}
                />
              )}
              <span>{t("filter_vehicles")}</span>
            </button>
            {showSecondaryMenu && (
              <div className="filter-button-menu">
                <div className="filter-menu fadeIn">
                  <div className="filter-menu-container">
                    <div className="filter-menu-content">
                      <MapFilterChoice
                        key="all-vehicles"
                        onChange={(value) => {
                          handleVehicleChange(value);
                        }}
                        selected={mapFilters.vehicleTypes.includes(
                          MAPINFO_MARKER_TYPE.ALL
                        )}
                        value={MAPINFO_MARKER_TYPE.ALL}
                        display={t("all_vehicles")}
                      />
                      {vehicleTypes &&
                        vehicleTypes.map((item) => (
                          <MapFilterChoice
                            key={`filter-${item.uuid}`}
                            onChange={(value) => {
                              handleVehicleChange(value);
                            }}
                            selected={mapFilters.vehicleTypes.includes(
                              item.uuid
                            )}
                            value={item.uuid}
                            display={item.name}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MapFilterButton;
