import {
  getApiByResourceName,
  handleBadResponse,
  handleException,
} from "./api";
import { debugLog } from "./logging";

export async function searchCrewShifts(dispatch, startTime, endTime) {
  return await getApiByResourceName(
    dispatch,
    "crew-shifts-search",
    "CrewShifts",
    "post",
    {
      startTime: startTime,
      endTime: endTime,
    }
  );
}

export async function getFileUpload(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "file-upload",
    "FileUpload",
    "get"
  );
}

export async function uploadCrewShiftsFile(
  dispatch,
  uploadUrl,
  fileData,
  fileType
) {
  try {
    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": fileType,
        "Cache-Control": "no-cache",
      },
      body: fileData,
    });
    await response.blob();
    if (!response.ok) {
      // Check errors?
      handleBadResponse(response, null, dispatch);
    }
    // File was uploaded successfully
    return true;
  } catch (e) {
    handleException(dispatch, e);
    debugLog(e);
  }
  return false;
}

export async function importPreviewCrewShifts(dispatch, fileUuid) {
  return getApiByResourceName(
    dispatch,
    "crew-shifts-import-preview",
    "CrewShiftsImportPreview",
    "post",
    {
      fileUuid: fileUuid,
    }
  );
}

export async function importCrewShifts(dispatch, fileUuid) {
  return getApiByResourceName(
    dispatch,
    "crew-shifts-import",
    "CrewShiftsImport",
    "post",
    {
      fileUuid: fileUuid,
    }
  );
}

export async function createCrewShift(dispatch, crewShift) {
  return getApiByResourceName(
    dispatch,
    "crew-shifts",
    "CrewShiftsCreate",
    "post",
    crewShift
  );
}

export async function deleteCrewShift(dispatch, crewShift) {
  return getApiByResourceName(
    dispatch,
    "crew-shifts",
    "CrewShiftsDelete",
    "delete",
    crewShift
  );
}

export async function updateCrewShift(dispatch, crewShift) {
  return getApiByResourceName(
    dispatch,
    "crew-shifts",
    "CrewShiftsUpdate",
    "put",
    crewShift
  );
}
