import { useTranslation } from "react-i18next";
import { getTimelineFromTemplate } from "../../templateUtils";
import { getNameKeyForOperationOffsetType } from "../../turnaroundUtils";
import { isBlank, isNullOrUndefined, sortByField } from "../../utils";

function TemplateReview(props, targetBlockTime) {
  const { t } = useTranslation();
  const { template } = props;
  const timelineInfo = getTimelineFromTemplate(template, targetBlockTime);

  const operationsToReview = timelineInfo.operationsToRender.map((item) => {
    const operation = item?.operation;
    const operationInfo = {
      uuid: operation.uuid,
      name: operation?.type.name,
      duration: operation.duration,
      vehicles: [],
      crew: [],
      offsetTypeId: operation.offsetTypeId,
      startTime: t(getNameKeyForOperationOffsetType(operation.offsetTypeId)),
    };

    if (operation.startOffsetMinutes !== 0) {
      operationInfo.startTime = `${operationInfo.startTime} ${
        operation.startOffsetMinutes < 0 ? "-" : "+"
      }${Math.abs(operation.startOffsetMinutes)}`;
    }
    for (let i = 0; i < item?.operation?.turnaroundRequirements.length; i++) {
      const turnaroundRequirement = item?.operation.turnaroundRequirements[i];
      if (!isBlank(turnaroundRequirement.certification.groundVehicleTypeUuid)) {
        operationInfo.vehicles.push({
          name: turnaroundRequirement.certification.name,
          quantity: turnaroundRequirement.quantity,
        });
      } else {
        operationInfo.crew.push({
          name: turnaroundRequirement.certification.name,
          quantity: turnaroundRequirement.quantity,
        });
      }
      sortByField(operationInfo.vehicles, "name");
      sortByField(operationInfo.crew, "name");
    }
    return operationInfo;
  });
  return (
    <div className="template-review">
      <div className="listing">
        <div className="listing-header">
          <div>{t("operation")}</div>
          <div>{t("start_time")}</div>
          <div>{t("vehicles")}</div>
          <div>{t("crew")}</div>
          <div>{t("duration")}</div>
        </div>
        {operationsToReview &&
          operationsToReview.map((item) => (
            <div
              className={`listing-row offset-type-${item.offsetTypeId}`}
              key={item.uuid}
            >
              <div>{item.name}</div>
              <div>{item.startTime}</div>
              <div>
                {item.vehicles &&
                  item.vehicles.map((vehicle, idx) => (
                    <div
                      key={idx}
                    >{`${vehicle.name}: ${vehicle.quantity}`}</div>
                  ))}
              </div>
              <div>
                {item.crew &&
                  item.crew.map((requirement, idx) => (
                    <div
                      key={idx}
                    >{`${requirement.name}: ${requirement.quantity}`}</div>
                  ))}
              </div>
              <div>
                {isNullOrUndefined(item.duration) ? "" : `${item.duration}m`}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TemplateReview;
