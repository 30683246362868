import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import { ReactComponent as Close } from "../../assets/close-cross.svg";
import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";

function LiveRosterFilterButton(props) {
  const { t } = useTranslation();

  const {
    rosterInfo,
    turnarounds,
    liveRosterFilters,
    onChange,
    onClear,
    toggleShowConfirmed,
    toggleShowOffShift,
  } = props;
  const [isOpened, setIsOpened] = useState(false);
  const componentRef = useRef(null);

  // Handle clicking away
  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setIsOpened(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const [turnaroundsToRender, turnaroundsTaskDetails] = useMemo(() => {
    const turnaroundInfoList = [];
    const turnaroundTaskDetailList = [];
    if (!isNullOrUndefined(rosterInfo)) {
      const turnaroundUuidList = [];
      if (!isEmptyList(rosterInfo.unassignedTasks)) {
        rosterInfo.unassignedTasks.forEach((element) => {
          const turnaroundUuid = element.turnaroundUuid;
          if (!turnaroundUuidList.includes(turnaroundUuid)) {
            turnaroundUuidList.push(turnaroundUuid);
            turnaroundTaskDetailList.push({
              turnaroundUuid: turnaroundUuid,
              assigned: 0,
              unassigned: 0,
            });
          }
          const turnaroundTaskDetail = getByFieldValue(
            turnaroundTaskDetailList,
            "turnaroundUuid",
            turnaroundUuid
          );

          turnaroundTaskDetail.unassigned = turnaroundTaskDetail.unassigned + 1;
        });
      }
      if (!isEmptyList(rosterInfo.users)) {
        rosterInfo.users.forEach((user) => {
          if (!isEmptyList(user.tasks)) {
            user.tasks.forEach((task) => {
              const turnaroundUuid = task.turnaroundUuid;
              if (!turnaroundUuidList.includes(turnaroundUuid)) {
                turnaroundUuidList.push(turnaroundUuid);
                turnaroundTaskDetailList.push({
                  turnaroundUuid: turnaroundUuid,
                  assigned: 0,
                  unassigned: 0,
                });
              }
              const turnaroundTaskDetail = getByFieldValue(
                turnaroundTaskDetailList,
                "turnaroundUuid",
                turnaroundUuid
              );
              turnaroundTaskDetail.assigned = turnaroundTaskDetail.assigned + 1;
            });
          }
        });
      }

      turnaroundUuidList.forEach((turnaroundUuid) => {
        const turnaround = getByFieldValue(turnarounds, "uuid", turnaroundUuid);
        const turnaroundInfo = !isNullOrUndefined(turnaround)
          ? getTurnaroundInfoForTurnaroundSummary(turnaround)
          : null;
        if (!isNullOrUndefined(turnaroundInfo)) {
          turnaroundInfoList.push(turnaroundInfo);
        }
      });
    }

    sortByField(turnaroundInfoList, "combinedFlightName");
    return [turnaroundInfoList, turnaroundTaskDetailList];
  }, [rosterInfo, turnarounds]);

  let filterCount = liveRosterFilters.turnaroundUuids.length;
  if (!liveRosterFilters.showUnconfirmed) {
    filterCount++;
  }
  if (!liveRosterFilters.showOffShift) {
    filterCount++;
  }
  const hasFilters = filterCount > 0;

  return (
    <div
      className={`live-roster-filters${isOpened ? " open" : ""}`}
      ref={componentRef}
    >
      <div>
        <button
          className={`${hasFilters ? "priamry" : "secondary"}`}
          onClick={() => {
            setIsOpened((prev) => !prev);
          }}
        >
          <Filter />
          &nbsp; {t("filter")}
          {hasFilters && (
            <>
              &nbsp;({filterCount}) &nbsp;
              <Close
                onClick={(e) => {
                  onClear();
                  setIsOpened(false);
                  e.stopPropagation();
                }}
              />
            </>
          )}
        </button>
        <div className="live-roster-filter-menu fadeIn drop-shadow">
          <div>
            <div className="live-roster-filter-rows">
              {/** Filter for Off-Shift */}
              <div
                className={`live-roster-filter-row${
                  !liveRosterFilters.showOffShift ? " selected" : ""
                }`}
              >
                <div>
                  <div
                    onClick={() => {
                      toggleShowOffShift();
                    }}
                  >
                    <div>{t("on_shift_only")}</div>
                  </div>
                  <div>
                    <Checkbox
                      changeHandler={(value) => {
                        toggleShowOffShift();
                      }}
                      isChecked={!liveRosterFilters.showOffShift}
                    />
                  </div>
                </div>
              </div>
              {/** Filter for unconfirmed tasks */}
              <div
                className={`live-roster-filter-row${
                  !liveRosterFilters.showUnconfirmed ? " selected" : ""
                }`}
              >
                <div>
                  <div
                    onClick={() => {
                      toggleShowConfirmed();
                    }}
                  >
                    <div>{t("confirmed_tasks_only")}</div>
                  </div>
                  <div>
                    <Checkbox
                      changeHandler={(value) => {
                        toggleShowConfirmed();
                      }}
                      isChecked={!liveRosterFilters.showUnconfirmed}
                    />
                  </div>
                </div>
              </div>
              <div className="live-roster-filter-row-divider">
                <div>
                  <hr />
                </div>
              </div>
              {!isEmptyList(turnaroundsToRender) &&
                turnaroundsToRender.map((turnaroundInfo) => {
                  const turnaroundsTaskDetail = getByFieldValue(
                    turnaroundsTaskDetails,
                    "turnaroundUuid",
                    turnaroundInfo.uuid
                  );

                  const isSelected = liveRosterFilters.turnaroundUuids.includes(
                    turnaroundInfo.uuid
                  );
                  return (
                    <div
                      className={`live-roster-filter-row${
                        isSelected ? " selected" : ""
                      }`}
                      key={turnaroundInfo.uuid}
                    >
                      <div>
                        <div
                          onClick={() => {
                            onChange(turnaroundInfo.uuid);
                          }}
                        >
                          <div>{turnaroundInfo.combinedFlightName}</div>
                          <div>
                            {turnaroundsTaskDetail?.unassigned > 0 && (
                              <span className="danger">
                                {`${turnaroundsTaskDetail?.unassigned} ${t(
                                  "unassigned"
                                )}`}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <Checkbox
                            value={turnaroundInfo.uuid}
                            changeHandler={(value) => {
                              onChange(value);
                            }}
                            isChecked={isSelected}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveRosterFilterButton;
