import CaretDown from "./assets/caret-down.svg";
export const selectInputFix = {
  appearance: "none",
  backgroundImage: `url(${CaretDown})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 8px center",
  outline: "none",
  fontFamily: "Inter",
};

export const selectInputNoCaretFix = {
  appearance: "none",
  outline: "none",
  fontFamily: "Inter",
};

export const tooltipStyles = {
  tooltip: {
    sx: {
      cursor: "default",
      fontSize: "12px",
      fontFamily: "Inter",
      background: "rgba(0,0,0,0.90)",
      color: "#a3a9b6",
      "& .MuiTooltip-arrow": {
        color: "rgba(0,0,0,0.90)",
      },
    },
  },
};
export const tooltipSolidStyles = {
  tooltip: {
    sx: {
      cursor: "default",
      fontSize: "12px",
      fontFamily: "Inter",
      background: "rgba(0,0,0,0.90)",
      textAlign: "center",
      padding: "8px",
      color: "#a3a9b6",
      "& .MuiTooltip-arrow": {
        color: "rgba(0,0,0,0.90)",
      },
    },
  },
};

export const tooltipLightStyles = {
  tooltip: {
    sx: {
      cursor: "default",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Inter",
      background: "rgba(255,255,255,0.5)",
      color: "#a3a9b6",
      padding: "1px",
      "& .tooltip-content": {
        background: "rgba(0,0,0,0.9)",
        borderRadius: "3px",
        padding: "6px 8px",
        maxWidth: "180px",
      },
      "& .tooltip-content-heading": {
        fontSize: "12px",
        fontWeight: "600",
        color: "#ffffff",
      },
      "& .tooltip-content-timestamps": {
        display: "grid",
      },
      "& .tooltip-content-timestamps .in-progress": {
        color: "var(--primary-yellow)",
      },
      "& .tooltip-content-timestamps .completed": {
        color: "var(--primary-green)",
      },
      "& .MuiTooltip-arrow": {
        color: "rgba(255,255,255,0.5)",
      },
    },
  },
};
