import "./styles.css";
import { useEffect, useMemo, useState } from "react";
import {
  getTimezoneFromUser,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import DataTable, { ColumnNames } from "../DataTable";
import { getFlightsSummarySearch } from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import {
  ANALYTICS_EVENTS,
  MIN_DATE,
  SEARCH_QUERY_PLACEHOLDERS,
} from "../../constants";
import { useTranslation } from "react-i18next";
import ButtonDatePicker from "../ButtonDatePicker";
import moment from "moment-timezone";
import { FlightSummaryColumns } from "../DataTable/FlightSummaryColumns";
import FlightsEditModal from "./FlightsEditModal";

function Flights() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    flightsSummarySearch,
    flightsSummarySearchLoading,
    currentUser,
    searchQuery,
  } = mainContext;
  const airportTimezone = getTimezoneFromUser(currentUser);
  const mToday = moment().tz(airportTimezone).startOf("day");

  const [selectedDate, setSelectedDate] = useState(mToday);
  const [selectedFlightForEdit, setSelectedFlightForEdit] = useState(null);

  useEffect(() => {
    const start = moment(selectedDate).toDate().toISOString();
    const end = moment(selectedDate).endOf("day").toDate().toISOString();
    getFlightsSummarySearch(dispatch, start, end);

    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_FLIGHTS,
      },
    });
  }, [dispatch, selectedDate]);

  function refreshFlights() {
    const start = moment(selectedDate).toDate().toISOString();
    const end = moment(selectedDate).endOf("day").toDate().toISOString();
    getFlightsSummarySearch(dispatch, start, end);
  }

  const flightList = useMemo(() => {
    // Need to process the flight data for presentation
    const flightsData = !isNullOrUndefined(flightsSummarySearch)
      ? flightsSummarySearch.map((flightData) => {
          // TODO confirm this, these are our interpretations of the flight aware fields for display here
          const baseAirport = currentUser.airport.iata;
          const isInbound = flightData.destAirportIata === baseAirport;

          return {
            uuid: flightData.uuid,
            flightName: `${flightData.airlineIata} ${flightData.flightNumber}`,
            isInbound: isInbound,
            airportTimezone: airportTimezone,
            origin: flightData.originAirportIata,
            destination: flightData.destAirportIata,
            scheduledIn: flightData.timestamps.scheduledGateIn,
            scheduledOut: flightData.timestamps.scheduledGateOut,
            estimatedIn: flightData.timestamps.estimatedGateIn,
            estimatedOut: flightData.timestamps.estimatedGateOut,
            actualIn: flightData.timestamps.actualGateIn,
            actualOut: flightData.timestamps.actualGateOut,
            registration: flightData.registration,
          };
        })
      : [];
    return flightsData;
  }, [flightsSummarySearch, airportTimezone, currentUser]);

  const hasTurnaroundEditModal = true;
  const rowActions = {
    viewAction: (item) => {
      return hasTurnaroundEditModal ? (
        <div className="flight-row-actions">
          <button
            className="primary"
            onClick={(e) => {
              setSelectedFlightForEdit(item);
              logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.FLIGHTS_EDIT_MODAL);
              e.stopPropagation();
            }}
          >
            {t("edit")}
          </button>
        </div>
      ) : null;
    },
  };

  return (
    <div className="flights">
      <div className="flights-header">
        <div>
          <div>
            <h3>{t("flights")}</h3>
          </div>
        </div>
        <div>
          <div>
            <ButtonDatePicker
              label={
                selectedDate == null ? null : selectedDate.format("MM/DD/YYYY")
              }
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              minDate={moment(MIN_DATE)}
              maxDate={moment(mToday).add(2, "month")}
            />
          </div>
        </div>
      </div>
      <div className="flights-container">
        {flightsSummarySearchLoading && <LoadingIndicator />}
        {!flightsSummarySearchLoading && (
          <DataTable
            dataList={flightList}
            searchQuery={searchQuery}
            columnNames={[
              ColumnNames.FlightName,
              ColumnNames.Registration,
              ColumnNames.Origin,
              ColumnNames.Destination,
              FlightSummaryColumns.ScheduledOut,
              FlightSummaryColumns.EstimatedOut,
              FlightSummaryColumns.ActualOut,
              FlightSummaryColumns.ScheduledIn,
              FlightSummaryColumns.EstimatedIn,
              FlightSummaryColumns.ActualIn,
            ]}
            rowActions={rowActions}
            gridColumns={
              "repeat(2, minmax(0, 180px)) repeat(2, minmax(0,96px)) repeat(5, minmax(0,96px)) 1fr minmax(0,96px)"
            }
          />
        )}
      </div>
      {!isNullOrUndefined(selectedFlightForEdit) && (
        <FlightsEditModal
          onCloseHandler={() => {
            setSelectedFlightForEdit(null);
          }}
          onSaved={() => {
            setSelectedFlightForEdit(null);
            // refresh
            refreshFlights();
          }}
          selectedFlight={selectedFlightForEdit}
          timezone={airportTimezone}
        />
      )}
    </div>
  );
}
export default Flights;
