import "./styles.css";
import { useRef, useState } from "react";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { ReactComponent as AccountAccepted } from "../../assets/account-accepted.svg";
import { ReactComponent as AccountPending } from "../../assets/awaiting.svg";
import UserBadge from "../UserBadge";
import {
  formatDatetime,
  formatHoursMins,
  formatLongDatetime,
  isBlank,
  isNullOrUndefined,
  logAnalyticsEvent,
} from "../../utils";
import AlertBadge from "../AlertBadge";
import Checkbox from "../Checkbox";
import { getTrackerSortValue, GroundVehicleStatus } from "../../gseUtils";
import BatteryPower from "../BatteryPower";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { useNavigate } from "react-router-dom";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import LeaderboardColumns from "./LeaderboardColumns";
import {
  createSortOptions,
  getColumnNameObjectById,
  sortByDataColumn,
} from "./utils";
import { Tooltip, Fade } from "@mui/material";
import { tooltipStyles } from "../../styleUtils";
import { getUserCertificationInfo } from "../../userUtils";
import { useTranslation } from "react-i18next";
import i18next from "../../i18n";
import CrewShiftsOutlook from "../CrewShifts/CrewShiftsOutlook";
import { TurnaroundsModuleColumns } from "./TurnaroundsModuleColumns";
import { ANALYTICS_EVENTS } from "../../constants";

const DASHES = <>--</>;

// Display names are i18n string keys
export const ColumnNames = {
  Name: {
    id: "name",
    displayName: "name",
    formatter: (item) => {
      return <UserBadge user={item} secondaryInfoField="email" />;
    },
    sortValue: (item) => `${item.firstName} ${item.lastName}`,
  },
  Email: {
    id: "email",
    displayName: "email_non_cap",
    formatter: (item) => {
      return (
        <Tooltip
          title={item.email}
          placement="top"
          componentsProps={tooltipStyles}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
          arrow
        >
          <div className="ellipsis-text">{item.email}</div>
        </Tooltip>
      );
    },
    sortValue: (item) => `${item.email}`,
    classNames: "ellipsis-text",
  },
  Confirmed: {
    id: "confirmed",
    displayName: "status",
    formatter: (item) => {
      return (
        <>
          {item.isConfirmed && (
            <Tooltip
              title={i18next.t("confirmed")}
              placement="top"
              componentsProps={tooltipStyles}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              arrow
            >
              <AccountAccepted />
            </Tooltip>
          )}
          {!item.isConfirmed && (
            <Tooltip
              title={i18next.t("awaiting_confirmation")}
              placement="top"
              componentsProps={tooltipStyles}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              arrow
            >
              <AccountPending />
            </Tooltip>
          )}
        </>
      );
    },
    sortValue: (item) => `${item.isConfirmed}`,
    classNames: "centered-text",
  },
  Certifications: {
    id: "certifications",
    displayName: "certifications",
    formatter: (item) => {
      const userCertificationInfo = getUserCertificationInfo(item);
      return (
        <Tooltip
          title={
            <div>
              {item.certifications &&
                item.certifications.map((i) => (
                  <div key={i.name}>{i.name}</div>
                ))}
            </div>
          }
          placement="top"
          componentsProps={tooltipStyles}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
          arrow
        >
          <div>{userCertificationInfo.certNames}</div>
        </Tooltip>
      );
    },
    sortValue: (item) => {
      let certList = [];
      if (!isNullOrUndefined(item.certifications)) {
        certList = item.certifications.map((i) => i.name).sort();
      }
      return certList.length > 0 ? certList.join(", ") : "";
    },
  },
  CurrentShifts: {
    id: "currentShifts",
    displayName: "current_shifts",
    formatter: (item) => {
      return (
        <div>
          <CrewShiftsOutlook user={item} />
        </div>
      );
    },
    sortValue: (item) => {
      return item?.hasCrewShiftForToday ? 0 : 1;
    },
  },
  VehicleName: {
    id: "vehicleName",
    displayName: "gse_name",
    formatter: (item) => {
      return <>{item.name}</>;
    },
    sortValue: (item) => `${item.name}`,
  },
  VehicleType: {
    id: "vehicleType",
    displayName: "type",
    formatter: (item) => {
      const val = !isNullOrUndefined(item.gseType)
        ? item.gseType.name
        : item.type;

      return <>{val}</>;
    },
    sortValue: (item) =>
      !isNullOrUndefined(item.gseType) ? item.gseType.name : item.type,
  },
  VehicleStatus: {
    id: "vehicleStatus",
    displayName: "status",
    formatter: (item) => {
      let status = "";
      switch (item.statusId) {
        case GroundVehicleStatus.Available:
          status = i18next.t("online").toUpperCase();
          break;
        case GroundVehicleStatus.Offline:
          status = i18next.t("offline").toUpperCase();
          break;
        default:
          status = "-";
      }
      return (
        <span
          className={
            item.statusId === GroundVehicleStatus.Offline ? "danger" : ""
          }
        >
          {status}
        </span>
      );
    },
    sortValue: (item) => `${item.statusId}`,
  },
  MapVehicle: {
    id: "mapVehicle",
    displayName: "map_non_cap",
    formatter: (item, actionHandler) => {
      return (
        <div>
          <button
            className="secondary"
            onClick={() => {
              actionHandler(
                ColumnActionType.SHOW_ON_MAP,
                MAPINFO_MARKER_TYPE.VEHICLE,
                item
              );
            }}
          >
            {i18next.t("show_in_map")}
          </button>
        </div>
      );
    },
    sortValue: (item) => `${item.name}`,
  },
  Tracker: {
    id: "tracker",
    displayName: "tracker_id_lower_case",
    formatter: (item) => {
      return <>{item.trackerCode}</>;
    },
    sortValue: (item) => `${item.trackerCode}`,
  },
  LastOnline: {
    id: "last-online",
    displayName: "last_online",
    formatter: (item) => {
      return <>{item.lastOnline}</>;
    },
    sortValue: (item) => `${item.lastOnlineTimestamp}`,
  },
  Battery: {
    id: "battery",
    displayName: "battery_non_cap",
    formatter: (item) => {
      const hasTracker = !isBlank(item.trackerCode);
      return (
        <>
          {hasTracker && (
            <BatteryPower battery={item.battery} hasPower={item.hasPower} />
          )}
        </>
      );
    },
    sortValue: (item) => {
      return getTrackerSortValue(item);
    },
  },
  VehicleAvgServiceTime: {
    id: "vehicle-avg-service-time",
    displayName: "avg_service_time",
    formatter: (item) => {
      return <>{formatHoursMins(item.averageServiceTimeMins)}</>;
    },
    sortValue: (item) => item.averageServiceTimeMins,
  },
  NotificationTitle: {
    id: "notificationTitle",
    displayName: "notification_title",
    formatter: (item) => {
      return <>{item.title}</>;
    },
    sortValue: (item) => `${item.userAlertTypeId} ${item.title}`,
  },
  AlertTitle: {
    id: "alertTitle",
    displayName: "flight_non_cap",
    formatter: (item) => {
      return (
        <AlertBadge title={item.title} userAlertTypeId={item.userAlertTypeId} />
      );
    },
    sortValue: (item) => `${item.userAlertTypeId} ${item.title}`,
  },
  AlertMessage: {
    id: "alertMessage",
    displayName: "alert",
    formatter: (item) => {
      return <div>{item.message}</div>;
    },
    sortValue: (item) => `${item.message}`,
  },
  NotificationMessage: {
    id: "notificationMessage",
    displayName: "notification_message",
    formatter: (item) => {
      return <div>{item.body}</div>;
    },
    sortValue: (item) => `${item.body}`,
  },
  NotificationSentAt: {
    id: "notificationSentAt",
    displayName: "date",
    formatter: (item) => {
      return (
        <div className="all-caps">
          {formatLongDatetime(item.sentAt, item.timezone)}
        </div>
      );
    },
    sortValue: (item) => new Date(item.sentAt),
  },
  NotificationStatus: {
    id: "notificationStatus",
    displayName: "status",
    formatter: (item) => {
      return (
        <div>
          {!item.read && (
            <div className="status-new">{i18next.t("new_reserved")}</div>
          )}
        </div>
      );
    },
    sortValue: (item) => item.read,
  },
  Datetime: {
    id: "datetime",
    displayName: "date",
    formatter: (item) => {
      return (
        <div className="capitalized">{formatLongDatetime(item.timestamp)}</div>
      );
    },
    sortValue: (item) => new Date(item.timestamp),
  },
  Status: {
    id: "status",
    displayName: "status",
    formatter: (item) => {
      return (
        <div>
          {!item.read && (
            <div className="status-new">{i18next.t("new_reserved")}</div>
          )}
        </div>
      );
    },
    sortValue: (item) => item.status,
  },
  FlightName: {
    id: "flightName",
    displayName: "flight_non_cap",
    formatter: (item) => {
      return <div>{item.flightName}</div>;
    },
    sortValue: (item) => item.flightName,
  },
  FlightStatus: {
    id: "flightStatus",
    displayName: "status",
    formatter: (item) => {
      return <div>{item.flightStatus}</div>;
    },
    sortValue: (item) => item.flightStatus,
  },
  Origin: {
    id: "origin",
    displayName: "origin_non_cap",
    formatter: (item) => {
      return <div>{item.origin}</div>;
    },
    sortValue: (item) => item.origin,
  },
  Destination: {
    id: "destination",
    displayName: "destination_non_cap",
    formatter: (item) => {
      return <div>{item.destination}</div>;
    },
    sortValue: (item) => item.destination,
  },
  Aircraft: {
    id: "aircraftType",
    displayName: "aircraft",
    formatter: (item) => {
      return <div>{item.aircraftType}</div>;
    },
    sortValue: (item) => item.aircraftType,
  },
  DepartureTerminal: {
    id: "departureTerminal",
    displayName: "departure_terminal",
    formatter: (item) => {
      return (
        <div>
          {!isBlank(item.departureTerminal) ? item.departureTerminal : DASHES}
        </div>
      );
    },
    sortValue: (item) => item.departureTerminal,
  },
  DepartureGate: {
    id: "departureGate",
    displayName: "departure_gate",
    formatter: (item) => {
      return (
        <div>{!isBlank(item.departureGate) ? item.departureGate : DASHES}</div>
      );
    },
    sortValue: (item) => item.departureGate,
  },
  DepartureTime: {
    id: "departureTime",
    displayName: "departure_time",
    formatter: (item) => {
      return (
        <div title={item.airportTimezone}>
          {!isBlank(item.departureTime)
            ? formatDatetime(
                item.departureTime,
                item.airportTimezone
              ).toUpperCase()
            : DASHES}
        </div>
      );
    },
    sortValue: (item) => new Date(item.departureTime),
  },
  ArrivalTerminal: {
    id: "arrivalTerminal",
    displayName: "arrival_terminal",
    formatter: (item) => {
      return (
        <div>
          {!isBlank(item.arrivalTerminal) ? item.arrivalTerminal : DASHES}
        </div>
      );
    },
    sortValue: (item) => item.arrivalTerminal,
  },
  ArrivalGate: {
    id: "arrivalGate",
    displayName: "arrival_gate",
    formatter: (item) => {
      return (
        <div>{!isBlank(item.arrivalGate) ? item.arrivalGate : DASHES}</div>
      );
    },
    sortValue: (item) => item.arrivalGate,
  },
  ArrivalTime: {
    id: "arrivalTime",
    displayName: "arrival_time",
    formatter: (item) => {
      return (
        <div title={item.airportTimezone}>
          {!isBlank(item.arrivalTime)
            ? formatDatetime(
                item.arrivalTime,
                item.airportTimezone
              ).toUpperCase()
            : DASHES}
        </div>
      );
    },
    sortValue: (item) => new Date(item.arrivalTime),
  },
  ...LeaderboardColumns,
  ...TurnaroundsModuleColumns,
};

// Actions that can be invoked from the table cell
export const ColumnActionType = {
  SHOW_ON_MAP: "showOnMap",
  DISPATCH_EVENT: "dispatchEvent",
};

function getFormattedString(item, columnNames) {
  const columnValues = columnNames.map((columnName) => {
    return columnName.sortValue(item);
  });
  return columnValues.join(" ");
}

function DataTable(props) {
  const { t } = useTranslation();
  const {
    dataList,
    selectedUuids = [],
    columnNames,
    rowActions,
    initialSort,
    initialSortDesc,
    hasMultiSelect = false,
    onSelectHandler,
    searchQuery,
    fixedWidths,
    onChangeSort,
    showNumberedRows,
    emptyState,
    gridColumns,
    rowClick,
  } = props;
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser } = mainContext;
  const defaultSort = !isNullOrUndefined(initialSort)
    ? initialSort
    : columnNames[0].id;
  const defaultSortDesc = !isNullOrUndefined(initialSortDesc)
    ? initialSortDesc
    : false;
  const [sortColumn, setSortColumn] = useState(defaultSort);
  const [sortDesc, setSortDesc] = useState(defaultSortDesc);
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const handleSort = (sortColumnName) => {
    const updatedSort =
      sortColumn === sortColumnName
        ? !sortDesc // already sorting by, toggle the direction
        : sortDesc;

    setSortColumn(sortColumnName);
    setSortDesc(updatedSort);

    if (!isNullOrUndefined(onChangeSort)) {
      const sortColumn = getColumnNameObjectById(sortColumnName);
      onChangeSort(createSortOptions(sortColumn, updatedSort));
    }
    if (!isNullOrUndefined(containerRef.current)) {
      containerRef.current.scrollTo(containerRef.current.scrollLeft, 0);
    }
  };

  // This action handler is made available to the column formatter to enable actions from the table cell
  const columnActionHandler = (
    columnActionType,
    markerType,
    item,
    dispatchEvent
  ) => {
    if (columnActionType === ColumnActionType.SHOW_ON_MAP) {
      setTimeout(() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.VEHICLE,
            item: item,
          },
        });
      }, 250);
      navigate("/map");
      logAnalyticsEvent(dispatch, ANALYTICS_EVENTS.GSE_SHOW_ON_MAP);
    } else if (columnActionType === ColumnActionType.DISPATCH_EVENT) {
      dispatch(dispatchEvent);
    }
  };

  const dataListSorted = [];
  const searchQueryAdjusted = searchQuery?.searchTerm?.trim().toLowerCase();
  if (!isNullOrUndefined(dataList)) {
    for (let i = 0; i < dataList.length; i++) {
      const item = dataList[i];
      let shouldInclude = true;
      if (!isBlank(searchQueryAdjusted)) {
        const formattedString = getFormattedString(
          item,
          columnNames
        ).toLowerCase();
        shouldInclude = formattedString.indexOf(searchQueryAdjusted) > -1;
      }

      if (shouldInclude) {
        dataListSorted.push(item);
      }
    }
  }

  // Sort according to the user selections
  sortByDataColumn(dataListSorted, sortColumn, sortDesc);

  const hasRowActions = !isNullOrUndefined(rowActions);
  let gridTemplateColumns = null;
  if (!isNullOrUndefined(gridColumns)) {
    gridTemplateColumns = gridColumns;
  } else if (!isNullOrUndefined(fixedWidths)) {
    gridTemplateColumns = `${fixedWidths[0]} repeat(${
      columnNames.length - 1
    }, minmax(${fixedWidths[1]}, 1fr))`;
  } else if (columnNames.length === 2) {
    gridTemplateColumns = "repeat(2, minmax(0,5fr))";
  } else if (columnNames.length === 3) {
    gridTemplateColumns = "repeat(2, minmax(0,1fr)) repeat(1, minmax(0,2fr))";
  } else if (columnNames.length === 4) {
    if (hasRowActions) {
      gridTemplateColumns =
        "minmax(0,1fr) minmax(0,1fr) minmax(0,2fr) minmax(0,1fr)";
    } else {
      gridTemplateColumns = "repeat(3, minmax(0,3fr)) repeat(1, minmax(0,2fr))";
    }
  } else if (columnNames.length > 4) {
    gridTemplateColumns = `repeat(${columnNames.length}, minmax(100px,1fr))`;
  }
  if (hasRowActions && isNullOrUndefined(gridColumns)) {
    gridTemplateColumns = `${
      hasMultiSelect ? "64px " : ""
    }${gridTemplateColumns}${hasRowActions ? " 96px" : ""}`;
  }

  const selectedItems = dataListSorted.filter((item) =>
    selectedUuids.includes(item.uuid)
  );
  const isPartialSelected =
    selectedItems.length > 0 && selectedItems.length < dataListSorted.length;
  const isAllSelected = selectedItems.length === dataListSorted.length;
  return (
    <div className="list-container" ref={containerRef}>
      <div
        className="list-header"
        style={{
          gridTemplateColumns: gridTemplateColumns,
        }}
      >
        {hasMultiSelect && (
          <div className="list-header-col list-multiselect">
            <Checkbox
              changeHandler={() => {
                if (selectedItems.length === 0) {
                  // select all
                  onSelectHandler(dataListSorted);
                } else {
                  // select none
                  onSelectHandler([]);
                }
              }}
              isChecked={isAllSelected}
              isPartialChecked={isPartialSelected}
            />
          </div>
        )}
        {columnNames &&
          columnNames.map((columnName) => (
            <div
              className={`list-header-col ${
                sortColumn === columnName.id ? "sorted" : ""
              }`}
              key={columnName.id}
            >
              <div
                className="list-header-name"
                onClick={() => handleSort(columnName.id)}
              >
                <div>
                  {!isBlank(columnName.tooltip) && (
                    <Tooltip
                      title={t(columnName.tooltip)}
                      placement="top"
                      componentsProps={tooltipStyles}
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 500 }}
                      arrow
                    >
                      <div>{t(columnName.displayName)}</div>
                    </Tooltip>
                  )}
                  {isBlank(columnName.tooltip) && (
                    <>{t(columnName.displayName)}</>
                  )}
                </div>
                <div
                  className={`sort-arrow ${
                    sortColumn === columnName.id && sortDesc ? "desc" : ""
                  }`}
                >
                  <Caret />
                </div>
              </div>
            </div>
          ))}
        {!isNullOrUndefined(rowActions) && (
          <div className="list-header-col list-actions">{t("actions")}</div>
        )}
      </div>
      <div className="list-body-container">
        <div className="list-body">
          {dataListSorted.length === 0 && (
            <>
              {emptyState && emptyState}
              {!emptyState && (
                <div className="empty-state">{t("no_results")}</div>
              )}
            </>
          )}
          {dataListSorted.length > 0 &&
            dataListSorted.map((item, idx) => (
              <div
                className={`list-row ${
                  !isNullOrUndefined(selectedUuids) &&
                  selectedUuids.includes(item.uuid)
                    ? "selected"
                    : ""
                }${!isNullOrUndefined(rowClick) ? " clickable" : ""}`}
                style={{
                  gridTemplateColumns: gridTemplateColumns,
                }}
                onClick={(e) => {
                  if (!isNullOrUndefined(rowClick)) {
                    rowClick(item);
                    e.stopPropagation();
                  }
                }}
                key={idx}
              >
                {hasMultiSelect && (
                  <div className="list-multiselect">
                    <Checkbox
                      value={item.uuid}
                      changeHandler={() => {
                        onSelectHandler([item]);
                      }}
                      isChecked={
                        !isNullOrUndefined(selectedUuids) &&
                        selectedUuids.includes(item.uuid)
                      }
                    />
                  </div>
                )}
                {columnNames &&
                  columnNames.map((columnName, colIndex) => (
                    <div
                      key={colIndex}
                      className={`${
                        showNumberedRows && colIndex === 0 ? "numbered" : ""
                      }`}
                    >
                      {showNumberedRows && colIndex === 0 && (
                        <div className="numbered-value">{idx + 1}</div>
                      )}
                      <div
                        className={`list-column-value${
                          !isBlank(columnName?.classNames)
                            ? ` ${columnName.classNames}`
                            : ""
                        }`}
                        title={
                          columnName?.titleValue
                            ? columnName.titleValue(item)
                            : ""
                        }
                      >
                        {columnName.formatter(
                          item,
                          columnActionHandler,
                          currentUser
                        )}
                      </div>
                    </div>
                  ))}
                {!isNullOrUndefined(rowActions) && (
                  <>
                    <div className="list-actions">
                      {!isNullOrUndefined(rowActions.editAction) && (
                        <div
                          className="button-icon"
                          onClick={(e) => {
                            rowActions.editAction(item);
                            e.stopPropagation();
                          }}
                        >
                          <Pencil />
                        </div>
                      )}
                      {!isNullOrUndefined(rowActions.deleteAction) && (
                        <div
                          className="button-icon"
                          onClick={(e) => {
                            rowActions.deleteAction(item);
                            e.stopPropagation();
                          }}
                        >
                          <Trash />
                        </div>
                      )}
                      {!isNullOrUndefined(rowActions.viewAction) && (
                        <div>{rowActions.viewAction(item)}</div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DataTable;
