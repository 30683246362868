import { useMemo, useRef, useState } from "react";
import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import { CHART_SERIES_COLORS, METRICS_VALUE_MODES } from "../../constants";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../userUtils";
import ButtonSwitch from "../ButtonSwitch";
import { useMainDispatchContext } from "../../MainContext";

function CrewLeaderboardChart(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const { chartData, users } = props;
  const [valueMode, setValueMode] = useState(METRICS_VALUE_MODES.PERCENT);
  registerTheme("Moonware", MoonwareTheme.theme);
  const chartRef = useRef();

  const [categoryBuckets, chartOption] = useMemo(() => {
    const categories = [];
    const hoursOnShiftData = [];
    const productiveHoursData = [];
    const productiveHoursPercentData = [];

    if (!isEmptyList(chartData) && !isEmptyList(users)) {
      const chartDataInfo = [];
      for (let i = 0; i < chartData.length; i++) {
        const item = chartData[i];
        const user = getByFieldValue(users, "uuid", item.userUuid);
        if (!isNullOrUndefined(user)) {
          const userInfo = getUserInfo(user);
          chartDataInfo.push({
            hoursOnShift: Math.round(item.hoursOnShift),
            productiveHours: Math.round(item.productiveHours),
            userUuid: item.userUuid,
            userInfo: userInfo,
            productiveHoursPercent:
              item.hoursOnShift > 0
                ? item.productiveHours / item.hoursOnShift
                : 0,
          });
        }
      }
      sortByField(chartDataInfo, "userInfo.fullName");
      chartDataInfo.reverse();
      if (valueMode === METRICS_VALUE_MODES.PERCENT) {
        sortByField(chartDataInfo, "productiveHoursPercent");
      } else {
        sortByField(chartDataInfo, "productiveHours");
      }

      for (let i = 0; i < chartDataInfo.length; i++) {
        const dataPoint = chartDataInfo[i];
        if (dataPoint.productiveHours > 0) {
          const nameParts = dataPoint.userInfo.fullName.split(" ");
          let nameString = dataPoint.userInfo.fullName.replaceAll(" ", "\n");
          if (nameParts.length > 3) {
            // Combine first two
            const part1 = nameParts.toSpliced(2);
            const part2 = nameParts.splice(2);
            nameString = `${part1.join(" ")}\n${part2.join(" ")}`;
          } else if (nameParts.length > 1) {
            // Break after first part
            const part1 = nameParts.toSpliced(1);
            const part2 = nameParts.splice(1);
            nameString = `${part1.join(" ")}\n${part2.join(" ")}`;
          }
          categories.push(nameString);
          // categories.push(dataPoint.userInfo.fullName);
          hoursOnShiftData.push(dataPoint.hoursOnShift);
          productiveHoursData.push(dataPoint.productiveHours);
          productiveHoursPercentData.push(dataPoint.productiveHoursPercent);
        }
      }
    }

    const hoursOnShift = {
      name: t("hours_on_shift"),
      type: "bar",
      data: hoursOnShiftData,
      itemStyle: {
        borderRadius: 4,
        color: CHART_SERIES_COLORS[1],
      },
      barWidth: 16,
      z: 1,
    };
    const productiveHours = {
      name: t("productive_hours"),
      type: "bar",
      data: productiveHoursData,
      itemStyle: {
        borderRadius: 4,
        color: CHART_SERIES_COLORS[0],
      },
      label: {
        show: true,
        fontWeight: 600,
      },
      barWidth: 16,
      z: 2,
    };
    const productiveHoursPercent = {
      name: t("productive_hours"),
      type: "bar",
      data: productiveHoursPercentData,
      itemStyle: {
        borderRadius: 4,
        color: CHART_SERIES_COLORS[0],
      },
      label: {
        show: true,
        fontWeight: 600,
        formatter: (params) => {
          const percentVal = Math.round(params.value * 100);
          return `${percentVal}%`;
        },
      },
      barWidth: 16,
      z: 2,
    };

    const seriesDataForChart =
      valueMode === METRICS_VALUE_MODES.PERCENT
        ? [productiveHoursPercent]
        : [hoursOnShift, productiveHours];

    // Configuration needed to render the chart
    const option = {
      axisPointer: {
        shadowStyle: {
          color: "rgba(23, 121, 218, 0.25)",
        },
        label: {
          backgroundColor: "rgba(23, 121, 218, 0.5)",
        },
      },
      tooltip: {
        borderColor: "#424959",
        backgroundColor: "#161721",
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "rgba(23, 121, 218, .75)",
          },
          label: {
            show: false,
          },
        },
        textStyle: {
          color: "#ffffff",
        },
        formatter: (params) => {
          const detailLines = [];
          detailLines.push(`<b>${params[0].name}</b>`);
          if (valueMode === METRICS_VALUE_MODES.PERCENT) {
            const percentVal = Math.round(params[0].value * 100);
            detailLines.push(
              `${params[0].marker} ${params[0].seriesName}: ${percentVal}%`
            );
          } else {
            detailLines.push(
              `${t("productive_hours")} (%): ${Math.round(
                (params[1].value / params[0].value) * 100
              )}%`
            );
            detailLines.push(
              `${params[0].marker} ${params[0].seriesName}: ${params[0].value}`
            );

            detailLines.push(
              `${params[1].marker} ${params[1].seriesName}: ${params[1].value}`
            );
          }
          return detailLines.join("<br />");
        },
      },

      legend: {
        show: false,
      },
      xAxis: [
        {
          type: "value",
          axisLabel: {
            color: "#ffffff",
            fontFamily: "Inter",
            fontWeight: 600,

            formatter: (value, index) => {
              if (valueMode === METRICS_VALUE_MODES.PERCENT) {
                return Math.round(value * 100);
              } else {
                return value;
              }
            },
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              color: "#4a4c56",
              type: "dotted",
              width: 2,
            },
          },
        },
      ],

      yAxis: {
        type: "category",
        data: categories,
        axisPointer: {
          type: "shadow",
        },
        axisLabel: {
          color: "#ffffff",
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: 10,
          lineHeight: 11,
          overflow: "truncate",
          width: 300,
          show: true,
          margin: 18,
        },

        axisLine: {
          show: false,
        },
      },
      series: seriesDataForChart,
      animationDuration: 250,
    };
    return [categories, option];
  }, [t, chartData, users, valueMode]);

  if (!isNullOrUndefined(chartRef.current)) {
    chartRef.current.getEchartsInstance();
  }

  function handleMouseOver() {
    dispatch({
      type: "setIsRefreshPaused",
      value: true,
    });
  }
  function handleMouseOut() {
    dispatch({
      type: "setIsRefreshPaused",
      value: false,
    });
  }
  return (
    <div className="chart-module">
      <div className="chart-container">
        <div className="chart-title-bar">
          <div>
            <h4>{t("productive_hours")}</h4>
          </div>
          <div>
            <ButtonSwitch
              selectedOption={valueMode.value}
              labelValuePairs={Object.values(METRICS_VALUE_MODES)}
              onChange={(mode) => {
                const selectedMode = getByFieldValue(
                  Object.values(METRICS_VALUE_MODES),
                  "value",
                  mode
                );
                setValueMode(selectedMode);
              }}
            />
          </div>
        </div>
        {isEmptyList(categoryBuckets) && (
          <div className="empty-state">
            {t("no_results_for_selected_range")}
          </div>
        )}
        {!isEmptyList(categoryBuckets) && (
          <div
            className="chart-component"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <ReactECharts
              ref={chartRef}
              option={chartOption}
              style={{
                height: `${48 * categoryBuckets?.length + 120}px`,
                width: "100%",
                padding: 0,
                margin: 0,
              }}
              theme="Moonware"
              notMerge={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default CrewLeaderboardChart;
